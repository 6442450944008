import { Box, Flex, Image, Text } from "@mantine/core";
import { IconChevronLeft, IconMinus, IconPlus, IconTrash } from "@tabler/icons-react";
import React from "react";

export const Cart = () => {
  return (
    <Box h={810} pos="relative">
        {/* Top Navigation Start */}
        <Flex justify={'flex-start'} align={'center'} px={20} pt={20} mb={25 }>
            <IconChevronLeft color="#27733E" size={22} />
            <Text ml={134} c={'#27733E'} fz={16} fw={600}> Cart </Text>
        </Flex>
        {/* Top Navigation End */}

        {/* Products List Start */}
        <Box>

          {/* Product Single */}
          <Box px={20} mb={20}>
            <Flex justify={'space-between'} align={'center'} py={20} px={10} style={{ boxShadow: "0px 0px 2px 0px #00000040", borderRadius: "10px" }}>
              <Flex align={'center'}>

                {/* image */}
                <Image w={60} h={60} fit="cover" mr={15} src={'https://images.pexels.com/photos/7974/pexels-photo.jpg'} style={{ borderRadius: "15%" }} />

                {/* text */}
                <Box>
                  <Text c={'#09051C'} fz={16} fw={500}> Dell Laptop </Text>
                  <Text c={'#27733E'} fz={16} fw={400}> $ 1,700 </Text>
                </Box>
              </Flex>
              <Flex>

                {/* counter */}
                <Flex align={'center'} justify={'center'}>
                  <Flex align={'center'} justify={'center'} w={26} h={26} bg={'#27733e26'} style={{ borderRadius: "8px", cursor: "pointer" }}>
                    <IconMinus color="#27733E" size={16} />
                  </Flex>
                  <Text ml={15}> 1 </Text>
                  <Flex align={'center'} justify={'center'} w={26} h={26} bg={'#27733E'} ml={15} style={{ borderRadius: "8px", cursor: "pointer" }}>
                    <IconPlus color="#ffffff" size={16} />
                  </Flex>
                </Flex>
              </Flex>

            </Flex>
          </Box>

          {/* Product Single */}
          <Box pr={20} mb={20}>
            <Box bg={'#27733E'} h={100} w={'100%'} pos={'relative'} style={{ borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }}>
              <Flex h={100} w={'100%'} bg={'#ffffff'} pos={'absolute'} right={45} justify={'space-between'} align={'center'} py={20} px={10} style={{ boxShadow: "0px 0px 2px 0px #00000040", borderRadius: "10px" }}>
                <Flex align={'center'}>

                  {/* image */}
                  <Image w={60} h={60} fit="cover" mr={15} src={'https://images.pexels.com/photos/40739/mobile-phone-smartphone-tablet-white-40739.jpeg'} style={{ borderRadius: "15%" }} />

                  {/* text */}
                  <Box>
                    <Text c={'#09051C'} fz={16} fw={500}> Tablet </Text>
                    <Text c={'#27733E'} fz={16} fw={400}> $ 590 </Text>
                  </Box>
                </Flex>
                <Flex>

                  {/* counter */}
                  <Flex align={'center'} justify={'center'}>
                    <Flex align={'center'} justify={'center'} w={26} h={26} bg={'#27733e26'} style={{ borderRadius: "8px", cursor: "pointer" }}>
                      <IconMinus color="#27733E" size={16} />
                    </Flex>
                    <Text ml={15}> 1 </Text>
                    <Flex align={'center'} justify={'center'} w={26} h={26} bg={'#27733E'} ml={15} style={{ borderRadius: "8px", cursor: "pointer" }}>
                      <IconPlus color="#ffffff" size={16} />
                    </Flex>
                  </Flex>
                </Flex>

              </Flex>
              <Box pos={'absolute'} right={12} top={'54%'} style={{ transform: "translateY(-50%)", cursor: "pointer" }}>
                <IconTrash color="#ffffff" size={22} />
              </Box>
            </Box>
          </Box>

          {/* Product Single */}
          <Box px={20} mb={20}>
            <Flex justify={'space-between'} align={'center'} py={20} px={10} style={{ boxShadow: "0px 0px 2px 0px #00000040", borderRadius: "10px" }}>
              <Flex align={'center'}>

                {/* image */}
                <Image w={60} h={60} fit="cover" mr={15} src={'https://images.pexels.com/photos/3825517/pexels-photo-3825517.jpeg'} style={{ borderRadius: "15%" }} />

                {/* text */}
                <Box>
                  <Text c={'#09051C'} fz={16} fw={500}> Airpods </Text>
                  <Text c={'#27733E'} fz={16} fw={400}> $ 150 </Text>
                </Box>
              </Flex>
              <Flex>

                {/* counter */}
                <Flex align={'center'} justify={'center'}>
                  <Flex align={'center'} justify={'center'} w={26} h={26} bg={'#27733e26'} style={{ borderRadius: "8px", cursor: "pointer" }}>
                    <IconMinus color="#27733E" size={16} />
                  </Flex>
                  <Text ml={15}> 1 </Text>
                  <Flex align={'center'} justify={'center'} w={26} h={26} bg={'#27733E'} ml={15} style={{ borderRadius: "8px", cursor: "pointer" }}>
                    <IconPlus color="#ffffff" size={16} />
                  </Flex>
                </Flex>
              </Flex>

            </Flex>
          </Box>

          {/* Product Single */}
          <Box px={20}>
            <Flex justify={'space-between'} align={'center'} py={20} px={10} style={{ boxShadow: "0px 0px 2px 0px #00000040", borderRadius: "10px" }}>
              <Flex align={'center'}>

                {/* image */}
                <Image w={60} h={60} fit="cover" mr={15} src={'https://images.pexels.com/photos/267394/pexels-photo-267394.jpeg'} style={{ borderRadius: "15%" }} />

                {/* text */}
                <Box>
                  <Text c={'#09051C'} fz={16} fw={500}> Smart Watch </Text>
                  <Text c={'#27733E'} fz={16} fw={400}> $ 320 </Text>
                </Box>
              </Flex>
              <Flex>

                {/* counter */}
                <Flex align={'center'} justify={'center'}>
                  <Flex align={'center'} justify={'center'} w={26} h={26} bg={'#27733e26'} style={{ borderRadius: "8px", cursor: "pointer" }}>
                    <IconMinus color="#27733E" size={16} />
                  </Flex>
                  <Text ml={15}> 1 </Text>
                  <Flex align={'center'} justify={'center'} w={26} h={26} bg={'#27733E'} ml={15} style={{ borderRadius: "8px", cursor: "pointer" }}>
                    <IconPlus color="#ffffff" size={16} />
                  </Flex>
                </Flex>
              </Flex>

            </Flex>
          </Box>

        </Box>
        {/* Products List End */}

        {/* Checkout Section Start */}
        <Box px={20} pos="absolute" bottom={20} w="100%">
          <Box p={15} bg={'linear-gradient(99deg, #27733E -0.82%, #27733E 101.53%)'} style={{ borderRadius: "10px" }}>
            <Flex justify={'space-between'} align={'center'}>
              <Text c={'#FEFEFF'} fz={14} fw={400}> Sub-Total </Text>
              <Text c={'#FEFEFF'} fz={14} fw={400} style={{ flexBasis: "25%" }}> $ 2,760 </Text>
            </Flex>
            <Flex justify={'space-between'} align={'center'}>
              <Text c={'#FEFEFF'} fz={14} fw={400}> Delivery Charge </Text>
              <Text c={'#FEFEFF'} fz={14} fw={400} style={{ flexBasis: "25%" }}> Free </Text>
            </Flex>
            <Flex justify={'space-between'} align={'center'}>
              <Text c={'#FEFEFF'} fz={14} fw={400}> Discount </Text>
              <Text c={'#FEFEFF'} fz={14} fw={400} style={{ flexBasis: "25%" }}> $ 2,760 </Text>
            </Flex>
            <Flex justify={'space-between'} align={'center'} mt={15}>
              <Text c={'#FEFEFF'} fz={16} fw={700}> Total </Text>
              <Text c={'#FEFEFF'} fz={16} fw={700} style={{ flexBasis: "25%" }}> $ 2,760 </Text>
            </Flex>
            <Flex justify={'center'} align={'center'} h={55} bg={'#FEFEFF'} mt={10} style={{ borderRadius: "10px", cursor: "pointer" }}>
              <Text c={"#27733E"} fz={14} fw={600}> Checkout </Text>
            </Flex>
          </Box>
        </Box>
        {/* Checkout Section End */}
    </Box>
  )
}
