import { Carousel } from "@mantine/carousel";
import { Box, Flex, Image, Input, Text, Title } from "@mantine/core";
import {
  IconSearch,
  IconPlus,
  IconMenu2,
  IconHome,
  IconShoppingCart,
  IconUserCircle,
  IconShoppingBag,
  IconChartCandle,
  IconStarFilled,
  IconHeart,
  IconMessage,
  IconHeartFilled,
} from "@tabler/icons-react";
import React from "react";

const Home = () => {
  return (
    <Box p={8} pos={"relative"}>
      {/* Template Title Start */}
      <Text
        c={"#ffffff"}
        pos={"absolute"}
        top={-65}
        fw={600}
        fz={20}
        left={"50%"}
        w={"100%"}
        ta={"center"}
        style={{ transform: "translateX(-50%)" }}
      >
        Home Template
      </Text>
      {/* Template Title End */}

      {/* Top Navigation Start */}
      <Flex justify={"space-between"} align={"center"} mb={20} px={25}>
        <IconMenu2 size={30} style={{ cursor: "pointer" }} />
        <Flex>
          <Flex
            justify={"center"}
            align={"center"}
            w={45}
            h={45}
            bg={"#ffffff"}
            style={{ borderRadius: "50%" }}
          >
            <IconSearch color="#1b77fe" size={22} />
          </Flex>
          <Flex
            justify={"center"}
            align={"center"}
            w={45}
            h={45}
            bg={"#ffffff"}
            ml={10}
            style={{ borderRadius: "50%" }}
          >
            <IconShoppingBag color="#1b77fe" size={22} />
          </Flex>
        </Flex>
      </Flex>
      {/* Top Navigation End */}

      {/* Search Bar Start */}
      <Flex justify={"space-between"} align={"center"} mb={20} px={25}>
        <Flex
          justify="flex-start"
          align="center"
          h={40}
          pl={10}
          bg={"#ffffff"}
          style={{
            borderRadius: "10px",
            borderColor: "transparent",
            cursor: "text",
            flexBasis: "80%",
          }}
        >
          <IconSearch color="#1c77fe" size={22} />
          <Input variant="unstyled" placeholder="Looking for shoes" ml={10} />
        </Flex>
        <Flex
          w={40}
          h={40}
          align={"center"}
          justify={"center"}
          style={{
            background: "linear-gradient(180deg, #3285FF 0%, #0766F2 100%)",
            borderRadius: "50%",
            transform: "rotate(90deg)",
          }}
        >
          <IconChartCandle color="#ffffff" size={22} />
        </Flex>
      </Flex>
      {/* Search Bar End */}

      {/* Categories Carousel Start */}
      <Box>
        <Title size={14} fw={600} c={"#2B2B2B"} my={20} px={25}>
          {" "}
          Select Category{" "}
        </Title>
        <Carousel
          slideSize={80}
          align="start"
          withControls={false}
          dragFree
          pl={25}
        >
          <Carousel.Slide mr={10}>
            <Text
              c={"#2B2B2B"}
              fz={11}
              fw={400}
              px={15}
              py={14}
              p={15}
              bg={"#ffffff"}
              ta={"center"}
              style={{
                borderRadius: "8px",
                boxShadow: "0px 2px 42px 0px #0000000a",
                cursor: "pointer",
              }}
            >
              {" "}
              All Shoes{" "}
            </Text>
          </Carousel.Slide>
          <Carousel.Slide mr={10}>
            <Text
              c={"#ffffff"}
              fz={12}
              fw={400}
              px={15}
              py={13}
              ta={"center"}
              style={{
                borderRadius: "8px",
                boxShadow: "0px 2px 42px 0px #0000000a",
                background: "linear-gradient(180deg, #1F79FF 0%, #0A6AF6 100%)",
                cursor: "pointer",
              }}
            >
              {" "}
              Outdoor{" "}
            </Text>
          </Carousel.Slide>
          <Carousel.Slide mr={10}>
            <Text
              c={"#2B2B2B"}
              fz={12}
              fw={400}
              px={15}
              py={13}
              p={15}
              bg={"#ffffff"}
              ta={"center"}
              style={{
                borderRadius: "8px",
                boxShadow: "0px 2px 42px 0px #0000000a",
                cursor: "pointer",
              }}
            >
              {" "}
              Tennis{" "}
            </Text>
          </Carousel.Slide>
          <Carousel.Slide>
            <Text
              c={"#2B2B2B"}
              fz={12}
              fw={400}
              px={15}
              py={13}
              p={15}
              bg={"#ffffff"}
              ta={"center"}
              style={{
                borderRadius: "8px",
                boxShadow: "0px 2px 42px 0px #0000000a",
                cursor: "pointer",
              }}
            >
              {" "}
              Running{" "}
            </Text>
          </Carousel.Slide>
        </Carousel>
      </Box>
      {/* Categories Carousel End */}

      {/* Popular Shoes Start */}
      <Box px={25}>
        {/* Section Title */}
        <Flex justify={"space-between"} align={"center"} my={20}>
          <Title size={14} fw={600} c={"#2B2B2B"}>
            {" "}
            Popular Shoes{" "}
          </Title>
          <Text c={"#0D6EFD"} fz={12} fw={500} style={{ cursor: "pointer" }}>
            See all
          </Text>
        </Flex>
        {/* Products Row */}
        <Flex justify={"space-between"} align={"center"}>
          <Box
            pos={"relative"}
            p={12}
            bg={"#ffffff"}
            style={{
              borderRadius: "15px",
              flexBasis: "48%",
              cursor: "pointer",
            }}
          >
            <Image
              h={80}
              w={"100%"}
              fit="contain"
              src="https://m.media-amazon.com/images/I/61KOUS5dKGL._AC_UF1000,1000_QL80_.jpg"
            />
            <Box mt={12}>
              <Flex align={"center"} justify={"space-between"}>
                <Text c={"#8C8C8C"} fz={12} fw={500}>
                  BEST SELLER
                </Text>
                <Flex align={"center"} justify={"flex-end"}>
                  <Box c={"#FCC915"}>
                    <IconStarFilled size={12} />
                  </Box>
                  <Text c={"#FFCE31"} fz={14} fw={600} ml={3}>
                    5
                  </Text>
                </Flex>
              </Flex>
              <Title c={"#222222"} fz={16} fw={600} mb={5}>
                Nike Jordan
              </Title>
              <Text c={"#0D6EFD"} fz={14} fw={600}>
                $302.00
              </Text>
              <Box pos={"absolute"} top={"10px"} left={"10px"}>
                <IconHeart color="#000000" size={22} />
              </Box>
              <Flex
                justify={"center"}
                align={"center"}
                pos={"absolute"}
                bottom={0}
                right={0}
                h={35}
                w={35}
                style={{
                  background:
                    "linear-gradient(180deg, #277EFF 0%, #0C65E8 100%)",
                  borderTopLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                <IconPlus color="#ffffff" size={22} />
              </Flex>
            </Box>
          </Box>

          <Box
            pos={"relative"}
            p={12}
            bg={"#ffffff"}
            style={{
              borderRadius: "15px",
              flexBasis: "48%",
              cursor: "pointer",
            }}
          >
            <Image
              h={80}
              w={"100%"}
              fit="contain"
              src="https://m.media-amazon.com/images/I/41d723SadlL._SR600%2C315_PIWhiteStrip%2CBottomLeft%2C0%2C35_SCLZZZZZZZ_FMpng_BG255%2C255%2C255.jpg"
            />
            <Box mt={12}>
              <Flex align={"center"} justify={"space-between"}>
                <Text c={"#8C8C8C"} fz={12} fw={500}>
                  OFFER 20% OFF
                </Text>
                <Flex align={"center"} justify={"flex-end"}>
                  <Box c={"#FCC915"}>
                    <IconStarFilled size={12} />
                  </Box>
                  <Text c={"#FFCE31"} fz={14} fw={600} ml={3}>
                    5
                  </Text>
                </Flex>
              </Flex>
              <Title c={"#222222"} fz={16} fw={600} mb={5}>
                Nike Air Max
              </Title>
              <Text c={"#0D6EFD"} fz={14} fw={600}>
                $752.00
              </Text>
              <Box pos={"absolute"} top={"10px"} left={"10px"} c={"#F87265"}>
                <IconHeartFilled size={22} />
              </Box>
              <Flex
                justify={"center"}
                align={"center"}
                pos={"absolute"}
                bottom={0}
                right={0}
                h={35}
                w={35}
                style={{
                  background:
                    "linear-gradient(180deg, #277EFF 0%, #0C65E8 100%)",
                  borderTopLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                <IconPlus color="#ffffff" size={22} />
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Box>
      {/* Popular Shoes End */}

      {/* Banner Section Start */}
      <Box mb={80} px={25}>
        {/* Section Title */}
        <Flex justify={"space-between"} align={"center"} my={20}>
          <Title size={14} fw={600} c={"#2B2B2B"}>
            {" "}
            New Arrival{" "}
          </Title>
          <Text c={"#0D6EFD"} fz={12} fw={500} style={{ cursor: "pointer" }}>
            See all
          </Text>
        </Flex>
        {/* Main Banner */}
        <Box
          p={22}
          pos={"relative"}
          bg={"#ffffff"}
          style={{ borderRadius: "15px" }}
        >
          <Text c={"#8C8C8C"} fz={12} fw={500} mb={5}>
            {" "}
            Summer Sale{" "}
          </Text>
          <Title c={"#0D6EFD"} fz={30} fw={900}>
            {" "}
            15% OFF{" "}
          </Title>
          <Box
            pos={"absolute"}
            top={25}
            left={"27%"}
            w={"60px"}
            style={{ transform: "rotate(-20deg)" }}
          >
            <Image src="new.png" w={150} fit="contain" mb={-10} />
            <Image src="v.png" w={150} fit="contain" />
          </Box>
          <Image
            src="shoe.png"
            pos={"absolute"}
            w={"auto"}
            h={110}
            fit="cover"
            top={"-15px"}
            right={"20px"}
          />

          {/* Stars */}
          <Image
            src="star.png"
            pos={"absolute"}
            w={25}
            left={1}
            top={70}
            style={{ opacity: "0.3" }}
          />
          <Image
            src="star.png"
            pos={"absolute"}
            w={25}
            left={100}
            top={5}
            style={{ opacity: "0.2" }}
          />
          <Image
            src="star.png"
            pos={"absolute"}
            w={25}
            left={120}
            bottom={5}
            style={{ opacity: "0.2" }}
          />
          <Image
            src="star.png"
            pos={"absolute"}
            w={25}
            right={1}
            top={5}
            style={{ opacity: "0.2" }}
          />
        </Box>
      </Box>
      {/* Banner Section End */}

      {/* Bottom Nav Start */}
      <Flex
        justify={"space-between"}
        align={"center"}
        pos={"absolute"}
        bg={"#ffffff"}
        w={"375px"}
        left={0}
        bottom={-25}
        h={65}
        p={25}
        style={{
          borderBottomLeftRadius: "30px",
          borderBottomRightRadius: "30px",
          boxShadow: "0px -4px 4px 0px #83aad11f",
        }}
      >
        <IconHome color="#4285f4" />
        <IconMessage color="#cacaca" />
        <IconSearch color="#cacaca" />
        <IconShoppingCart color="#cacaca" />
        <IconUserCircle color="#cacaca" />
      </Flex>
      {/* Bottom Nav End */}
    </Box>
  );
};

export default Home;
