import { Box, Button, Flex, Image, Text } from "@mantine/core";
import { IconStarFilled, IconChevronLeft, IconMinus, IconPlus, IconTrash } from "@tabler/icons-react";
import React from "react";

const Cart = () => {
  return (
    <Box p={8} pos={'relative'}>
        {/* Template Title Start */}
        <Text c={"#ffffff"} pos={'absolute'} top={-65} fw={600} fz={20} left={'50%'} w={"100%"} ta={'center'} style={{ transform: "translateX(-50%)" }}>Cart Template</Text>
        {/* Template Title End */}

        {/* Page Title Start */}
        <Flex c={'#ffffff'} mb={25}>
            <IconChevronLeft />
            <Text fz={24} fw={500} lh={1} ml={95}> My Cart </Text>
        </Flex>
        {/* Page Title End */}

        {/* Product List Start */}
        <Box>
            {/* Single Product */}
            <Box bg={'#181818'} p={20} mb={20} style={{ border: "2px solid #2F3134", borderRadius: "10px", boxShadow: "4px 4px 12px 0px #0000004d" }}>
                <Flex justify={'flex-start'} align={'center'} mb={20}>
                    {/* left col */}
                    <Image h={110} w={110} radius="10px" mr={20} src="https://images.pexels.com/photos/844923/pexels-photo-844923.jpeg" />
                    {/* right col */}
                    <Box>
                        <Text c={'#ffffff'} fz={16} fw={500} mb={10}>Headphone Gen X Wireless 1048 FX</Text>
                        <Flex>
                            <Text c={'#515151'} fz={12} lh={'24px'} fw={500} mr={10} style={{ textDecoration: "line-through" }}>$45.00</Text>
                            <Text c={'#ffffff'} fz={16} fw={500}>$32.00</Text>
                        </Flex>
                        <Flex justify={'flex-start'} align={'center'}>
                            <Box c={'#E7B53D'} mr={5}> 
                                <IconStarFilled size={12} /> 
                            </Box>
                            <Text c={'#ECEBEB'} fz={16} fw={500}>4.9</Text>
                        </Flex>
                    </Box>
                </Flex>
                <Flex justify={'space-between'} align={'center'}>
                    {/* Qty */}
                    <Flex align={'center'}>
                        <Flex w={23} h={20} bg={'#262626'} justify={'center'} align={'center'} style={{ border: "2px solid #2F3134", borderRadius: "4px", cursor: "pointer" }}>
                            <IconMinus color="#ffffff" size={13} />
                        </Flex>
                        <Text c={'#ffffff'} mx={10} fz={16} fw={500}>1</Text>
                        <Flex w={23} h={20} bg={'#262626'} justify={'center'} align={'center'} style={{ border: "2px solid #2F3134", borderRadius: "4px", cursor: "pointer" }}>
                            <IconPlus color="#ffffff" size={13} />
                        </Flex>
                    </Flex>
                    {/* Remove */}
                    <Flex>
                        <IconTrash color="#7c7c7c" size={20} />
                        <Text c={'#7C7C7C'} fz={14} fw={500} ml={5} style={{ cursor: "pointer" }}>Remove</Text>
                    </Flex>
                </Flex>
            </Box>
        </Box>
        {/* Product List End */}

        {/* Buttons Start */}
        <Flex px={25} mt={370} justify={'space-between'} align={'center'}>
            <Box>
                <Text fz={20} fw={500} c={'#ffffff'}>$234.00</Text>
                <Text fz={12} fw={500} lh={'24px'} c={'#ffffff'}>View Details</Text>
            </Box>
            <Button variant="filled" c={'#ffffff'} fz={16} fw={500} h={50} bg={'#00B9D3'} style={{ cursor: 'pointer', flexBasis: "50%", borderRadius: "30px" }}>Buy Now</Button>
        </Flex>
        {/* Buttons End */}

        {/* Bottom Bar Start */}
        <Box w={128} h={7} bg={'#d9d9d9'} mt={25} mb={10} mx={'auto'} style={{ borderRadius: "12px" }}></Box>
        {/* Bottom Bar End */}
      
    </Box>
  );
};

export default Cart;
