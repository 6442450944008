import { Box, Divider, Flex, Image, Tabs, Text } from "@mantine/core";
import { IconDotsVertical, IconUserCircle } from "@tabler/icons-react";
import { IconBrandSafari, IconShoppingCart } from "@tabler/icons-react";
import { IconHome, IconMenu2 } from "@tabler/icons-react";
import React from "react";

const MyOrder = () => {
  return (
    <Box p={24} pos="relative" w={375} h="100%">
      {/* Nav Start */}
      <Flex align="center" justify="space-between">
        <IconMenu2 size={35} />
        <Text fw={700} fz={20}>
          My Order
        </Text>
          <IconDotsVertical />
      </Flex>
      {/* Nav End */}

      {/* Tab Menu Start */}
      <Flex align="center" justify="space-between" my={30}>
        <Text
          fz={15}
          w={30}
          ta="center"
          c={'#4C6C93'}
        >
          All
        </Text>
        <Text fz={15} ta="center" c={'#979797'}>
          On Progress
        </Text>
        <Text fz={15} ta="center" c={'#979797'}>
          Completed
        </Text>
        <Text fz={15} ta="center" c={'#979797'}>
          Canceled
        </Text>
      </Flex>
      {/* Tab Menu End */}

      {/* Tab Items Start */}
      <Tabs color="#868E96" defaultValue="all">
        <Tabs.Panel value="all">
          <Flex align="center" justify="space-between" my={16}>
            <Box>
              <Text fw={600}>#89246703</Text>
              <Text c="#868E96" size={12} mt={3} lh={1}>Estimate Delivery</Text>
            </Box>
            <Box>
              <Text fw={600} c="#FAB005">
                On Delivery
              </Text>
              <Text c="#868E96" size={12} mt={3} lh={1}>Monday, 20 july 2024</Text>
            </Box>
          </Flex>
          <Box pt={10}>
            <Flex align="center" gap={5}>
              <Box w={60} h={80}>
                <Image
                  style={{ width: "100%", height: "100%" }}
                  src="https://i.pinimg.com/736x/da/5d/f8/da5df88c6da22104193f1e02baafdbf2.jpg"
                  alt=""
                  radius="10px"
                />
              </Box>
              <Box h={88} p={8}>
                <Text fz={15} fw={700}>
                  Default text Tshirt
                </Text>
                <Box>
                  <Text fz={13} c="dimmed">
                    T-Shirt
                  </Text>
                </Box>
                <Text c="#6a89cc" fw={700}>
                  $24
                </Text>
              </Box>
            </Flex>
            <Flex align="center" justify="space-between">
              <Flex lign="center" justify="center">
                <Text fz={13} c="#868E96">
                  Size:
                </Text>
                <Text fw={600} fz={13} c="#343A40" pl={1}>
                  XL
                </Text>
              </Flex>
              <Flex align="center" gap={4}>
                <Text fz={13} c="#868E96">
                  Size:
                </Text>
                <Box
                  w={15}
                  h={15}
                  bg="#E67700"
                  style={{ borderRadius: "100%" }}
                ></Box>
              </Flex>
              <Flex lign="center" justify="center">
                <Text fz={13} c="#868E96">
                  Qty:
                </Text>
                <Text fw={600} c="#343A40" pl={1}>
                  2{" "}
                </Text>
              </Flex>
            </Flex>
          </Box>
          <Box pt={10} mt={10}>
            <Flex align="center" gap={5}>
              <Box w={60} h={80}>
                <Image
                  style={{ width: "100%", height: "100%" }}
                  src="https://i.pinimg.com/736x/da/5d/f8/da5df88c6da22104193f1e02baafdbf2.jpg"
                  alt=""
                  radius="10px"
                />
              </Box>
              <Box h={88} p={8}>
                <Text fz={15} fw={700}>
                  Default text Tshirt
                </Text>
                <Box>
                  <Text fz={13} c="dimmed">
                    T-Shirt
                  </Text>
                </Box>
                <Text c="#6a89cc" fw={700}>
                  $24
                </Text>
              </Box>
            </Flex>
            <Flex align="center" justify="space-between">
              <Flex lign="center" justify="center">
                <Text fz={13} c="#868E96">
                  Size:
                </Text>
                <Text fw={600} fz={13} c="#343A40" pl={1}>
                  XL
                </Text>
              </Flex>
              <Flex align="center" gap={4}>
                <Text fz={13} c="#868E96">
                  Size:
                </Text>
                <Box
                  w={15}
                  h={15}
                  bg="#E67700"
                  style={{ borderRadius: "100%" }}
                ></Box>
              </Flex>
              <Flex lign="center" justify="center">
                <Text fz={13} c="#868E96">
                  Qty:
                </Text>
                <Text fw={600} c="#343A40" pl={1}>
                  2{" "}
                </Text>
              </Flex>
            </Flex>
            <Divider my={8} />
          </Box>

          <Flex align="center" justify="space-between" my={16}>
            <Box>
              <Text fw={600}>#89246703</Text>
              <Text c="#868E96" size={12} mt={3} lh={1}>Estimate Delivery</Text>
            </Box>
            <Box>
              <Text fw={600} c="#82C91E">
                Complete
              </Text>
              <Text c="#868E96" size={12} mt={3} lh={1}>Monday, 20 july 2024</Text>
            </Box>
          </Flex>

          <Box pt={10} mt={10}>
            <Flex align="center" gap={5}>
              <Box w={60} h={80}>
                <Image
                  style={{ width: "100%", height: "100%" }}
                  src="https://i.pinimg.com/736x/da/5d/f8/da5df88c6da22104193f1e02baafdbf2.jpg"
                  alt=""
                  radius="10px"
                />
              </Box>
              <Box h={88} p={8}>
                <Text fz={15} fw={700}>
                  Default text Tshirt
                </Text>
                <Box>
                  <Text fz={13} c="dimmed">
                    T-Shirt
                  </Text>
                </Box>
                <Text c="#6a89cc" fw={700}>
                  $24
                </Text>
              </Box>
            </Flex>
            <Flex align="center" justify="space-between">
              <Flex lign="center" justify="center">
                <Text fz={13} c="#868E96">
                  Size:
                </Text>
                <Text fw={600} fz={13} c="#343A40" pl={1}>
                  XL
                </Text>
              </Flex>
              <Flex align="center" gap={4}>
                <Text fz={13} c="#868E96">
                  Size:
                </Text>
                <Box
                  w={15}
                  h={15}
                  bg="#E67700"
                  style={{ borderRadius: "100%" }}
                ></Box>
              </Flex>
              <Flex lign="center" justify="center">
                <Text fz={13} c="#868E96">
                  Qty:
                </Text>
                <Text fw={600} c="#343A40" pl={1}>
                  2{" "}
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Tabs.Panel>
      </Tabs>
      {/* Tab Items End */}

      {/* Bottom Nav Start */}
      <Box pos={"absolute"} py={5} left={0} bottom={0} w={"100%"} bg="#ffffff" style={{ boxShadow: "#83aad11f 0px -4px 4px 0px", borderBottomLeftRadius: "30px" , borderBottomRightRadius: "30px" }}>
        <Flex
          py={14}
          align="center"
          justify="space-around"
        >
          <IconHome color="#E03131" />
          <IconBrandSafari color="#868E96" />
          <IconShoppingCart color="#868E96" />
          <IconUserCircle color="#868E96" />
        </Flex>{" "}
      </Box>
      {/* Bottom Nav Start */}
    </Box>
  );
};

export default MyOrder;
