import { Box, Flex, Image, Text } from "@mantine/core";
import {
  IconCheck,
  IconChevronRight,
  IconDotsVertical,
  IconLogout,
  IconMenu2,
  IconStar,
  IconTruckDelivery,
  IconHome,
  IconBrandSafari,
  IconUserCircle,
  IconShoppingCart,
} from "@tabler/icons-react";
import React from "react";

const Profile = () => {
  return (
    <>
      <Box p={24} h="100%" pos="relative">
        {/* Nav Start */}
        <Flex align="center" justify="space-between">
          <IconMenu2 size={35} />
          <Text fw={700} fz={20}>
            Profile
          </Text>
          <IconDotsVertical />
        </Flex>
        {/* Nav End */}

        <Flex align="center" justify="center" direction="column" mt={50}>
          {/* Profile Sectin Start */}
          <Box w={120} h={120} pos="relative">
            <Image
              radius="40px"
              style={{ width: "100%", height: "100%" }}
              src="https://images.pexels.com/photos/4918155/pexels-photo-4918155.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt="Profile"
            />
            <Flex
              pos={"absolute"}
              right={-10}
              bottom={-5}
              justify={"center"}
              align={"center"}
              h={40}
              w={40}
              bg={"#2EC52B"}
              style={{ borderRadius: "50%" }}
            >
              <IconCheck size={22} color="#ffffff" />
            </Flex>
          </Box>
          <Box ta="center" my={20}>
            <Text fz={20} fw={600}>
              John Doe
            </Text>
            <Text c="#868E96">john.doe@example.com</Text>
          </Box>
          {/* Profile Sectin End */}

          {/* Button Start */}
          <Flex
            w="100%"
            p={10}
            align="center"
            justify="space-between"
            style={{ boxShadow: "0 0 16px 0 #E9ECEF", borderRadius: "10px" }}
          >
            <Flex
              w={150}
              h={40}
              bg="#E03131"
              align="center"
              justify="center"
              style={{ borderRadius: "10px" }}
            >
              <Text c="#ffffff" ta="center" fw={600}>
                $ 650,54
              </Text>
            </Flex>
            <Flex h={30} align="center" justify="space-between">
              <Text ta="center" fz={14} fw={600} c="#4C6C93">
                Top Up
              </Text>
              <IconChevronRight color="#4C6C93" size={20} />
            </Flex>
          </Flex>
          {/* Button End */}

          {/* Bottom Options Start */}
          <Flex
            style={{ width: "100%", height: "100%" }}
            direction="column"
            align="center"
            mt={15}
          >
            <Box my={20} w="100%">
              <Flex align="center" gap={30}>
                <IconStar color="#868E96" />
                <Text fw={600}>My Review</Text>
              </Flex>
              <Flex align="center" gap={30} my={30}>
                <IconTruckDelivery color="#868E96" />
                <Text fw={600}>Shipment Address</Text>
              </Flex>
              <Flex align="center" gap={30}>
                <IconLogout color="#ff4757" />
                <Text fw={600} c="#E03131">
                  Log out
                </Text>
              </Flex>
            </Box>
          </Flex>
          {/* Bottom Options End */}
        </Flex>

        {/* Bottom Nav Start */}
        <Box
          pos={"absolute"}
          py={5}
          left={0}
          bottom={0}
          w={"100%"}
          bg="#ffffff"
          style={{
            boxShadow: "#83aad11f 0px -4px 4px 0px",
            borderBottomLeftRadius: "30px",
            borderBottomRightRadius: "30px",
          }}
        >
          <Flex py={14} align="center" justify="space-around">
            <IconHome color="#E03131" />
            <IconBrandSafari color="#868E96" />
            <IconShoppingCart color="#868E96" />
            <IconUserCircle color="#868E96" />
          </Flex>{" "}
        </Box>
        {/* Bottom Nav Start */}
      </Box>
    </>
  );
};

export default Profile;
