import { Carousel } from "@mantine/carousel";
import {
  Box,
  Flex,
  Image,
  Text,
  Group,
  Input,
  Indicator,
  Grid
} from "@mantine/core";
import {
  IconUser,
  IconMapPin,
  IconShoppingBag,
  IconBell,
  IconHome,
  IconGripVertical,
  IconClipboardList,
  IconWallet,
  IconClockFilled,
  IconSearch,
  IconAdjustments,
  IconCheck,
  IconStarFilled,
  IconShoppingCart,
} from "@tabler/icons-react";
import React from "react";

const Home = () => {
  return (
    <Box h='100%' pos='relative'>
      {/* header start */}
      <Flex px={20} pt={20} align="center" justify="space-between">
        <Flex align="center" gap={8}>
          <Image
            mih={28}
            miw={28}
            mah={28}
            maw={28}
            src="https://i.postimg.cc/6678S5G0/pexels-italo-melo-2379005.jpg"
            style={{ borderRadius: "50%" }}
            fit="cover"
          />

          <Box>
            <Text c="#A5A4A4" fz={12} fw={400}>Deleiver to</Text>
            <Text fz={12} fw={600} c="#2F2E36">Location..... <IconMapPin size={14} color="#00743d"></IconMapPin></Text>
          </Box>
        </Flex>

        <Flex align="center" gap={8} c="#00743d">
          <Indicator label={2} size={18} color="#ff1424" offset={4} style={{ transform: "scale(0.6)" }}>
            <IconBell size={30} style={{ transform: "scale(1.1)" }} />
          </Indicator>
          <Indicator position="top-end" color="#ff1424" offset={3} style={{ transform: "scale(0.6)" }}>
            <IconShoppingBag size={30} style={{ transform: "scale(1.1)" }} />
          </Indicator>
        </Flex>
      </Flex>
      {/* header end */}

      {/* Search section start */}
      <Box px={20}>
        <Flex mt="12" py={3} px={10} align="center" gap={10} bg="#fff" style={{ boxShadow: "0px 0px 10px 5px #dbe2de8a", borderRadius: "8px" }}>
          <IconSearch color="#00743d" size={22} /> 
          <Input
            w="100%"
            variant="unstyled"
            placeholder="Search"
            rightSection={
              <Flex align="center" h="100%" pl={10} style={{ borderLeft: "1px solid #D9D6D6" }}>
                <IconAdjustments color="#00743d" size={22} />
              </Flex>
            }
          />
        </Flex>
      </Box>
      {/* Search section end */}

      {/* Product Carousel start */}
      <Box mt="15">
        <Carousel
          slideSize={150}
          slideGap="12"
          align="center"
          withControls={false}
          loop={true}
        >
          <Carousel.Slide miw="85%">
            <Flex justify="center" align="center" pos="relative">
              <Image
                radius="10px"
                miw="100%"
                h={100}
                fit="cover"
                src="https://i.postimg.cc/CK9zX7d7/pexels-aleksandar-pasaric-2339009.jpg"
              />
              <Text pos="absolute" c='#fff' bg="#000000c2" fw={500} size={20} lh={1} px={12} py={8} style={{ borderRadius: "5px" }}>Super Flash Sale 50% Off</Text>

            </Flex>
          </Carousel.Slide>

          <Carousel.Slide miw="85%" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Flex w="100%" justify="center" align="center" pos="relative">
              <Image
                radius="10px"
                miw="100%"
                h={90}
                fit="cover"
                src="https://i.postimg.cc/CK9zX7d7/pexels-aleksandar-pasaric-2339009.jpg"
              // style={{ transform: "scale(0.8)" }}
              />
              <Text pos="absolute" c='#fff' bg="#000000c2" fw={700} size={18} lh={1} px={12} py={8} style={{ borderRadius: "5px" }}>Super Flash Sale 50% Off</Text>

            </Flex>
          </Carousel.Slide>

          <Carousel.Slide miw="85%" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Flex w="100%" justify="center" align="center" pos="relative">
              <Image
                radius="10px"
                miw="100%"
                h={90}
                fit="cover"
                src="https://i.postimg.cc/CK9zX7d7/pexels-aleksandar-pasaric-2339009.jpg"
              />
              <Text pos="absolute" c='#fff' bg="#000000c2" fw={700} size={18} lh={1} px={12} py={8} style={{ borderRadius: "5px" }}>Super Flash Sale 50% Off</Text>

            </Flex>
          </Carousel.Slide>
        </Carousel>

        <Flex pl={20} pr={30} mt="8" align="center" justify="end">
          <Group gap='6'>
            <Box w={30} h={10} style={{ borderRadius: '100vmax' }} bg='#00743d'></Box>
            <Box w={10} h={10} style={{ borderRadius: '50%' }} bg='#e0e0e0'></Box>
            <Box w={10} h={10} style={{ borderRadius: '50%' }} bg='#e0e0e0'></Box>
            <Box w={10} h={10} style={{ borderRadius: '50%' }} bg='#e0e0e0'></Box>
          </Group>
        </Flex>
      </Box>
      {/* Product Carousel end */}

      {/* categories section start */}
      <Box mt="10" pl={20}>
        <Text c="#2f2e36" fw={700} size={16} lh={1}>Categories</Text>
        <Carousel
          slideGap={5}
          mt="10"
          dragFree
          align="start"
          withControls={false}
        // loop={true}
        >
          <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
            <Box ta="center" c='#323139'>
              <Image
                mih={60}
                miw={60}
                mah={60}
                maw={60}
                src="https://i.postimg.cc/KjpMC4Vg/josh-sorenson-u8-QI4t-RES0-unsplash.jpg"
                style={{ borderRadius: "50%" }}
                fit="cover"
              />
              <Text fz={12} fw={400} c={'#2F2E36'} mt="10">Speaker</Text>
            </Box>
          </Carousel.Slide>

          <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
            <Box ta="center" c='#323139'>
              <Image
                mih={60}
                miw={60}
                mah={60}
                maw={60}
                src="https://i.postimg.cc/rwKdR8Hf/andrew-hutchings-W2-Dta-Yiwfw-unsplash.jpg"
                style={{ borderRadius: "50%" }}
                fit="cover"
              />
              <Text fz={12} fw={400} c={'#2F2E36'} mt="10">Camera</Text>
            </Box>
          </Carousel.Slide>

          <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
            <Box ta="center" c='#323139'>
              <Image
                mih={60}
                miw={60}
                mah={60}
                maw={60}
                src="https://i.postimg.cc/NfwFf68f/simon-daoudi-2w-Foa040m8g-unsplash.jpg"
                style={{ borderRadius: "50%" }}
                fit="cover"
              />
              <Text fz={12} fw={400} c={'#2F2E36'} mt="10">Watches</Text>
            </Box>
          </Carousel.Slide>

          <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
            <Box ta="center" c='#323139'>
              <Image
                mih={60}
                miw={60}
                mah={60}
                maw={60}
                src="https://i.postimg.cc/FzSYCGjw/dylan-calluy-WU4ek4-KCyjw-unsplash.jpg"
                style={{ borderRadius: "50%" }}
                fit="cover"
              />
              <Text fz={12} fw={400} c={'#2F2E36'} mt="10">Projector</Text>
            </Box>
          </Carousel.Slide>

          <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
            <Box ta="center" c='#323139'>
              <Image
                mih={60}
                miw={60}
                mah={60}
                maw={60}
                src="https://i.postimg.cc/QMWZTPhL/lee-campbell-GI6-L2pki-Zg-Q-unsplash.jpg"
                style={{ borderRadius: "50%" }}
                fit="cover"
              />
              <Text fz={12} fw={400} c={'#2F2E36'} mt="10">Headphone</Text>
            </Box>
          </Carousel.Slide>
        </Carousel>
      </Box>
      {/* categories section end */}

      {/* Product cart section start */}
      <Box px={20} mt={-10}>
        <Flex align="center" justify="space-between">
          <Text fz={12} fw={400} c="#A5A4A4">Showing results</Text>
          <Text c="#27733E" size={10} fw={400}>Recomended</Text>
        </Flex>

        <Grid mt={-30}>
          <Grid.Col mt="20" span={6}>
            <Box style={{ borderRadius: '10px', boxShadow: '#64646f36 0px 3px 8px 1px' }} pos="relative" p={15} bg='#fafafa'>
              <Image
                mih={110}
                miw={110}
                mah={110}
                maw={110}
                style={{ borderRadius: "50%", boxShadow: '#9b9b9b 0px 3px 11px 0px' }}
                fit="cover"
                pos="relative"
                top={-30}
                left={0}
                right={0}
                mx="auto"
                src="https://i.postimg.cc/TYY89hfJ/kiran-ck-LSNJ-pltdu8-unsplash.jpg"
              />

              <Box mt={-20}>
                <Flex align="center" justify="space-between">
                  <Text lh={1} size={14} fw={700}>Headphone</Text>
                  <Flex align="center" justify="center" c="#fff" bg="#0096f3" h={18} w={18} p={2} style={{ borderRadius: "50%" }}>
                    <IconCheck></IconCheck>
                  </Flex>
                </Flex>

                <Flex align="center" mt={5} gap={5} c="#00743d">
                  <IconShoppingCart size={20}></IconShoppingCart>
                  <Text lh={1} size={12}>Free Delivery</Text>
                </Flex>

                <Flex align="center" justify="space-between" mt={5} gap={5}>
                  <Flex align="center" gap={5} bg="#fff" py={3} px={5} style={{ border: "1px solid #d9d9d9", borderRadius: "5px" }}>

                    <Flex justify="center" align="center" c="#ffc800">
                      <IconStarFilled size={15}></IconStarFilled>
                    </Flex>
                    <Text size={12} lh={1}>4.7</Text>
                  </Flex>

                  <Flex align="center" gap={5} c="#989494">
                    <IconClockFilled size={15}></IconClockFilled>
                    <Text size={12} lh={1}>24 Hrs</Text>
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Grid.Col>

          <Grid.Col mt="20" span={6}>
            <Box style={{ borderRadius: '10px', boxShadow: '#64646f36 0px 3px 8px 1px' }} pos="relative" p={15} bg='#fafafa'>
              <Image
                mih={110}
                miw={110}
                mah={110}
                maw={110}
                style={{ borderRadius: "50%", boxShadow: '#9b9b9b 0px 3px 11px 0px' }}
                fit="cover"
                pos="relative"
                top={-30}
                left={0}
                right={0}
                mx="auto"
                src="https://i.postimg.cc/j5d69V5m/austin-poon-JO-S6ew-Bq-Ak-unsplash.jpg"
              />

              <Box mt={-20}>
                <Flex align="center" justify="space-between">
                  <Text lh={1} size={14} fw={700}>Laptop Market</Text>
                  <Flex align="center" justify="center" c="#fff" bg="#0096f3" h={18} w={18} p={2} style={{ borderRadius: "50%" }}>
                    <IconCheck></IconCheck>
                  </Flex>
                </Flex>

                <Flex align="center" mt={5} gap={5} c="#00743d">
                  <IconShoppingCart size={20}></IconShoppingCart>
                  <Text lh={1} size={12}>Free Delivery</Text>
                </Flex>

                <Flex align="center" justify="space-between" mt={5} gap={5}>
                  <Flex align="center" gap={5} bg="#fff" py={3} px={5} style={{ border: "1px solid #d9d9d9", borderRadius: "5px" }}>

                    <Flex justify="center" align="center" c="#ffc800">
                      <IconStarFilled size={15}></IconStarFilled>
                    </Flex>
                    <Text size={12} lh={1}>4.7</Text>
                  </Flex>

                  <Flex align="center" gap={5} c="#989494">
                    <IconClockFilled size={15}></IconClockFilled>
                    <Text size={12} lh={1}>24 Hrs</Text>
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Grid.Col>

          <Grid.Col mt="20" span={6}>
            <Box style={{ borderRadius: '10px', boxShadow: '#64646f36 0px 3px 8px 1px' }} pos="relative" p={15} bg='#fafafa'>
              <Image
                mih={110}
                miw={110}
                mah={110}
                maw={110}
                style={{ borderRadius: "50%", boxShadow: '#9b9b9b 0px 3px 11px 0px' }}
                fit="cover"
                pos="relative"
                top={-30}
                left={0}
                right={0}
                mx="auto"
                src="https://i.postimg.cc/NfwFf68f/simon-daoudi-2w-Foa040m8g-unsplash.jpg"
              />

              <Box mt={-20}>
                <Flex align="center" justify="space-between">
                  <Text lh={1} size={14} fw={700}>Watch</Text>
                  <Flex align="center" justify="center" c="#fff" bg="#0096f3" h={18} w={18} p={2} style={{ borderRadius: "50%" }}>
                    <IconCheck></IconCheck>
                  </Flex>
                </Flex>

                <Flex align="center" mt={5} gap={5} c="#00743d">
                  <IconShoppingCart size={20}></IconShoppingCart>
                  <Text lh={1} size={12}>Free Delivery</Text>
                </Flex>

                <Flex align="center" justify="space-between" mt={5} gap={5}>
                  <Flex align="center" gap={5} bg="#fff" py={3} px={5} style={{ border: "1px solid #d9d9d9", borderRadius: "5px" }}>

                    <Flex justify="center" align="center" c="#ffc800">
                      <IconStarFilled size={15}></IconStarFilled>
                    </Flex>
                    <Text size={12} lh={1}>4.7</Text>
                  </Flex>

                  <Flex align="center" gap={5} c="#989494">
                    <IconClockFilled size={15}></IconClockFilled>
                    <Text size={12} lh={1}>24 Hrs</Text>
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Grid.Col>

          <Grid.Col mt="20" span={6}>
            <Box style={{ borderRadius: '10px', boxShadow: '#64646f36 0px 3px 8px 1px' }} pos="relative" p={15} bg='#fafafa'>
              <Image
                mih={110}
                miw={110}
                mah={110}
                maw={110}
                style={{ borderRadius: "50%", boxShadow: '#9b9b9b 0px 3px 11px 0px' }}
                fit="cover"
                pos="relative"
                top={-30}
                left={0}
                right={0}
                mx="auto"
                src="https://i.postimg.cc/j5Gwp4gt/pexels-soulful-pizza-3780681.jpg"
              />

              <Box mt={-20}>
                <Flex align="center" justify="space-between">
                  <Text lh={1} size={14} fw={700}>Airpod</Text>
                  <Flex align="center" justify="center" c="#fff" bg="#0096f3" h={18} w={18} p={2} style={{ borderRadius: "50%" }}>
                    <IconCheck></IconCheck>
                  </Flex>
                </Flex>

                <Flex align="center" mt={5} gap={5} c="#00743d">
                  <IconShoppingCart size={20}></IconShoppingCart>
                  <Text lh={1} size={12}>Free Delivery</Text>
                </Flex>

                <Flex align="center" justify="space-between" mt={5} gap={5}>
                  <Flex align="center" gap={5} bg="#fff" py={3} px={5} style={{ border: "1px solid #d9d9d9", borderRadius: "5px" }}>

                    <Flex justify="center" align="center" c="#ffc800">
                      <IconStarFilled size={15}></IconStarFilled>
                    </Flex>
                    <Text size={12} lh={1}>4.7</Text>
                  </Flex>

                  <Flex align="center" gap={5} c="#989494">
                    <IconClockFilled size={15}></IconClockFilled>
                    <Text size={12} lh={1}>24 Hrs</Text>
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
      {/* Product cart section end */}

      {/* bottom nav start */}
      <Flex align="center" w='100%' ml="auto" mr="auto" bg='#fff' py={15} px={25} justify="space-between" pos="absolute" bottom={0} left={0} right={0} style={{ boxShadow: '#64646f36 0px 3px 8px 1px' }}>
        <Box ta="center" style={{ cursor: 'pointer' }} c='#00743d'>
          <IconHome size={22}></IconHome>
          <Text size={12} lh={1}>Home</Text>
        </Box>
        <Box ta="center" style={{ cursor: 'pointer' }} c='#8b8787'>
          <IconGripVertical size={22}></IconGripVertical>
          <Text size={12} lh={1}>Explore</Text>
        </Box>

        <Box ta="center" style={{ cursor: 'pointer' }} c='#8b8787'>
          <IconClipboardList size={22}></IconClipboardList>
          <Text size={12} lh={1}>Orders</Text>
        </Box>

        <Box ta="center" style={{ cursor: 'pointer' }} c='#8b8787'>
          <IconWallet size={22}></IconWallet>
          <Text size={12} lh={1}>E Wallet</Text>
        </Box>

        <Box ta="center" style={{ cursor: 'pointer' }} c='#8b8787'>
          <IconUser size={22}></IconUser>
          <Text size={12} lh={1}>Profile</Text>
        </Box>
      </Flex>
      {/* bottom nav end */}
    </Box>
  );
};

export default Home;