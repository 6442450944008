import { ActionIcon, Box, Flex, Image, Text, Title } from "@mantine/core";
import {
  IconChevronLeft,
  IconDots,
  IconMenu2,
  IconMinus,
  IconPlus,
} from "@tabler/icons-react";
import React from "react";
const Cart = () => {
  return (
    <Box p={24} pos="relative" h={760}>
      {/* Nav start */}
      <Flex align="center" justify="space-between" mb={30}>
        <ActionIcon
          variant="filled"
          color="#fffff"
          size={40}
          style={{ borderRadius: "100%", border: "1px solid #EDEDED" }}
        >
          <IconChevronLeft
            style={{ width: "30px", height: "30px" }}
            stroke={1.5}
            color="#F13658"
          />
        </ActionIcon>
        <Text fz={16} fw={600} c={'#1B2028'}>
          Cart
        </Text>
        <ActionIcon
          variant="filled"
          color="#ffffff"
          size={40}
          style={{ borderRadius: "100%", border: "1px solid #EDEDED" }}
        >
          <IconMenu2
            style={{ width: "30px", height: "30px" }}
            color="#F13658"
            stroke={1.5}
          />
        </ActionIcon>
      </Flex>
      {/* Nav End */}
      {/* cart item start */}
      <Box>
        <Flex align="center" justify="space-between">
          <Image
            src="https://images.pexels.com/photos/298863/pexels-photo-298863.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt=""
            w={70} 
            h={75}
            radius={'12px'}
          />
          <Box>
            <Title order={6} fz={14} fw={600} c={'#1B2028'}>Modern Light dclothes</Title>
            <Text fz={10} c="#A4AAAD" fw={400} mt={-2} mb={10}> Dress </Text>
            <Text fz={13} fw={600}>
              $233.5
            </Text>
          </Box>
          <Flex direction="column" gap={10}>
            <Flex align="center" justify="flex-end" gap={2}>
              <IconDots size={25} color="#F13658" />
            </Flex>
            <Flex
              align="center"
              justify="flex-end"
              gap={10}
              bg="#F13658"
              w={80}
              px={5}
              py={3}
              style={{ borderRadius: "8px" }}
            >
              <IconMinus size={18} color="#ffffff" />
              <Text fz={13} fw={600} c="#ffffff">
                4
              </Text>
              <IconPlus size={18} color="#ffffff" />
            </Flex>
          </Flex>
        </Flex>

        <Box h={1} bg={'#EDEDED'} my={20}></Box>

        <Flex align="center" justify="space-between">
          <Image
            src="https://images.pexels.com/photos/298863/pexels-photo-298863.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt=""
            w={70} 
            h={75}
            radius={'12px'}
          />
          <Box>
            <Title order={6} fz={14} fw={600} c={'#1B2028'}>Modern Light dclothes</Title>
            <Text fz={10} c="#A4AAAD" fw={400} mt={-2} mb={10}> Dress </Text>
            <Text fz={13} fw={600}>
              $233.5
            </Text>
          </Box>
          <Flex direction="column" gap={10}>
            <Flex align="center" justify="flex-end" gap={2}>
              <IconDots size={25} color="#F13658" />
            </Flex>
            <Flex
              align="center"
              justify="flex-end"
              gap={10}
              bg="#F13658"
              w={80}
              px={5}
              py={3}
              style={{ borderRadius: "8px" }}
            >
              <IconMinus size={18} color="#ffffff" />
              <Text fz={13} fw={600} c="#ffffff">
                4
              </Text>
              <IconPlus size={18} color="#ffffff" />
            </Flex>
          </Flex>
        </Flex>

        <Box h={1} bg={'#EDEDED'} my={20}></Box>

        <Flex align="center" justify="space-between">
          <Image
            src="https://images.pexels.com/photos/298863/pexels-photo-298863.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt=""
            w={70} 
            h={75}
            radius={'12px'}
          />
          <Box>
            <Title order={6} fz={14} fw={600} c={'#1B2028'}>Modern Light dclothes</Title>
            <Text fz={10} c="#A4AAAD" fw={400} mt={-2} mb={10}> Dress </Text>
            <Text fz={13} fw={600}>
              $233.5
            </Text>
          </Box>
          <Flex direction="column" gap={10}>
            <Flex align="center" justify="flex-end" gap={2}>
              <IconDots size={25} color="#F13658" />
            </Flex>
            <Flex
              align="center"
              justify="flex-end"
              gap={10}
              bg="#F13658"
              w={80}
              px={5}
              py={3}
              style={{ borderRadius: "8px" }}
            >
              <IconMinus size={18} color="#ffffff" />
              <Text fz={13} fw={600} c="#ffffff">
                4
              </Text>
              <IconPlus size={18} color="#ffffff" />
            </Flex>
          </Flex>
        </Flex>
      </Box>
      {/* cart item end */}
      <Box pos="absolute" bottom={20}>
        <Flex justify="center" direction="column" w={327}>
          <Flex
            align="center"
            justify="space-between"
            my={10}
            style={{ width: "100%" }}
          >
            <Text fz={14} c={'#1B2028'} fw={400}>Total (9 items)</Text>
            <Text fz={14} fw={600} c={'#1B2028'}>$105</Text>
          </Flex>
          <Box bg="#292526" style={{ borderRadius: "50px" }}>
            <Text ta="center" py="15px" c="#ffffff" fw={700}>
              Checkout
            </Text>
          </Box>
        </Flex>
      </Box>

    </Box>
  );
};

export default Cart;
