import { Carousel } from "@mantine/carousel";
import {
  Box,
  Flex,
  Image,
  Text,
  Input,
  Indicator,
} from "@mantine/core";
import {
  IconUser,
  IconMapPin,
  IconShoppingBag,
  IconBell,
  IconHome,
  IconGripVertical,
  IconClipboardList,
  IconWallet,
  IconClockFilled,
  IconSearch,
  IconAdjustments,
  IconCheck,
  IconStarFilled,
  IconShoppingCart,
} from "@tabler/icons-react";
import React from "react";

const Explore = () => {
  return (
    <Box h='100%' pos='relative'>
      {/* header start */}
      <Flex px={20} pt={20} align="center" justify="space-between">
        <Flex align="center" gap={8}>
          <Image
            mih={28}
            miw={28}
            mah={28}
            maw={28}
            src="https://i.postimg.cc/6678S5G0/pexels-italo-melo-2379005.jpg"
            style={{ borderRadius: "50%" }}
            fit="cover"
          />

          <Box>
            <Text c="#A5A4A4" fz={12} fw={400}>Deleiver to</Text>
            <Text fz={12} fw={600} c="#2F2E36">Location..... <IconMapPin size={14} color="#00743d"></IconMapPin></Text>
          </Box>
        </Flex>

        <Flex align="center" gap={8} c="#00743d">
          <Indicator label={2} size={18} color="#ff1424" offset={4} style={{ transform: "scale(0.6)" }}>
            <IconBell size={30} style={{ transform: "scale(1.1)" }} />
          </Indicator>
          <Indicator position="top-end" color="#ff1424" offset={3} style={{ transform: "scale(0.6)" }}>
            <IconShoppingBag size={30} style={{ transform: "scale(1.1)" }} />
          </Indicator>
        </Flex>
      </Flex>
      {/* header end */}

      {/* Search section start */}
      <Box px={20}>
        <Flex mt="12" py={3} px={10} align="center" gap={10} bg="#fff" style={{ boxShadow: "0px 0px 10px 5px #dbe2de8a", borderRadius: "8px" }}>
          <IconSearch color="#00743d" size={22} /> 
          <Input
            w="100%"
            variant="unstyled"
            placeholder="Search"
            rightSection={
              <Flex align="center" h="100%" pl={10} style={{ borderLeft: "1px solid #D9D6D6" }}>
                <IconAdjustments color="#00743d" size={22} />
              </Flex>
            }
          />
        </Flex>
      </Box>
      {/* Search section end */}

      {/* Top seller section start */}
      <Box>
        <Flex px={20} mt={20} align="center" justify="space-between" mb={-10}>
          <Flex align="center" gap={5}>
            <Text fz={14} fw={600} lh={1} c="#2F2E36">Top Rated Seller's</Text>
            <Flex justify="center" align="center" c="#ffc800">
              <IconStarFilled size={15}></IconStarFilled>
            </Flex>
          </Flex>

          <Text c="#00743d" size={14} lh={1}>Recomended</Text>
        </Flex>

        <Carousel
          mt={20}
          pl={20}
          slideGap="12"
          dragFree
          align="start"
          withControls={false}
          styles={{
            viewport: {
              overflow: "visible",
              overflowX: "clip",
            },
          }}
        >
          <Carousel.Slide maw={220}>
            <Box p={15} bg="#fafafa" style={{ boxShadow: '#64646f36 0px 3px 8px 1px', borderRadius: "5px" }}>
              <Flex gap={10}>
                <Box>
                  <Image
                    mih={40}
                    miw={40}
                    mah={40}
                    maw={40}
                    src="https://i.postimg.cc/3rqQJYg8/jeffrey-keenan-p-Uhxo-Sap-PFA-unsplash.jpg"
                    style={{ borderRadius: "50%", border: "2px solid #00743d" }}
                    fit="cover"
                  />

                  <Flex mt={5} align="center" gap={5} bg="#fff" py={3} px={5} style={{ border: "1px solid #d9d9d9", borderRadius: "5px" }}>
                    <Flex justify="center" align="center" c="#ffc800">
                      <IconStarFilled size={13}></IconStarFilled>
                    </Flex>
                    <Text size={11} lh={1}>4.7</Text>
                  </Flex>
                </Box>

                <Box w="100%">
                  <Flex align="center" justify="space-between">
                    <Text lh={1} size={14} fw={700}>War Electronic</Text>

                    <Flex align="center" justify="center" c="#fff" bg="#0096f3" h={18} w={18} p={2} style={{ borderRadius: "50%" }}>
                      <IconCheck></IconCheck>
                    </Flex>
                  </Flex>

                  <Flex align="center" mt={9} gap={5} c="#00743d">
                    <IconShoppingCart size={18}></IconShoppingCart>
                    <Text size={12} lh={1}>Free Delivery</Text>
                  </Flex>

                  <Flex align="center" mt={9} gap={5} c="#989494">
                    <IconClockFilled size={18}></IconClockFilled>
                    <Text size={12} lh={1}>24 Hrs</Text>
                  </Flex>
                </Box>
              </Flex>

              <Flex mt="7" align="center" gap={10} c="#989494">
                <Text size={12} lh={1}>Laptop</Text>
                <Text size={12} lh={1}>Mobile</Text>
                <Text size={12} lh={1}>Gadget</Text>
              </Flex>
            </Box>
          </Carousel.Slide>

          <Carousel.Slide maw={210}>
            <Box p={15} bg="#fafafa" style={{ boxShadow: '#64646f36 0px 3px 8px 1px', borderRadius: "5px" }}>
              <Flex gap={10}>
                <Box>
                  <Image
                    mih={40}
                    miw={40}
                    mah={40}
                    maw={40}
                    src="https://i.postimg.cc/cHm15jjK/irene-strong-v2a-Knj-Mb-P-k-unsplash.jpg"
                    style={{ borderRadius: "50%", border: "2px solid #00743d" }}
                    fit="cover"
                  />

                  <Flex mt={5} align="center" gap={5} bg="#fff" py={3} px={5} style={{ border: "1px solid #d9d9d9", borderRadius: "5px" }}>
                    <Flex justify="center" align="center" c="#ffc800">
                      <IconStarFilled size={15}></IconStarFilled>
                    </Flex>
                    <Text size={12} lh={1}>4.9</Text>
                  </Flex>
                </Box>

                <Box w="100%">
                  <Flex align="center" justify="space-between">
                    <Text lh={1} size={14} fw={700}>Michal Zhun</Text>

                    <Flex align="center" justify="center" c="#fff" bg="#0096f3" h={18} w={18} p={2} style={{ borderRadius: "50%" }}>
                      <IconCheck></IconCheck>
                    </Flex>
                  </Flex>

                  <Flex align="center" mt={9} gap={5} c="#00743d">
                    <IconShoppingCart size={18}></IconShoppingCart>
                    <Text size={12} lh={1}>Free Delivery</Text>
                  </Flex>

                  <Flex align="center" mt={9} gap={5} c="#989494">
                    <IconClockFilled size={18}></IconClockFilled>
                    <Text size={12} lh={1}>1-2 Days</Text>
                  </Flex>
                </Box>
              </Flex>

              <Flex mt={7} align="center" gap={10} c="#989494">
                <Text size={12} lh={1}>Grapic Card</Text>
                <Text size={12} lh={1}>Computer ports</Text>
              </Flex>
            </Box>
          </Carousel.Slide>
        </Carousel>
      </Box>
      {/* Top seller section end */}

      {/* discover by area section start */}
      <Box mt="20" pl={20} py={15} style={{ boxShadow: '#64646f36 0px 3px 8px 1px' }}>
        <Text fz={14} fw={600} lh={1} c="#2F2E36" mb={-5}>Discover by Area</Text>

        <Carousel
          mt="15"
          slideSize={110}
          // height={250}
          dragFree
          align="start"
          withControls={false}
          slideGap="12"
        >
          <Carousel.Slide>
            <Flex justify="center" align="center" pos="relative">
              <Image
                w={100}
                h={80}
                src="https://i.postimg.cc/YCJk7DN4/pexels-sohid-hasan-17830385.jpg"
                fit="cover"
                radius="10px"
              />
              <Text pos="absolute" style={{ borderRadius: '5px' }} bg="#00743da8" c='#fff' fw={700} size={13} lh={1} ta="center" py={6} px={10}>Dhaka</Text>
            </Flex>
          </Carousel.Slide>

          <Carousel.Slide>
            <Flex justify="center" align="center" pos="relative">
              <Image
                w={100}
                h={80}
                src="https://i.postimg.cc/TYjfxJ7Q/Screenshot-from-2024-02-09-15-35-08.png"
                fit="cover"
                radius="10px"
              />
              <Text pos="absolute" style={{ borderRadius: '5px' }} bg="#00743da8" c='#fff' fw={700} size={13} lh={1} ta="center" py={6} px={10}>Bogura</Text>
            </Flex>
          </Carousel.Slide>

          <Carousel.Slide>
            <Flex justify="center" align="center" pos="relative">
              <Image
                w={100}
                h={80}
                src="https://i.postimg.cc/Wz2SkjVd/pexels-asif-al-naeem-19102359.jpg"
                fit="cover"
                radius="10px"
              />
              <Text pos="absolute" style={{ borderRadius: '5px' }} bg="#00743da8" c='#fff' fw={700} size={13} lh={1} ta="center" py={6} px={10}>Sylhet</Text>
            </Flex>
          </Carousel.Slide>

          <Carousel.Slide>
            <Flex justify="center" align="center" pos="relative">
              <Image
                w={100}
                h={80}
                src="https://i.postimg.cc/YCJk7DN4/pexels-sohid-hasan-17830385.jpg"
                fit="cover"
                radius="10px"
              />
              <Text pos="absolute" style={{ borderRadius: '5px' }} bg="#00743da8" c='#fff' fw={700} size={13} lh={1} ta="center" py={6} px={10}>Dhaka</Text>
            </Flex>
          </Carousel.Slide>
        </Carousel>
      </Box>
      {/* discover by area section end */}

      {/* Trending Items start */}
      <Box mt="20" pl={20}>
        <Text fz={14} fw={600} lh={1} c="#2F2E36" mb={-5}>Tending Items 🔥</Text>

        <Carousel
          mt="35"
          slideSize={80}
          slideGap="12"
          align="start"
          withControls={false}
          styles={{
            viewport: {
              overflow: "visible",
              overflowX: "clip",
            },
          }}
        >
          <Carousel.Slide>
            <Box style={{ borderRadius: '10px', boxShadow: '#64646f36 0px 3px 8px 1px' }} ta="center" pos="relative" p={15} bg='#fafafa'>
              <Image
                mih={65}
                miw={65}
                mah={65}
                maw={65}
                style={{ borderRadius: "50%", boxShadow: '#9b9b9b 0px 3px 11px 0px' }}
                fit="cover"
                pos="relative"
                top={-30}
                left={0}
                right={0}
                mx="auto"
                src="https://i.postimg.cc/j5Gwp4gt/pexels-soulful-pizza-3780681.jpg"
              />
              <Text size={15} lh="1px" mt={-15}>Airpods</Text>
            </Box>
          </Carousel.Slide>

          <Carousel.Slide>
            <Box style={{ borderRadius: '10px', boxShadow: '#64646f36 0px 3px 8px 1px' }} ta="center" pos="relative" p={15} bg='#fafafa'>
              <Image
                mih={65}
                miw={65}
                mah={65}
                maw={65}
                style={{ borderRadius: "50%", boxShadow: '#9b9b9b 0px 3px 11px 0px' }}
                fit="cover"
                pos="relative"
                top={-30}
                left={0}
                right={0}
                mx="auto"
                src="https://i.postimg.cc/vm26S4VF/iphone15pro-digitalmat-gallery-3-202309.png"
              />
              <Text size={15} lh="1px" mt={-15}>Phone</Text>
            </Box>
          </Carousel.Slide>

          <Carousel.Slide>
            <Box style={{ borderRadius: '10px', boxShadow: '#64646f36 0px 3px 8px 1px' }} ta="center" pos="relative" p={15} bg='#fafafa'>
              <Image
                mih={65}
                miw={65}
                mah={65}
                maw={65}
                style={{ borderRadius: "50%", boxShadow: '#9b9b9b 0px 3px 11px 0px' }}
                fit="cover"
                pos="relative"
                top={-30}
                left={0}
                right={0}
                mx="auto"
                src="https://i.postimg.cc/Sspp4r8W/daniel-korpai-cnxs-Zi5-X-58-unsplash.jpg"
              />
              <Text size={15} lh="1px" mt={-15}>Tablet</Text>
            </Box>
          </Carousel.Slide>

          <Carousel.Slide>
            <Box style={{ borderRadius: '10px', boxShadow: '#64646f36 0px 3px 8px 1px' }} ta="center" pos="relative" p={15} bg='#fafafa'>
              <Image
                mih={65}
                miw={65}
                mah={65}
                maw={65}
                style={{ borderRadius: "50%", boxShadow: '#9b9b9b 0px 3px 11px 0px' }}
                fit="cover"
                pos="relative"
                top={-30}
                left={0}
                right={0}
                mx="auto"
                src="https://i.postimg.cc/vm26S4VF/iphone15pro-digitalmat-gallery-3-202309.png"
              />
              <Text size={15} lh="1px" mt={-15}>Smart Phone</Text>
            </Box>
          </Carousel.Slide>
        </Carousel>
      </Box>
      {/* Trending Items end */}

      {/* Discounts & Offers section start */}
      <Box mt="20" pl={20} pt={15} style={{ boxShadow: '#64646f0f 0px -5px 8px 1px' }}>
        <Text fz={14} fw={600} lh={1} c="#2F2E36" mb={-5}>Discounts & Offers</Text>
        <Carousel
          mt="15"
          slideSize={110}
          dragFree
          align="start"
          withControls={false}
          slideGap="12"
        >
          <Carousel.Slide>
            <Box pos="relative">
              <Image
                w={100}
                h={80}
                src="https://i.postimg.cc/Kjzphk9P/erik-mclean-U-Vu-r6qyy-U-unsplash.jpg"
                fit="cover"
                radius="10px"
              />
              <Text pos="absolute" top={5} left={5} style={{ borderRadius: '5px' }} bg="#00743da8" c='#fff' fw={700} size={10} lh={1} ta="center" py={4} px={8}>20% OFF</Text>
            </Box>
          </Carousel.Slide>

          <Carousel.Slide>
            <Box pos="relative">
              <Image
                w={100}
                h={80}
                src="https://i.postimg.cc/J4S6ShjX/nick-loggie-f-RVPz-BYcd5-A-unsplash.jpg"
                fit="cover"
                radius="10px"
              />
              <Text pos="absolute" top={5} left={5} style={{ borderRadius: '5px' }} bg="#00743da8" c='#fff' fw={700} size={10} lh={1} ta="center" py={4} px={8}>10% OFF</Text>
              <Text pos="absolute" bottom={5} right={5} style={{ borderRadius: '5px' }} bg="#00743da8" c='#fff' fw={700} size={9} lh={1} ta="center" py={4} px={8}>1 Day Left</Text>
            </Box>
          </Carousel.Slide>

          <Carousel.Slide>
            <Box pos="relative">
              <Image
                w={100}
                h={80}
                src="https://i.postimg.cc/YCwVh9jH/akhil-yerabati-Q2u-V5-Tkj-Nz8-unsplash.jpg"
                fit="cover"
                radius="10px"
              />
              <Text pos="absolute" top={5} left={5} style={{ borderRadius: '5px' }} bg="#00743da8" c='#fff' fw={700} size={10} lh={1} ta="center" py={4} px={8}>15% OFF</Text>
            </Box>
          </Carousel.Slide>

          <Carousel.Slide>
            <Box pos="relative">
              <Image
                w={100}
                h={80}
                src="https://i.postimg.cc/rwKdR8Hf/andrew-hutchings-W2-Dta-Yiwfw-unsplash.jpg"
                fit="cover"
                radius="10px"
              />
            </Box>
          </Carousel.Slide>
        </Carousel>
      </Box>
      {/* Discounts & Offers section end */}

      {/* bottom nav start */}
      <Flex align="center" w='100%' ml="auto" mr="auto" bg='#fff' py={15} px={25} justify="space-between" pos="absolute" bottom={0} left={0} right={0} style={{ boxShadow: '#64646f36 0px 3px 8px 1px' }}>
        <Box ta="center" style={{ cursor: 'pointer' }} c='#8b8787'>
          <IconHome size={23}></IconHome>
          <Text size={12} lh={1}>Home</Text>
        </Box>
        <Box ta="center" style={{ cursor: 'pointer' }} c='#00743d'>
          <IconGripVertical size={23}></IconGripVertical>
          <Text size={12} lh={1}>Explore</Text>
        </Box>

        <Box ta="center" style={{ cursor: 'pointer' }} c='#8b8787'>
          <IconClipboardList size={23}></IconClipboardList>
          <Text size={12} lh={1}>Orders</Text>
        </Box>

        <Box ta="center" style={{ cursor: 'pointer' }} c='#8b8787'>
          <IconWallet size={23}></IconWallet>
          <Text size={12} lh={1}>E Wallet</Text>
        </Box>

        <Box ta="center" style={{ cursor: 'pointer' }} c='#8b8787'>
          <IconUser size={23}></IconUser>
          <Text size={12} lh={1}>Profile</Text>
        </Box>
      </Flex>
      {/* bottom nav end */}
    </Box>
  );
};

export default Explore;