import { Carousel } from "@mantine/carousel";
import {
  Box,
  Flex,
  Image,
  Text,
  ActionIcon,
  Group,
  ColorSwatch,
  Button
} from "@mantine/core";
import {
  IconShare,
  IconHeart,
  IconChevronLeft,
  IconStarFilled,
} from "@tabler/icons-react";
import React from "react";

const ProductDetails = () => {
  return (
    <Box style={{ position: 'relative' }}>
      {/* header start */}
      <Flex p={20} align="center" justify="space-between">
        <ActionIcon bg="#ffffff" variant="filled" radius="50%" aria-label="Settings">
          <IconChevronLeft style={{ width: '70%', height: '70%', color: '#cfcfcf' }} stroke={1.5} />
        </ActionIcon>

        <Box>
          <ActionIcon
            bg="#ffffff"
            mr={6}
            variant="filled"
            radius="50%"
            aria-label="Settings"
          >
            <IconHeart
              style={{ width: "70%", height: "70%", color: "#cfcfcf" }}
              stroke={1.5}
            />
          </ActionIcon>

          <ActionIcon
            bg="#ffffff"
            variant="filled"
            radius="50%"
            aria-label="Settings"
          >
            <IconShare
              style={{ width: "70%", height: "70%", color: "#cfcfcf" }}
              stroke={1.5}
            />
          </ActionIcon>
        </Box>
      </Flex>
      {/* header end */}

      {/* product slider start */}
      <Box p={12} mt={32} maw={350} mx='auto' h="auto" w="100%">
        <Carousel
          slideSize={250}
          // height={250}
          slideGap={10}
          dragFree
          align="center"
          justify="center"
          withControls={false}
          styles={{
            viewport: {
              overflow: "visible",
              overflowX: "clip",
            },
          }}
        >
          <Carousel.Slide>
            <Box pos="relative" w={250}>
              <Flex align="center" justify="center" h={150} style={{ borderRadius: "30px", background: 'linear-gradient(180deg, #ffffff 0%, #f8f7f5 100%)' }}>
                <Image
                  pos="absolute"
                  w={150}
                  h="auto"
                  top={-20}
                  src="https://i.postimg.cc/fWvhLRw7/Png-Item-891873.png"
                />
              </Flex>
            </Box>
          </Carousel.Slide>
        </Carousel>

        <Group gap='6' mt={85} justify="center">
          <Box w={5} h={5} style={{ borderRadius: '50%' }} bg='#d1d1d1'></Box>
          <Box w={15} h={5} style={{ borderRadius: '100vmax' }} bg='#4285f4'></Box>
          <Box w={5} h={5} style={{ borderRadius: '50%' }} bg='#d1d1d1'></Box>
        </Group>

        <Flex align="center" justify="center" gap={10} mt={20}>
          <Flex align="center" justify="center" p={6} w={70} h={70} bg='#fff' style={{ borderRadius: "20px"}}>
            <Image
              mah={50}
              src="https://i.postimg.cc/fWvhLRw7/Png-Item-891873.png"
            />
          </Flex>

          <Flex align="center" justify="center" p={6} w={70} h={70} bg='#fff' style={{ borderRadius: "20px", border: "1px solid #dbeeff" }}>
            <Image
              mah={50}
              src="https://i.postimg.cc/fWvhLRw7/Png-Item-891873.png"
            />
          </Flex>

          <Flex align="center" justify="center" p={6} w={70} h={70} bg='#fff' style={{ borderRadius: "20px"}}>
            <Image
              mah={50}
              src="https://i.postimg.cc/fWvhLRw7/Png-Item-891873.png"
            />
          </Flex>
        </Flex>
      </Box>
      {/* product slider end */}

      {/* product details section start */}
      <Box p={23} mt={32} bg="#fff" style={{ borderTopLeftRadius: "30px", borderTopRightRadius: "30px", boxShadow: '#64646f29 0px -2px 29px 0px' }}>
        <Flex align="center" justify="space-between">
          <Text tt="uppercase" size={12} lh="18px" c="#b5b5b5" fw={700}>free shipping</Text>
          <Flex align="center" justify="center" gap={5}>
            <Text c="#e6bb66">
              <IconStarFilled size={15}></IconStarFilled>
            </Text>
            <Text c="#asdfa" size={14} lh={1} fw={700}>4.8</Text>
            <Text c="#b5b5b5" size={14} lh={1}>(231)</Text>
          </Flex>
        </Flex>

        <Text fw={500} mt={12}>Sony WH-1000XM4</Text>

        <Text size={12} lh="18px" c="#b5b5b5" mt={12}>
          The intuitive and intelligent WH-1000XM4 headphones bring you new imporvements in industry-leading noise cancelling technolgoy.
        </Text>

        <Flex mt={20} align="center" justify="space-between">
          <Text fw={700} c="#4285f4">$ 4.999</Text>

          <Group>
            <Box>
              <Flex align="center" justify="center" pos="relative" w={26} h={26} style={{ borderRadius: "50%", border: "1px solid #3f4343" }}>
                <ColorSwatch pos="absolute" top={2} left={2} mx="auto" color="#3f4343" size={20} />
              </Flex>
            </Box>

            <Box>
              <Flex align="center" justify="center" pos="relative" w={26} h={26} style={{ borderRadius: "50%" }}>
                <ColorSwatch pos="absolute" top={2} left={2} mx="auto" color="#ccc6ba" size={20} />
              </Flex>
            </Box>

            <Box>
              <Flex align="center" justify="center" pos="relative" w={26} h={26} style={{ borderRadius: "50%" }}>
                <ColorSwatch pos="absolute" top={2} left={2} mx="auto" color="#cfcfcf" size={20} />
              </Flex>
            </Box>
          </Group>
        </Flex>

        <Flex mt={20} align="center" justify="space-between">
          <Flex gap={20} c="#b5b5b5" p={10} align="center" h={30} style={{ border: "1px solid #b5b5b5", borderRadius: "30px", }}>
            <Text size={20} lh={1}>-</Text>
            <Text size={14} lh={1}>1</Text>
            <Text size={20} lh={1}>+</Text>
          </Flex>
          <Button bg="#4285f4" c="#ffffff" style={{ borderRadius: "30px", flexBasis: "65%" }}>Buy Now</Button>
        </Flex>
      </Box>
      {/* product details section end */}
    </Box>
  );
};

export default ProductDetails;
