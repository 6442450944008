import { Box, Flex, Image, Text, Title } from "@mantine/core";
import { IconChevronLeft, IconMail, IconEdit, IconPhone, IconCheck, IconChevronDown } from "@tabler/icons-react";
import React from "react";

export const Checkout = () => {
  return (
    <Box h="100%" pos="relative">

        {/* Top Navigation Start */}
        <Flex justify={'flex-start'} align={'center'} px={20} pt={20} mb={25 }>
            <IconChevronLeft color="#27733E" size={22} />
            <Text ml={110} c={'#27733E'} fz={16} fw={600}> Checkout </Text>
        </Flex>
        {/* Top Navigation End */}

        {/* Checkout Section Start */}
        <Box px={20}>
          <Box p={15} bg={'linear-gradient(99deg, #27733E -0.82%, #27733E 101.53%)'} style={{ borderRadius: "10px" }}>
            <Flex justify={'space-between'} align={'center'}>
              <Text c={'#FEFEFF'} fz={14} fw={400}> Sub-Total </Text>
              <Text c={'#FEFEFF'} fz={14} fw={400} style={{ flexBasis: "25%" }}> $ 2,760 </Text>
            </Flex>
            <Flex justify={'space-between'} align={'center'}>
              <Text c={'#FEFEFF'} fz={14} fw={400}> Delivery Charge </Text>
              <Text c={'#FEFEFF'} fz={14} fw={400} style={{ flexBasis: "25%" }}> Free </Text>
            </Flex>
            <Flex justify={'space-between'} align={'center'}>
              <Text c={'#FEFEFF'} fz={14} fw={400}> Discount </Text>
              <Text c={'#FEFEFF'} fz={14} fw={400} style={{ flexBasis: "25%" }}> $ 2,760 </Text>
            </Flex>
            <Flex justify={'space-between'} align={'center'} mt={15}>
              <Text c={'#FEFEFF'} fz={16} fw={700}> Total </Text>
              <Text c={'#FEFEFF'} fz={16} fw={700} style={{ flexBasis: "25%" }}> $ 2,760 </Text>
            </Flex>
          </Box>
        </Box>
        {/* Checkout Section End */}

        {/* Contact Info Start */}
        <Box bg={'#ffffff'} px={35} style={{ borderRadius: "15px" }} mt={25}>
          <Title c={'#1A2530'} fz={14} fw={500} mb={10}> Contact Information </Title>

          {/* Email */}
          <Flex justify={'space-between'} align={'center'} mb={10}>
            <Flex justify={'flex-start'} align={'center'}>
              {/* Icon Box */}
              <Flex justify={'center'} align={'center'} bg={'#F8F9FA'} w={42} h={42} mr={12} style={{ borderRadius: "12px" }}>
                <IconMail color="#27733E" size={22} />
              </Flex>
              {/* Email box */}
              <Box>
                <Text c={"#1A2530"} fz={14} fw={500}> sample@gmail.com </Text>
                <Text c={"#707B81"} fz={12} fw={500}> Email </Text>
              </Box>
            </Flex>
            {/* Edit Icon */}
            <IconEdit color="#27733E" size={22} />
          </Flex>

          {/* Phone */}
          <Flex justify={'space-between'} align={'center'}>
            <Flex justify={'flex-start'} align={'center'}>
              {/* Icon Box */}
              <Flex justify={'center'} align={'center'} bg={'#F8F9FA'} w={42} h={42} mr={12} style={{ borderRadius: "12px" }}>
                <IconPhone color="#27733E" size={22} />
              </Flex>
              {/* Text box */}
              <Box>
                <Text c={"#1A2530"} fz={14} fw={500}> +234-811-732-5298 </Text>
                <Text c={"#707B81"} fz={12} fw={500}> Phone </Text>
              </Box>
            </Flex>
            {/* Edit Icon */}
            <IconEdit color="#27733E" size={22} />
          </Flex>

          {/* Payment Box */}
          <Box mt={30}>
            <Title c={'#1A2530'} fz={14} fw={500} mt={15} mb={15}> Payment Method </Title>
            <Flex justify={'space-between'} align={'center'}>
              <Flex>
                <Flex justify={'center'} align={'center'} h={40} w={40} bg={'#F8F9FA'} p={5} mr={12} style={{ borderRadius: "12px" }}>
                  <Image src={'https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/363_Visa_Credit_Card_logo-512.png'} />
                </Flex>
                <Box>
                  <Title c={'#1A2530'} fz={14} fw={500} mb={5}> Debit Card </Title>
                  <Text c={'#707B81'} fz={12} fw={500}> **** **** 0696 4629 </Text>
                </Box>
              </Flex>
              <IconChevronDown size={22} color="#27733E" />
            </Flex>

            <Text c={'#32343E'} fz={14} fw={400} mt={25}> Select </Text>
            <Flex align={'center'} mt={10}>

              {/* delivery */}
              <Flex justify={'flex-start'} align={'center'} style={{ cursor: "pointer" }}>
                <Flex align={'center'} justify={'center'} w={15} h={15} bg={'#27733E'} style={{ borderRadius: "50%" }}>
                  <IconCheck color="#ffffff" size={12} />
                </Flex>
                <Text c={'#27733E'} fz={12} fw={500} ml={7}>Delivery</Text>
              </Flex>

              {/* pickup */}
              <Flex justify={'flex-start'} align={'center'} ml={15} style={{ cursor: "pointer" }}>
                <Flex align={'center'} justify={'center'} w={14} h={14} bg={'#FFFDFD'} style={{ border: "2px solid #27733E", borderRadius: "50%" }}>
                </Flex>
                <Text c={'#A5A4A4'} fz={12} fw={500} ml={6}>Pickup</Text>
              </Flex>

            </Flex>

            <Text c={'#32343E'} fz={14} fw={400} mt={25}> Additional Details </Text>
            <Box mt={10} h={65} p={15} style={{ border: "1px solid #E8EAED", borderRadius: "10px", cursor: "text" }}>
              <Text c={'#A5A4A4'} fz={12} fw={400}> Additional Details </Text>
            </Box>

          </Box>

        </Box>
        {/* Contact Info End */}

        {/* Order Button Start */}
        <Box px={20} pos="absolute" bottom={20} w="100%">
          <Flex justify={'center'} align={'center'} h={55} bg={'#27733E'} style={{ borderRadius: "10px", cursor: "pointer" }}>
            <Text c={'#ffffff'} fz={14} fw={600}>Place My Order</Text>
          </Flex>
        </Box>
        {/* Order Button End */}

    </Box>
  )
}
