import { ActionIcon, Box, Flex, Image, Text, Title } from "@mantine/core";
import {
  IconArrowsUpDown,
  IconBell,
  IconChevronLeft,
  IconKey,
  IconMessage,
  IconQuestionMark,
  IconScan,
  IconSettings,
  IconUsers,
  IconLogout
} from "@tabler/icons-react";
import React from "react";
const Profile = () => {
  return (
    <Box py={24} pos="relative">

      {/* Nav start */}
      <Flex align="center" justify="space-between" px={24} mb={30}>
        <ActionIcon
          variant="filled"
          color="#fffff"
          size={40}
          style={{ borderRadius: "100%", border: "1px solid #EDEDED" }}
        >
          <IconChevronLeft
            style={{ width: "30px", height: "30px" }}
            stroke={1.5}
            color="#F13658"
          />
        </ActionIcon>
        <Text fz={16} fw={600} c={'#1B2028'}>
          Profile
        </Text>
        <ActionIcon
          variant="filled"
          color="#ffffff"
          size={40}
          style={{ borderRadius: "100%", border: "1px solid #EDEDED" }}
        >
          <IconSettings
            style={{ width: "30px", height: "30px" }}
            color="#F13658"
            stroke={1.5}
          />
        </ActionIcon>
      </Flex>
      {/* Nav out */}

      {/* Profile Section Start */}
      <Flex px={24} justify={'space-between'} align={'center'} mt={30}>
        <Flex justify={'flex-start'} align={'center'}>
          {/* image */}
          <Box style={{ border: "2px solid #F13658", borderRadius: "50%" }}>
            <Box w={55} h={55} style={{ border: "2px solid #ffffff", borderRadius: "50%", overflow: "hidden" }}>
              <Image w={'100%'} h={'100%'} fit="cover" src={'https://images.pexels.com/photos/3778680/pexels-photo-3778680.jpeg'} />
            </Box>
          </Box>
          {/* name */}
          <Box ml={12}>
            <Title c={'#000000'} fz={20} fw={400} mb={5}> Noorkhan </Title>
            <Text c={'#797C7B'} fz={12} fw={400}> Spread Love </Text>
          </Box>
        </Flex>
        <IconScan color="#F13658" size={22} />
      </Flex>
      {/* Profile Section End */}

      {/* Section Divider */}
      <Box h={1} bg={'#797c7b2b'} my={25}></Box>

      {/* Options Start */}
      <Box px={24} pb={40}>

        {/* Account */}
        <Flex justify={'flex-start'} align={'center'} mb={30}>
          <Flex justify={'center'} align={'center'} h={44} w={44} style={{ borderRadius: "50%", boxShadow: "0px 0px 4px #00000026" }}>
            <IconKey color="#F13658" size={22} />
          </Flex>
          <Box ml={20}>
            <Text c={'#232323'} fz={16} fw={400}> Account </Text>
            <Text c={'#797C7B'} fz={12} fw={400}> Privacy, security, change number </Text>
          </Box>
        </Flex>

        {/* Chat */}
        <Flex justify={'flex-start'} align={'center'} mb={30}>
          <Flex justify={'center'} align={'center'} h={44} w={44} style={{ borderRadius: "50%", boxShadow: "0px 0px 4px #00000026" }}>
            <IconMessage color="#F13658" size={22} />
          </Flex>
          <Box ml={20}>
            <Text c={'#232323'} fz={16} fw={400}> Chat </Text>
            <Text c={'#797C7B'} fz={12} fw={400}> Chat history,theme,wallpapers </Text>
          </Box>
        </Flex>

        {/* Notifications */}
        <Flex justify={'flex-start'} align={'center'} mb={30}>
          <Flex justify={'center'} align={'center'} h={44} w={44} style={{ borderRadius: "50%", boxShadow: "0px 0px 4px #00000026" }}>
            <IconBell color="#F13658" size={22} />
          </Flex>
          <Box ml={20}>
            <Text c={'#232323'} fz={16} fw={400}> Notifications </Text>
            <Text c={'#797C7B'} fz={12} fw={400}> Messages, group and others </Text>
          </Box>
        </Flex>

        {/* Help */}
        <Flex justify={'flex-start'} align={'center'} mb={30}>
          <Flex justify={'center'} align={'center'} h={44} w={44} style={{ borderRadius: "50%", boxShadow: "0px 0px 4px #00000026" }}>
            <IconQuestionMark color="#F13658" size={22} />
          </Flex>
          <Box ml={20}>
            <Text c={'#232323'} fz={16} fw={400}> Help </Text>
            <Text c={'#797C7B'} fz={12} fw={400}> Help center,contact us, privacy policy </Text>
          </Box>
        </Flex>

        {/* Storage and data */}
        <Flex justify={'flex-start'} align={'center'} mb={30}>
          <Flex justify={'center'} align={'center'} h={44} w={44} style={{ borderRadius: "50%", boxShadow: "0px 0px 4px #00000026" }}>
            <IconArrowsUpDown color="#F13658" size={22} />
          </Flex>
          <Box ml={20}>
            <Text c={'#232323'} fz={16} fw={400}> Storage and data </Text>
            <Text c={'#797C7B'} fz={12} fw={400}> Network usage, stogare usage </Text>
          </Box>
        </Flex>

        {/* Invite a friend */}
        <Flex justify={'flex-start'} align={'center'} mb={30}>
          <Flex justify={'center'} align={'center'} h={44} w={44} style={{ borderRadius: "50%", boxShadow: "0px 0px 4px #00000026" }}>
            <IconUsers color="#F13658" size={22} />
          </Flex>
          <Box ml={20}>
            <Text c={'#232323'} fz={16} fw={400}> Invite a friend </Text>
          </Box>
        </Flex>

        {/* Logout */}
        <Flex justify={'flex-start'} align={'center'}>
          <Flex justify={'center'} align={'center'} h={44} w={44} style={{ borderRadius: "50%", boxShadow: "0px 0px 4px #00000026" }}>
            <IconLogout color="#F13658" size={22} />
          </Flex>
          <Box ml={20}>
            <Text c={'#232323'} fz={16} fw={400}> Logout </Text>
          </Box>
        </Flex>

      </Box>
      {/* Options End */}

      {/* Bottom Nav Start */}
      <Box pos={'absolute'} bottom={10} left={'50%'} w={135} h={5} bg={'#101010'} style={{ borderRadius: "25px", transform: "translateX(-50%)" }}></Box>
      {/* Bottom Nav End */}

    </Box>
  );
};

export default Profile;
