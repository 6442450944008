import { Box, Flex, Image, Text, Title } from "@mantine/core";
import { IconChevronLeft, IconCameraFilled, IconMail, IconEdit, IconKey, IconArrowsUpDown, IconQuestionMark, IconUsers, IconLogout, IconPhone } from "@tabler/icons-react";
import React from "react";

const Profile = () => {
  return (
    <Box py={8} pos={'relative'}>

      {/* Template Title Start */}
      <Text c={"#ffffff"} pos={'absolute'} top={-65} fw={600} fz={20} left={'50%'} w={"100%"} ta={'center'} style={{ transform: "translateX(-50%)" }}>Profile Template</Text>
        {/* Template Title End */}

        {/* Top Navigation Start */}
        <Flex justify={'flex-start'} align={'center'} mb={20}>
            <Flex justify={'center'} align={'center'} w={45} h={45} bg={"#ffffff"} style={{ borderRadius: "50%" }}>
                <IconChevronLeft color="#1b77fe" size={22} />
            </Flex>
            <Text c={'#2B2B2B'} fz={16} fw={600} ml={90}> Profile </Text>
        </Flex>
        {/* Top Navigation End */}

        {/* Profile Image Start */}
        <Box>
          <Flex pos={'relative'} justify={'space-between'} align={'center'}>
            <Image w={95} h={95} mx={'auto'} src="https://images.pexels.com/photos/3778680/pexels-photo-3778680.jpeg" style={{ borderRadius: "50%", border: "4px solid #2C80FD" }} />
            <Flex pos={'absolute'} left={'56%'} bottom={0} justify={'center'} align={'center'} h={22} w={22} c={"#fff"} bg={'#006FDC'} style={{ borderRadius: "50%" }} >
              <IconCameraFilled size={13} />
            </Flex>
          </Flex>
          <Title c={'#2C80FD'} fz={14} ta={'center'} fw={500} mt={10}> Display Name </Title>
        </Box>
        {/* Profile Image End */}

        {/* White BG Section Start */}
        <Box bg={'#ffffff'} p={15} mt={15} style={{ borderRadius: "15px" }}>

          {/* contact info */}
          <Title c={'#1A2530'} fz={14} fw={500} mb={15}> Contact Information </Title>

          {/* Email */}
          <Flex justify={'space-between'} align={'center'} mb={15}>
            <Flex justify={'flex-start'} align={'center'}>
              {/* Icon Box */}
              <Flex justify={'center'} align={'center'} bg={'#F8F9FA'} w={42} h={42} mr={12} style={{ borderRadius: "12px" }}>
                <IconMail color="#0D6EFD" size={22} />
              </Flex>
              {/* Email box */}
              <Box>
                <Text c={"#1A2530"} fz={14} fw={500}> sample@gmail.com </Text>
                <Text c={"#707B81"} fz={12} fw={500}> Email </Text>
              </Box>
            </Flex>
            {/* Edit Icon */}
            <IconEdit color="#2C80FD" size={22} />
          </Flex>

          {/* Phone */}
          <Flex justify={'space-between'} align={'center'}>
            <Flex justify={'flex-start'} align={'center'}>
              {/* Icon Box */}
              <Flex justify={'center'} align={'center'} bg={'#F8F9FA'} w={42} h={42} mr={12} style={{ borderRadius: "12px" }}>
                <IconPhone color="#0D6EFD" size={22} />
              </Flex>
              {/* Text box */}
              <Box>
                <Text c={"#1A2530"} fz={14} fw={500}> +234-811-732-5298 </Text>
                <Text c={"#707B81"} fz={12} fw={500}> Phone </Text>
              </Box>
            </Flex>
            {/* Edit Icon */}
            <IconEdit color="#2C80FD" size={22} />
          </Flex>

          {/* general info */}
          <Title c={'#1A2530'} fz={14} fw={500} mt={15} mb={15}> General Information </Title>

          {/* Account */}
          <Flex justify={'space-between'} align={'center'} mb={15}>
            <Flex justify={'flex-start'} align={'center'}>
              {/* Icon Box */}
              <Flex justify={'center'} align={'center'} bg={'#F8F9FA'} w={42} h={42} mr={12} style={{ borderRadius: "12px" }}>
                <IconKey color="#0D6EFD" size={22} />
              </Flex>
              {/* Text box */}
              <Box>
                <Text c={"#1A2530"} fz={14} fw={500}> Account </Text>
                <Text c={"#707B81"} fz={12} fw={500}> Privacy, security, update </Text>
              </Box>
            </Flex>
          </Flex>

          {/* Payments & Bills */}
          <Flex justify={'space-between'} align={'center'} mb={15}>
            <Flex justify={'flex-start'} align={'center'}>
              {/* Icon Box */}
              <Flex justify={'center'} align={'center'} bg={'#F8F9FA'} w={42} h={42} mr={12} style={{ borderRadius: "12px" }}>
                <IconArrowsUpDown color="#0D6EFD" size={22} />
              </Flex>
              {/* Text box */}
              <Box>
                <Text c={"#1A2530"} fz={14} fw={500}> Payments & Bills </Text>
                <Text c={"#707B81"} fz={12} fw={500}> Record, History, update </Text>
              </Box>
            </Flex>
          </Flex>

          {/* Help */}
          <Flex justify={'space-between'} align={'center'} mb={15}>
            <Flex justify={'flex-start'} align={'center'}>
              {/* Icon Box */}
              <Flex justify={'center'} align={'center'} bg={'#F8F9FA'} w={42} h={42} mr={12} style={{ borderRadius: "12px" }}>
                <IconQuestionMark color="#0D6EFD" size={22} />
              </Flex>
              {/* Text box */}
              <Box>
                <Text c={"#1A2530"} fz={14} fw={500}> Help </Text>
                <Text c={"#707B81"} fz={12} fw={500}> Help centre, privacy policy </Text>
              </Box>
            </Flex>
          </Flex>

          {/* Invite Friend */}
          <Flex justify={'space-between'} align={'center'} mb={15}>
            <Flex justify={'flex-start'} align={'center'}>
              {/* Icon Box */}
              <Flex justify={'center'} align={'center'} bg={'#F8F9FA'} w={42} h={42} mr={12} style={{ borderRadius: "12px" }}>
                <IconUsers color="#0D6EFD" size={22} />
              </Flex>
              {/* Text box */}
              <Text c={"#1A2530"} fz={14} fw={500}> Invite Friend </Text>
            </Flex>
          </Flex>

          {/* Logout */}
          <Flex justify={'space-between'} align={'center'}>
            <Flex justify={'flex-start'} align={'center'}>
              {/* Icon Box */}
              <Flex justify={'center'} align={'center'} bg={'#F8F9FA'} w={42} h={42} mr={12} style={{ borderRadius: "12px" }}>
                <IconLogout color="#0D6EFD" size={22} />
              </Flex>
              {/* Text Box */}
              <Text c={"#2C80FD"} fz={14} fw={600}> Logout </Text>
            </Flex>
          </Flex>

        </Box>
        {/* Contact Info End */}
      
    </Box>
  );
};

export default Profile;
