import { Box, Flex, Text } from "@mantine/core";
import React from "react";
import Cart from "./Cart";
import Home from "./Home";
import Products from "./Products";
import Profile from "./Profile";
import MyOrder from "./MyOrder";
import ProductDetails from "./ProductDetails";
import ProductDetailsWithModal from "./ProductDetailsWithModal";

const FirstRow = () => {
  return (
    <Box mb={80} pt={50}>
      <Flex px={25} align="center">
        {/* Home Screen Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            Home Screen
          </Text>
          <Box
            style={{ borderRadius: "30px" }}
            bg="#F8F9FA"
            m={10}
            w={375}
            h={760}
          >
            <Home />
          </Box>
        </Box>
        {/* Home Screen End */}

        {/* Product scree Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            Product Screen
          </Text>
          <Box
            bg="#F8F9FA"
            m={10}
            w={375}
            h={760}
            style={{ borderRadius: "30px" }}
          >
            <Products />
          </Box>
        </Box>
        {/* Product scree End */}

        {/* Product Details Screen Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            ProductDetails Screen
          </Text>
          <Box
            bg="#F8F9FA"
            m={10}
            w={375}
            h={760}
            style={{ borderRadius: "30px" }}
          >
            <ProductDetails />
          </Box>
        </Box>
        {/* Product Details Screen End */}

        {/* Product Details with modal screen Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            ProductDetails with modal Screen{" "}
          </Text>
          <Box
            bg="#F8F9FA"
            m={10}
            w={375}
            h={760}
            style={{ borderRadius: "30px" }}
          >
            <ProductDetailsWithModal />
          </Box>
        </Box>
        {/* Product Details with modal screen End */}

        {/* Cart Screen Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            Cart Screen
          </Text>
          <Box
            bg="#F8F9FA"
            m={10}
            w={375}
            h={760}
            style={{ borderRadius: "30px" }}
          >
            <Cart />
          </Box>
        </Box>
        {/* Cart Screen End */}

        {/* My Order Screen Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            My Order Screen
          </Text>
          <Box
            bg="#F8F9FA"
            m={10}
            w={375}
            h={760}
            style={{ borderRadius: "30px" }}
          >
            <MyOrder />
          </Box>
        </Box>
        {/* My Order Screen End */}

        {/* Profile Screeen Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            Profile Screen
          </Text>
          <Box
            bg="#F8F9FA"
            m={10}
            w={375}
            h={760}
            style={{ borderRadius: "30px" }}
          >
            <Profile />
          </Box>
        </Box>
        {/* Profile Screeen End */}
      </Flex>
    </Box>
  );
};

export default FirstRow;
