import { Carousel } from "@mantine/carousel";
import {
    Box,
    Flex,
    Image,
    Text,
    ActionIcon,
    Button
} from "@mantine/core";
import {
    IconChevronLeft,
    IconBrandVisa,
    IconCheck,
    IconCpu2,
    IconPlus,
} from "@tabler/icons-react";
import React from "react";

const Checkout = () => {
    return (
        <Box h='100%' pos='relative'>
            {/* header start */}
            <Box pos='relative' pt={20} px={20}>
                <ActionIcon pos="absolute" left={20} bg="#ffffff" variant="filled" radius="50px" aria-label="Settings">
                    <IconChevronLeft style={{ width: '70%', height: '70%', color: '#cfcfcf' }} stroke={1.5} />
                </ActionIcon>

                <Text ta="center" size={18} lh={1} fw={700}>Checkout</Text>
            </Box>
            {/* header end */}

            {/* main section start */}
            <Box mt={32} px={20}>
                <Flex align="center" gap={10}>
                    <Flex align="center" justify="center" p={10} bg="#ffffff" w={90} h={90} style={{ borderRadius: "15px" }}>
                        <Image
                            w={70}
                            h="auto"
                            src="https://i.postimg.cc/fWvhLRw7/Png-Item-891873.png"
                        />
                    </Flex>

                    <Box>
                        <Text size={18} mb={8} lh={1} fw={500} c="#343a40">Sony WH-1000XM4</Text>
                        <Text size={20} lh={1} c="#4a86f4" fw={700}>$ 4.999</Text>
                    </Box>
                </Flex>

                <Box mt={32}>
                    <Text size={14} lh={1} c="#3f4343">Shipping method</Text>
                    <Flex justify="space-between" align="center" mt={12} bg="#636666" p={3} style={{ borderRadius: "100vmax" }}>
                        <Box w="50%" bg="#fff" p={10} style={{ borderRadius: "100vmax" }}>
                            <Text ta="center" size={12} lh={1} c="#3f4343">Shipping method</Text>
                        </Box>
                        <Box w="50%" p={5} style={{ borderRadius: "100vmax" }}>
                            <Text ta="center" size={12} lh={1} c="#fff">Shipping method</Text>
                        </Box>
                    </Flex>
                </Box>
            </Box>

            <Box mt={20} pl={20}>
                <Text size={14} lh={1} c="#3f4343">Select your payment method</Text>

                <Carousel
                    slideGap={3}
                    dragFree
                    align="start"
                    withControls={false}
                    mt={12}
                >
                    <Carousel.Slide maw={200}>
                        <Box pb={15} pt={10} px={15} bg="#e487c6" style={{ borderRadius: "20px" }} maw={200}>
                            <Flex justify="space-between" align="center">
                                <Text c="#fff" lh={1}>
                                    <IconBrandVisa size={40}></IconBrandVisa>
                                </Text>
                                <Flex align="center" justify="center" c="#e487c6" bg="#fff" h={22} w={22} p={3} style={{ borderRadius: "50%" }}>
                                    <IconCheck></IconCheck>
                                </Flex>
                            </Flex>

                            <Text c="#fff" size={14} lh={1} mt={20}>**** **** **** 1921</Text>

                            <Flex justify="space-between" align="center" mt={20}>
                                <Text c="#fff" lh={1}>
                                    <IconCpu2 size={20}></IconCpu2>
                                </Text>

                                <Text c="#fff" size={14} lh={1}>07/25</Text>
                            </Flex>
                        </Box>
                    </Carousel.Slide>

                    <Carousel.Slide maw={200} style={{ transform: "scale(0.88)" }}>
                        <Box pb={15} pt={10} px={15} bg="#aa8cc6" style={{ borderRadius: "20px" }} maw={200}>
                            <Flex justify="space-between" align="center">
                                <Text c="#fff" lh={1}>
                                    <IconBrandVisa size={40}></IconBrandVisa>
                                </Text>
                                <Flex align="center" justify="center" c="#aa8cc6" bg="#fff" h={22} w={22} p={3} style={{ borderRadius: "50%" }}>
                                    <IconCheck></IconCheck>
                                </Flex>
                            </Flex>

                            <Text c="#fff" size={14} lh={1} mt={20}>**** **** **** 1921</Text>

                            <Flex justify="space-between" align="center" mt={20}>
                                <Text c="#fff" lh={1}>
                                    <IconCpu2 size={20}></IconCpu2>
                                </Text>

                                <Text c="#fff" size={14} lh={1}>07/25</Text>
                            </Flex>
                        </Box>
                    </Carousel.Slide>
                </Carousel>
            </Box>

            <Box mt={32} px={20}>
                <Flex align="center" gap={3} c="#3f4343">
                    <IconPlus size={16}></IconPlus>
                    <Text scize={15} lh={1}>Add new</Text>
                </Flex>

                <Flex align="center" mt={12} gap={10}>
                    <Flex justify="center" align="center" bg="#fff" p={15} style={{ border: "1px solid #d2d2d2", borderRadius: "14px" }} w={80} mah={45}>
                        <Image
                            h="auto"
                            maw={40}
                            src="https://i.postimg.cc/VLG15cS5/google-pay.png"
                        />
                    </Flex>

                    <Flex justify="center" align="center" bg="#fff" p={15} style={{ border: "1px solid #d2d2d2", borderRadius: "14px" }} w={80} mah={45}>
                        <Image
                            h="auto"
                            maw={40}
                            src="https://i.postimg.cc/fTrnDjF9/apple-pay.png"
                        />
                    </Flex>

                    <Flex justify="center" align="center" bg="#fff" p={15} style={{ border: "1px solid #d2d2d2", borderRadius: "14px" }} w={80} mah={45}>
                        <Image
                            h="auto"
                            maw={25}
                            src="https://i.postimg.cc/xdLWGrdv/paypal.png"
                        />
                    </Flex>
                </Flex>
            </Box>
            {/* main section end */}

            {/* footer section start */}
            <Box p={23} mt={32} bg="#fff" style={{ borderTopLeftRadius: "30px", borderTopRightRadius: "30px", boxShadow: '#64646f29 0px -2px 29px 0px' }} pos="absolute" w="100%" bottom={0}>
                <Flex align="center" pb={10} justify="space-between">
                    <Text size={14} lh={1}>Subtotal (2 items)</Text>
                    <Text size={14} lh={1} style={{ flexBasis: "17%" }}>$ 4.999</Text>
                </Flex>

                <Flex align="center" pb={10} justify="space-between" style={{ borderBottom: "1px dotted #cbcaca" }}>
                    <Text size={14} lh={1}>Shipping cost</Text>
                    <Text size={14} lh={1} style={{ flexBasis: "17%" }}>Free</Text>
                </Flex>

                <Flex align="center" pt={10} justify="space-between">
                    <Text>Total</Text>
                    <Text style={{ flexBasis: "17%" }}>$ 4.999</Text>
                </Flex>

                <Button radius="50px" mt={12} w="100%" bg="#4285f4" c="#ffffff">Buy Now</Button>
            </Box>
            {/* footer section end */}
        </Box>
    );
};

export default Checkout;