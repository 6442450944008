import { Carousel } from "@mantine/carousel";
import {
  Box,
  Flex,
  Image,
  Text,
  ActionIcon,
  Group,
  Input,
  Grid
} from "@mantine/core";
import {
  IconHeart,
  IconMenu2,
  IconSearch,
  IconBellRinging,
  IconHome,
  IconMessage,
  IconShoppingCart,
  IconUserCircle,
} from "@tabler/icons-react";
import React from "react";

const Home = () => {
  return (
    <Box h='100%' p={20} pos='relative'>
      {/* header start */}
      <Flex align="center" justify="space-between">
        <IconMenu2></IconMenu2>
        <Flex style={{ borderRadius: '18px' }} align="center" justify="space-between" radius="50px" bg="#ffffff">
          <Box pr='5' pl='10' pos="relative" top={3}>
            <IconSearch style={{ color: '#cfcfcf', width: '18px' }}></IconSearch>
          </Box>
          <Input variant="unstyled" c="#cfcfcf" style={{ width: '230px' }} h="100%" size={16} lh={1} placeholder="Search Products" />
        </Flex>
        <ActionIcon bg="#ffffff" variant="filled" radius="50%" aria-label="Settings">
          <IconBellRinging style={{ width: '70%', height: '70%', color: '#cfcfcf' }} stroke={1.5} />
        </ActionIcon>
      </Flex>
      {/* header end */}

      {/* banner section start */}
      <Box pos={'relative'}>
        <Flex p={15} align="center" justify="space-between" h={150} mt='30px' style={{ borderRadius: '10px', backgroundImage: 'linear-gradient(70deg, #3c7de7 0%, #0a3c8f 100%)' }}>
          <Box>
            <Text fz="25px" c='#f4bd46' lh='1' fw={700} tt="uppercase">cyber</Text>
            <Text fz="25px" c='#f4bd46' lh='1' fw={700} tt="uppercase">linio</Text>
            <Flex align="flex-end" gap='3'>
              <Text fz="20px" c='#fff' lh='1' fw={700} tt="uppercase">40%</Text>
              <Text fz="11px" c='#fff' lh='1' fw={500} tt="uppercase">dscnt</Text>
            </Flex>
            <Text fz="14px" c='#fff' lh='1' fw={500}>in technolgoy</Text>
            <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="uppercase" mx={0} mb={0} mt={10}>free shipping</Text>
          </Box>

          <Box pos={'absolute'} left={'25%'} top={'-20px'} style={{ transform: "rotate(-55deg)" }}>
            <Image
              h={100}
              src="remote.png"
            />
          </Box>

          <Box pos={'absolute'} left={'45%'} top={'-20px'} style={{ transform: "rotate(-30deg)" }}>
            <Image
            h={130}
              src="vr.png"
            />
          </Box>

          <Box pos={'absolute'} right={-10} bottom={'-15px'}>
            <Image
            w={100}
              src="air-pods.png"
            />
          </Box>
        </Flex>

        <Text fz="9px" fw={700} c='#b9b9b9' ta="center" lh='1' mr={38} mt={5}>*Valid from 27/03 to 01/04/2022. Min stock: 1 unit</Text>
      </Box>
      {/* banner section end */}

      {/* category section start */}
      <Box mt={32}>
        <Carousel
          // slideSize={80}
          // height={250}
          style={{ display: 'flex' }}
          dragFree
          align="start"
          withControls={false}
        >
          <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
            <Box>
              <Text style={{ border: '1px solid #d6e6ff', borderRadius: '100vmax' }} bg="#eaf2ff" c='#4285f4' size={14} lh={1} ta="center" py={5} px={10} tt="capitalize" w="fit-content">Technology</Text>
            </Box>
          </Carousel.Slide>
          <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
            <Box>
              <Text style={{ border: '1px solid #e9e9e9', borderRadius: '100vmax' }} bg="#fff" size={14} lh={1} ta="center" py={5} px={10} tt="capitalize" w="fit-content">Fashion</Text>
            </Box>
          </Carousel.Slide>
          <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
            <Box>
              <Text style={{ border: '1px solid #e9e9e9', borderRadius: '100vmax' }} bg="#fff" size={14} lh={1} ta="center" py={5} px={10} tt="capitalize" w="fit-content">Sports</Text>
            </Box>
          </Carousel.Slide>
          <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
            <Box>
              <Text style={{ border: '1px solid #e9e9e9', borderRadius: '100vmax' }} bg="#fff" size={14} lh={1} ta="center" py={5} px={10} tt="capitalize" w="fit-content">Home</Text>
            </Box>
          </Carousel.Slide>
        </Carousel>
      </Box>
      {/* category section end */}

      {/* Hot sale section start */}
      <Flex mt={32} align="center" justify="space-between">
        <Text fz="18px" c='#353b41' lh='1' fw={700}>Hot Sales</Text>
        <Group gap='6'>
          <Box w={15} h={5} style={{ borderRadius: '100vmax' }} bg='#4285f4'></Box>
          <Box w={5} h={5} style={{ borderRadius: '50%' }} bg='#d1d1d1'></Box>
          <Box w={5} h={5} style={{ borderRadius: '50%' }} bg='#d1d1d1'></Box>
          <Box w={5} h={5} style={{ borderRadius: '50%' }} bg='#d1d1d1'></Box>
        </Group>
      </Flex>

      {/* Product Carousel start */}
      <Box mt={12}>
        <Carousel
          slideSize={150}
          // height={250}
          slideGap={10}
          dragFree
          align="start"
          withControls={false}
        >
          <Carousel.Slide>
            <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={10} bg='#eef1f5'>
              <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text>
              <Image
                h='auto'
                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
              />
              <Text c='#353b41' mt={12} tt="capitalize" size={12} lh="18px">macbook air m1</Text>
              <Text c='#353b41' fw={700} size={14} lh={1}>$ 29,999</Text>
            </Box>
          </Carousel.Slide>
          <Carousel.Slide>
            <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={10} bg='#eef1f5'>
              <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text>
              <Image
                h='auto'
                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
              />
              <Text c='#353b41' mt={12} tt="capitalize" size={12} lh="18px">macbook air m1</Text>
              <Text c='#353b41' fw={700} size={14} lh={1}>$ 29,999</Text>
            </Box>
          </Carousel.Slide>
          <Carousel.Slide>
            <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={10} bg='#eef1f5'>
              <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text>
              <Image
                h='auto'
                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
              />
              <Text c='#353b41' mt={12} tt="capitalize" size={12} lh="18px">macbook air m1</Text>
              <Text c='#353b41' fw={700} size={14} lh={1}>$ 29,999</Text>
            </Box>
          </Carousel.Slide>
        </Carousel>
      </Box>
      {/* Product Carousel end */}
      {/* Hot sale section end */}

      {/* Recently viewed start */}
      <Box mt={32}>
        <Text fz="18px" c='#353b41' lh='1' fw={700}>Recently Viewed</Text>
        <Grid mt={12}>
          <Grid.Col span={6}>
            <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={15} bg='#eef1f5'>
              {/* <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text> */}

              <Image
                h='120'
                style={{ objectFit: 'contain' }}
                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
              />

              <Text c='#353b41' ta='center' tt="capitalize" size={12} lh="18px">Huawei macbook x13</Text>
              <Text c='#353b41' fw={700} ta='center' mt={5} size={14} lh={1}>$ 20,999</Text>

              <Box style={{ position: "absolute", right: '10px', top: '10px' }}>
                <ActionIcon bg="#ffffff" variant="filled" radius="50%" aria-label="Settings">
                  <IconHeart style={{ width: '60%', height: '60%', color: '#cfcfcf' }} stroke={1.5} />
                </ActionIcon>
              </Box>
            </Box>
          </Grid.Col>
          <Grid.Col span={6}>
            <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={15} bg='#eef1f5'>
              {/* <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text> */}

              <Image
                h='120'
                style={{ objectFit: 'contain' }}
                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
              />

              <Text c='#353b41' ta='center' tt="capitalize" size={12} lh="18px">Huawei macbook x13</Text>
              <Text c='#353b41' fw={700} ta='center' mt={5} size={14} lh={1}>$ 20,999</Text>

              <Box style={{ position: "absolute", right: '10px', top: '10px' }}>
                <ActionIcon bg="#ffffff" variant="filled" radius="50%" aria-label="Settings">
                  <IconHeart style={{ width: '60%', height: '60%', color: '#cfcfcf' }} stroke={1.5} />
                </ActionIcon>
              </Box>
            </Box>
          </Grid.Col>
          <Grid.Col span={6}>
            <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={15} bg='#eef1f5'>
              {/* <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text> */}

              <Image
                h='120'
                style={{ objectFit: 'contain' }}
                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
              />

              <Text c='#353b41' ta='center' tt="capitalize" size={12} lh="18px">Huawei macbook x13</Text>
              <Text c='#353b41' fw={700} ta='center' mt={5} size={14} lh={1}>$ 20,999</Text>

              <Box style={{ position: "absolute", right: '10px', top: '10px' }}>
                <ActionIcon bg="#ffffff" variant="filled" radius="50%" aria-label="Settings">
                  <IconHeart style={{ width: '60%', height: '60%', color: '#cfcfcf' }} stroke={1.5} />
                </ActionIcon>
              </Box>
            </Box>
          </Grid.Col>
          <Grid.Col span={6}>
            <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={15} bg='#eef1f5'>
              {/* <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text> */}

              <Image
                h='120'
                style={{ objectFit: 'contain' }}
                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
              />

              <Text c='#353b41' ta='center' tt="capitalize" size={12} lh="18px">Huawei macbook x13</Text>
              <Text c='#353b41' fw={700} ta='center' mt={5} size={14} lh={1}>$ 20,999</Text>

              <Box style={{ position: "absolute", right: '10px', top: '10px' }}>
                <ActionIcon bg="#ffffff" variant="filled" radius="50%" aria-label="Settings">
                  <IconHeart style={{ width: '60%', height: '60%', color: '#cfcfcf' }} stroke={1.5} />
                </ActionIcon>
              </Box>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
      {/* Recently viewed end */}

      {/* bottom nav start */}
      <Flex align="center" w='335px' ml="auto" mr="auto" bg='#fff' py={7} px={7} justify="space-between" pos="absolute" bottom={25} left={0} right={0} style={{ borderRadius: '100vmax', boxShadow: '#64646f29 0px -2px 29px 0px' }}>
        <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '100vmax' }} bg='#eaf2ff' c='#4385f4'>
          <IconHome style={{ maxWidth: "20px" }}></IconHome>
        </Flex>
        <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '50%' }} c='#cacaca'>
          <IconMessage style={{ maxWidth: "20px" }}></IconMessage>
        </Flex>
        <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '50%' }} c='#cacaca'>
          <IconSearch style={{ maxWidth: "20px" }}></IconSearch>
        </Flex>
        <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '50%' }} c='#cacaca'>
          <IconShoppingCart style={{ maxWidth: "20px" }}></IconShoppingCart>
        </Flex>
        <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '50%' }} c='#cacaca'>
          <IconUserCircle style={{ maxWidth: "20px" }}></IconUserCircle>
        </Flex>
      </Flex>
      {/* bottom nav end */}
    </Box>
  );
};

export default Home;
