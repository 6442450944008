import { Box, Flex, Image, Input, Text } from "@mantine/core";
import { IconSearch, IconPlus, IconStarFilled, IconHeart, IconMenu2, IconHome, IconCompass, IconShoppingCart, IconUserCircle } from "@tabler/icons-react";
import React from "react";

const CategorySingle = () => {
  return (
    <Box p={8} pos={'relative'}>

       {/* Template Title Start */}
        <Text c={"#ffffff"} pos={'absolute'} top={-65} fw={600} fz={20} left={'50%'} w={"100%"} ta={'center'} style={{ transform: "translateX(-50%)" }}>Category Single Template</Text>
       {/* Template Title End */}

      {/* Top Navigation Start */}
      <Flex justify={'space-between'}>
        <IconMenu2 style={{color: '#fff', cursor: 'pointer'}}/>
        <Image radius="12px" src="https://images.pexels.com/photos/3778680/pexels-photo-3778680.jpeg" h={32} w={32} />
      </Flex>
      {/* Top Navigation End */}

      {/* Titel Start */}
      <Text fz={20} fw={600} c={"#ffffff"} mt={10}> Discover Your Best Gadget </Text>
      {/* Titel End */}

      {/* Search Bar Start */}
      <Flex justify="flex-start" align="center" mt={10} h={50} pl={10} style={{ borderRadius: "30px", border: "1px solid #7f8c8d" }}>
        <IconSearch color="#fff" size={20} />
        <Input variant="unstyled" placeholder="Search" ml={15} size="16" />
      </Flex>
      {/* Search Bar End */}

      {/* Category Filter Button Start */}
      <Flex mt={20} wrap={'wrap'} justify={'space-between'}>
        <Box px={18} py={12} bg={'#00B9D3'} c={'#ffffff'} fz={'14px'} fw={400} style={{ borderRadius: "25px", cursor: 'pointer' }}>All Product</Box>
        <Box px={18} py={12} bg={'#262626'} c={'#ffffff'} fz={'14px'} fw={400} style={{ borderRadius: "25px", cursor: 'pointer' }}>Electronics</Box>
        <Box px={18} py={12} bg={'#262626'} c={'#ffffff'} fz={'14px'} fw={400} style={{ borderRadius: "25px", cursor: 'pointer' }}>Cloting</Box>
      </Flex>
      {/* Category Filter Button End */}

      {/* Product Grid Start */}
      <Flex mt="20px" mb={40} justify={'space-between'} align={'flex-start'} wrap={'wrap'} style={{ maxHeight: "435px", overflowY: "hidden" }}>
         
        {/* Single Product */}
        <Box bg={'#161414'} p={15} mb={10} style={{ flexBasis: "49%", border: "2px solid #2F3134", borderRadius: "10px", boxShadow: "4px 4px 12px 0px #0000004d" }}>
            <Flex justify={'space-between'} align={'flex-start'}>
                <Box>
                    <Text c={'#ECEBEB'} fz={16} fw={500}>Watches</Text>
                    <Text c={'#ECEBEB'} fz={12} fw={500} style={{ display: "inline-block" }}>4.9</Text>
                    <Box c={'#E7B53D'} ml={5} style={{ display: "inline-block" }}>
                        <IconStarFilled size={10} />
                    </Box>
                </Box>
                <IconHeart color="#ffffff" style={{ width: "20px" }} />
            </Flex>
            <Image h={120} radius="10px" mt={5} src="https://images.pexels.com/photos/13009437/pexels-photo-13009437.jpeg" style={{ border: "1px solid #ffffff" }}/>
            <Flex mt={15} justify={'space-between'} align={'center'}>
                <Box>
                    <Text c={'#585858'} fz={12} fw={500} style={{ textDecoration: "line-through" }}>$45.00</Text>
                    <Text c={'#ECEBEB'} fz={14} fw={500}>32.00</Text>
                </Box>
                <Flex h={50} w={50} justify={"center"} align={"center"} style={{ borderRadius: "13px", backgroundImage: "linear-gradient(126deg, #4C6C93 10%, #6BAEAE 110%)", float: "right", cursor: "pointer" }}>
                    <IconPlus color="#ffffff"/>
                </Flex>
            </Flex>
        </Box>

        {/* Single Product */}
        <Box bg={'#161414'} p={15} mb={10} style={{ flexBasis: "49%", border: "2px solid #2F3134", borderRadius: "10px", boxShadow: "4px 4px 12px 0px #0000004d" }}>
            <Flex justify={'space-between'} align={'flex-start'}>
                <Box>
                    <Text c={'#ECEBEB'} fz={16} fw={500}>Shirts</Text>
                    <Text c={'#ECEBEB'} fz={12} fw={500} style={{ display: "inline-block" }}>4.9</Text>
                    <Box c={'#E7B53D'} ml={5} style={{ display: "inline-block" }}>
                        <IconStarFilled size={10} />
                    </Box>
                </Box>
                <IconHeart color="#ffffff" style={{ width: "20px" }} />
            </Flex>
            <Image h={120} radius="10px" mt={5} src="https://images.pexels.com/photos/3029796/pexels-photo-3029796.jpeg" style={{ border: "1px solid #ffffff" }}/>
            <Flex mt={15} justify={'space-between'} align={'center'}>
                <Box>
                    <Text c={'#585858'} fz={12} fw={500} style={{ textDecoration: "line-through" }}>$45.00</Text>
                    <Text c={'#ECEBEB'} fz={14} fw={500}>32.00</Text>
                </Box>
                <Flex h={50} w={50} justify={"center"} align={"center"} style={{ borderRadius: "13px", backgroundImage: "linear-gradient(126deg, #4C6C93 10%, #6BAEAE 110%)", float: "right", cursor: "pointer" }}>
                    <IconPlus color="#ffffff"/>
                </Flex>
            </Flex>
        </Box>

        {/* Single Product */}
        <Box bg={'#161414'} p={15} mb={10} style={{ flexBasis: "49%", border: "2px solid #2F3134", borderRadius: "10px", boxShadow: "4px 4px 12px 0px #0000004d" }}>
            <Flex justify={'space-between'} align={'flex-start'}>
                <Box>
                    <Text c={'#ECEBEB'} fz={16} fw={500}>Mobile</Text>
                    <Text c={'#ECEBEB'} fz={12} fw={500} style={{ display: "inline-block" }}>4.9</Text>
                    <Box c={'#E7B53D'} ml={5} style={{ display: "inline-block" }}>
                        <IconStarFilled size={10} />
                    </Box>
                </Box>
                <IconHeart color="#ffffff" style={{ width: "20px" }} />
            </Flex>
            <Image h={120} radius="10px" mt={5} src="https://images.pexels.com/photos/699122/pexels-photo-699122.jpeg" style={{ border: "1px solid #ffffff" }}/>
            <Flex mt={15} justify={'space-between'} align={'center'}>
                <Box>
                    <Text c={'#585858'} fz={12} fw={500} style={{ textDecoration: "line-through" }}>$45.00</Text>
                    <Text c={'#ECEBEB'} fz={14} fw={500}>32.00</Text>
                </Box>
                <Flex h={50} w={50} justify={"center"} align={"center"} style={{ borderRadius: "13px", backgroundImage: "linear-gradient(126deg, #4C6C93 10%, #6BAEAE 110%)", float: "right", cursor: "pointer" }}>
                    <IconPlus color="#ffffff"/>
                </Flex>
            </Flex>
        </Box>

        {/* Single Product */}
        <Box bg={'#161414'} p={15} mb={10} style={{ flexBasis: "49%", border: "2px solid #2F3134", borderRadius: "10px", boxShadow: "4px 4px 12px 0px #0000004d" }}>
            <Flex justify={'space-between'} align={'flex-start'}>
                <Box>
                    <Text c={'#ECEBEB'} fz={16} fw={500}>Headphone</Text>
                    <Text c={'#ECEBEB'} fz={12} fw={500} style={{ display: "inline-block" }}>4.9</Text>
                    <Box c={'#E7B53D'} ml={5} style={{ display: "inline-block" }}>
                        <IconStarFilled size={10} />
                    </Box>
                </Box>
                <IconHeart color="#ffffff" style={{ width: "20px" }} />
            </Flex>
            <Image h={120} radius="10px" mt={5} src="https://images.pexels.com/photos/375751/pexels-photo-375751.jpeg" style={{ border: "1px solid #ffffff" }}/>
            <Flex mt={15} justify={'space-between'} align={'center'}>
                <Box>
                    <Text c={'#585858'} fz={12} fw={500} style={{ textDecoration: "line-through" }}>$45.00</Text>
                    <Text c={'#ECEBEB'} fz={14} fw={500}>32.00</Text>
                </Box>
                <Flex h={50} w={50} justify={"center"} align={"center"} style={{ borderRadius: "13px", backgroundImage: "linear-gradient(126deg, #4C6C93 10%, #6BAEAE 110%)", float: "right", cursor: "pointer" }}>
                    <IconPlus color="#ffffff"/>
                </Flex>
            </Flex>
        </Box>

      </Flex>
      {/* Product Grid End */}
      
      {/* Bottom Nav Start */}
      <Flex justify={'space-between'} align={'center'} pos={'absolute'} bg={'#1A1A1A'} w={'375px'} left={-25} bottom={-25} h={65} py={25} px={30} style={{ borderBottomLeftRadius: '30px', borderBottomRightRadius: '30px', }}>
        <IconHome color="#00B9D3"/>
        <IconCompass color="#A5A5A5"/>
        <IconShoppingCart color="#A5A5A5" />
        <IconUserCircle color="#A5A5A5" />
      </Flex>
      {/* Bottom Nav End */}

    </Box>
  );
};

export default CategorySingle;
