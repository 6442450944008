import {
  Box,
  Divider,
  Flex,
  Image,
  ScrollArea,
  Text,
} from "@mantine/core";
import { IconDotsVertical, IconMenu2 } from "@tabler/icons-react";
import React from "react";
const Cart = () => {
  return (
    <Box p={24} pos="relative">
      {/* Nav Start */}
      <Flex align="center" justify="space-between">
        <IconMenu2 size={35} />
        <Text fw={700} fz={20}>
          Cart
        </Text>
          <IconDotsVertical />
      </Flex>
      {/* Nav End */}

      {/* Product List Start */}
      <ScrollArea style={{ width: "100%" }} h={560} mt={20}>
        <Box pt={10}>
          <Flex align="center" gap={5}>
            <Box w={60} h={80}>
              <Image
                style={{ width: "100%", height: "100%" }}
                src="https://i.pinimg.com/736x/da/5d/f8/da5df88c6da22104193f1e02baafdbf2.jpg"
                alt=""
                radius="10px"
              />
            </Box>
            <Box h={88} p={8}>
              <Text fz={16} fw={700}>
                Default text Tshirt
              </Text>
              <Box>
                <Text fz={13} c="#868E96" mt={3}>
                  T-Shirt
                </Text>
              </Box>
              <Text c="#6a89cc" fw={700} mt={3}>
                $ 24
              </Text>
            </Box>
          </Flex>
          <Flex align="center" justify="space-between" mt={8}>
            <Flex lign="center" justify="center">
              <Text fz={14} c="#868E96">
                Size:
              </Text>
              <Text fw={600} fz={14} c="#343A40" pl={1}>
                XL
              </Text>
            </Flex>
            <Flex align="center" gap={4}>
              <Text fz={14} c="#868E96">
                Size:
              </Text>
              <Box
                w={15}
                h={15}
                bg="#E67700"
                style={{ borderRadius: "100%" }}
              ></Box>
            </Flex>
            <Flex lign="center" justify="center">
              <Text fz={14} c="#868E96">
                Qty:
              </Text>
              <Text fw={600} c="#343A40" pl={1}>
                2{" "}
              </Text>
            </Flex>
          </Flex>
          <Divider my={8} />
        </Box>
        <Box pt={10}>
          <Flex align="center" gap={5}>
            <Box w={60} h={80}>
              <Image
                style={{ width: "100%", height: "100%" }}
                src="https://i.pinimg.com/736x/da/5d/f8/da5df88c6da22104193f1e02baafdbf2.jpg"
                alt=""
                radius="10"
              />
            </Box>
            <Box h={88} p={8}>
              <Text fz={16} fw={700}>
                Default text Tshirt
              </Text>
              <Box>
                <Text fz={13} c="#868E96" mt={3}>
                  T-Shirt
                </Text>
              </Box>
              <Text c="#6a89cc" fw={700} mt={3}>
                $ 24
              </Text>
            </Box>
          </Flex>
          <Flex align="center" justify="space-between" mt={8}>
            <Flex lign="center" justify="center">
              <Text fz={14} c="#868E96">
                Size:
              </Text>
              <Text fw={600} fz={14} c="#343A40" pl={1}>
                XL
              </Text>
            </Flex>
            <Flex align="center" gap={4}>
              <Text fz={14} c="#868E96">
                Size:
              </Text>
              <Box
                w={15}
                h={15}
                bg="#E67700"
                style={{ borderRadius: "100%" }}
              ></Box>
            </Flex>
            <Flex lign="center" justify="center">
              <Text fz={14} c="#868E96">
                Qty:
              </Text>
              <Text fw={600} c="#343A40" pl={1}>
                2{" "}
              </Text>
            </Flex>
          </Flex>
          <Divider my={8} />
        </Box>
      </ScrollArea>
      {/* Product List End */}

      {/* Cart Bottom Start */}
      <Flex justify="space-between" direction="column" ml={2}>
        <Flex align="center" justify="space-between" style={{ width: "100%" }}>
          <Text size={14} lh={1} fw={500}>Total Amount</Text>
          <Text size={18} lh={1} fw={700}>$ 105</Text>
        </Flex>
        <Box bg="#F24545" style={{ borderRadius: "20px" }} mt={20}>
          <Text ta="center" py="15px" c="#ffffff" fw={700}>
            Checkout
          </Text>
        </Box>
      </Flex>
       {/* Cart Bottom End */}
    </Box>
  );
};

export default Cart;
