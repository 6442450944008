import { Carousel } from "@mantine/carousel";
import {
    Box,
    Flex,
    Image,
    Text,
    Button,
    Group,
} from "@mantine/core";
import {
    IconMinus,
    IconPlus,
    IconHeart,
    IconChevronLeft,
} from "@tabler/icons-react";
import React from "react";

const Home = () => {
    return (
        <Box h='100%' pos='relative'>
            <Box pos="relative">
                {/* header start */}
                <Flex w="100%" justify="space-between" align="center" pos="absolute" top={0} pt={20} px={20} style={{ zIndex: "1" }}>
                    <Flex justify="center" align="center" w={30} h={30} bg="#686969" c="#fff" style={{ borderRadius: "8px" }}>
                        <IconChevronLeft></IconChevronLeft>
                    </Flex>

                    <Flex justify="center" align="center" w={30} h={30} bg="#686969" c="#fff" style={{ borderRadius: "8px" }}>
                        <IconHeart></IconHeart>
                    </Flex>
                </Flex>
                {/* header end */}

                {/* image section start */}
                <Box pos="relative">
                    <Image
                        w="100%"
                        src="https://i.postimg.cc/zX0Q49tF/nordwood-themes-sg8n-Xmp-WDM-unsplash.jpg"
                    />

                    <Box h="100%" top={140} pos="absolute" w="100%" style={{ background: "linear-gradient(#ffffff00 0%, #000000 60%)" }}></Box>
                </Box>
                {/* image section end */}
            </Box>

            {/* middle section start */}
            <Box bg="#fff" pos="relative" top={-20} style={{ borderTopLeftRadius: "30px", borderTopRightRadius: "30px" }}>

                <Flex justify="center">
                    <Group gap='6' pos="absolute" top={-30} style={{ zIndex: "1" }}>
                        <Box w={10} h={10} style={{ borderRadius: '50%' }} bg='#ffffff'></Box>
                        <Box w={10} h={10} style={{ borderRadius: '50%' }} bg='#cfcfcf'></Box>
                        <Box w={10} h={10} style={{ borderRadius: '50%' }} bg='#cfcfcf'></Box>
                    </Group>
                </Flex>

                <Box px={23} pt={25}>
                    <Text bg="#d7efe3" c="#00743d" w="fit-content" size={12} lh={1} py={5} px={8} style={{ borderRadius: "5px" }}>Sale 20% OFF</Text>

                    <Text size={22} lh={1} fw={600} c={'#333333'} mt="15">Dell Laptop</Text>

                    <Flex align="center" gap={15} mt="20">
                        <Text c="#00743d" fw={700} size={16} lh={1}>$ 2,100</Text>
                        <Text c="#c4c4c4" fw={700} size={16} lh={1}>$ 2,100</Text>
                    </Flex>
                </Box>

                <Box mt="20" style={{ borderBottom: "1px solid #c4c4c4" }}>
                    <Flex justify="space-around" align="center">
                        <Box>
                            <Text fz={14} fw={400} pb="10" c="#27733E">Description</Text>
                            <Box h={4} bg="#00743d" style={{ borderTopLeftRadius: "100vmax", borderTopRightRadius: "100vmax" }}></Box>
                        </Box>
                        <Text fz={14} fw={400} pb="10" c="#333333">Reviews</Text>
                    </Flex>
                </Box>

                <Box>
                    <Box px={23} pt={23}>
                    <Text lh="17px" size={12} c="#b5b5b5" style={{ display: "inline-block" }}>
                        The Dell Core i3 has a 4 GB of installed RAM and makes this laptop very responsive. It has a built in 500 GB of the Hard drive having a speed of 5400 RPM and ... <Text lh={1} c="#00743d" style={{ display: "inline-block" }}>Read more</Text>
                    </Text>
                    </Box>

                    <Box mt="20" pl={23}>
                        <Text fz={14} fw={600} lh={1} c="#333333" mb={12}>Related Product</Text>

                        <Carousel
                            slideGap={5}
                            mt="12"
                            // dragFree
                            align="start"
                            withControls={false}
                            // loop={true}
                            styles={{
                                viewport: {
                                    overflow: "visible",
                                    overflowX: "clip",
                                },
                            }}
                        >
                            <Carousel.Slide mr={15} bg="#fff" style={{ maxWidth: 'fit-content', boxShadow: "#0000001a 0px 4px 12px", borderRadius: "10px" }}>
                                <Flex align="center" gap={10}>
                                    <Image
                                        style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}
                                        miw={90}
                                        mih={60}
                                        maw={90}
                                        mah={60}
                                        src="https://i.postimg.cc/6678S5G0/pexels-italo-melo-2379005.jpg"
                                        fit="cover"
                                    />

                                    <Box miw={130}>
                                        <Text c='#323139' size={15} lh={1} fw={700}>Dell Inspiron</Text>
                                        <Text c="#00743d" mt="8" size={14} lh={1} fw={700}>$1,900</Text>
                                    </Box>
                                </Flex>
                            </Carousel.Slide>

                            <Carousel.Slide mr={15} bg="#fff" style={{ maxWidth: 'fit-content', boxShadow: "#0000001a 0px 4px 12px", borderRadius: "10px" }}>
                                <Flex align="center" gap={10}>
                                    <Image
                                        style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}
                                        miw={90}
                                        mih={60}
                                        maw={90}
                                        mah={60}
                                        src="https://i.postimg.cc/6678S5G0/pexels-italo-melo-2379005.jpg"
                                        fit="cover"
                                    />

                                    <Box miw={130}>
                                        <Text c='#323139' size={15} lh={1} fw={700}>Dell Inspiron</Text>
                                        <Text c="#00743d" mt="8" size={14} lh={1} fw={700}>$1,900</Text>
                                    </Box>
                                </Flex>
                            </Carousel.Slide>
                        </Carousel>
                    </Box>
                </Box>
            </Box>
            {/* middle section end */}

            {/* footer section strat */}
            <Box w="100%" p={23} bg="#fff" pos="absolute" bottom={0} left={0} style={{ borderTopLeftRadius: "30px", borderTopRightRadius: "30px", boxShadow: '#64646f29 0px -2px 29px 0px' }}>
                <Flex align="center" justify="space-between">
                    <Flex gap={10} c="#00743d" align="center" h={30}>
                        <Flex justify="center" align="center" w={26} h={26} size={14} lh={1} px={5} py={0} style={{ border: "1px solid #00743d", borderRadius: "6px", }}>
                            <IconMinus></IconMinus>
                        </Flex>
                        <Text size={16} fw={700} bg="#dbeae2" py={15} px={20} lh={1} style={{ borderRadius: "12px" }}>1</Text>
                        <Flex justify="center" align="center" w={26} h={26} size={14} lh={1} px={5} py={0} style={{ border: "1px solid #00743d", borderRadius: "6px", }}>
                            <IconPlus></IconPlus>
                        </Flex>
                    </Flex>
                    <Button radius="10px" px={30} h={40} fw={700} bg="#00743d" c="#ffffff">Add to Cart</Button>
                </Flex>
            </Box>
            {/* footer section end */}
        </Box>

    );
};

export default Home;