import { Carousel } from "@mantine/carousel";
import {
  ActionIcon,
  BackgroundImage,
  Box,
  Flex,
  Grid,
  Image,
  Text,
} from "@mantine/core";
import {
  IconBrandRedhat,
  IconDeviceWatchStats,
  IconHeart,
  IconHeartFilled,
  IconHome2,
  IconLayoutGrid,
  IconMessageCircle,
  IconSearch,
  IconShirt,
  IconShoppingCart,
  IconStarFilled,
  IconUser,
} from "@tabler/icons-react";
import React from "react";
const Products = () => {
  return (
    <Box p={24} pos="relative" h={760} style={{ overflow: "hidden" }}>
      {/* Navbar start*/}
      <Flex align="center" justify="space-between">
        <Box>
          <Text fz={14} fw={400} c={'#1B2028'}>Hello, Welcome 👋</Text>
          <Text fz={16} fw={700} c={'#434242'}>
            Albert Stevano
          </Text>
        </Box>
        <Flex align="center" justify="center" gap={10}>
          <ActionIcon variant="filled" color="#211F1F" size="40px" radius="12px">
            <IconSearch
              style={{ width: "20px", height: "20px" }}
              stroke={1.5}
            />
          </ActionIcon>
          <Box w={45} h={45}>
            <Image
              src="https://images.pexels.com/photos/697509/pexels-photo-697509.jpeg?auto=compress&cs=tinysrgb&w=600"
              style={{ width: "100%", height: "100%", borderRadius: "100%" }}
            />
          </Box>
        </Flex>
      </Flex>
      {/* Navbar end */}
      {/* items carousel start */}
      <Box sx={{ maxWidth: 300 }} my={25}>
        <Carousel
          slideSize="25%"
          slideGap={15}
          align="start"
          slidesToScroll={1}
          withControls={false}
          loop
        >
          <Carousel.Slide>
            <Flex
              w={120}
              h={40}
              style={{ border: "1px solid #E9ECEF", borderRadius: "10px" }}
              align="center"
              justify="center"
              gap={8}
              bg="#292526"
            >
              <IconLayoutGrid color="#fff" size={18} />
              <Text fz={12} fw={500} c={'#fff'}>
                All Items
              </Text>
            </Flex>
          </Carousel.Slide>
          <Carousel.Slide>
            <Flex
              w={120}
              h={40}
              style={{ border: "1px solid #E9ECEF", borderRadius: "10px" }}
              align="center"
              justify="center"
              gap={8}
            >
              <IconShirt size={18} color="#1B2028" />
              <Text fz={12} fw={500} c="#1B2028">
                Dress
              </Text>
            </Flex>
          </Carousel.Slide>
          <Carousel.Slide>
            <Flex
              w={120}
              h={40}
              style={{ border: "1px solid #E9ECEF", borderRadius: "10px" }}
              align="center"
              justify="center"
              gap={8}
            >
              <IconBrandRedhat size={18} />
              <Text fz={12} fw={500} c={'#1B2028'}>
                Hat
              </Text>
            </Flex>
          </Carousel.Slide>
          <Carousel.Slide>
            <Flex
              w={120}
              h={40}
              style={{ border: "1px solid #E9ECEF", borderRadius: "10px" }}
              align="center"
              justify="center"
              gap={8}
            >
              <IconDeviceWatchStats size={18} />
              <Text fz={12} fw={500} c={'#1B2028'}>
                Watch
              </Text>
            </Flex>
          </Carousel.Slide>
        </Carousel>
      </Box>
      {/* Items carousel end */}
      {/* Products section start */}
      <Grid align="center" justify="center" gutter={28}>
        <Grid.Col span={6}>
          <Box radius="15px" w={150} h={215} my={5}>
            <BackgroundImage
              src="https://images.pexels.com/photos/935760/pexels-photo-935760.jpeg?auto=compress&cs=tinysrgb&w=300"
              radius="15px"
              h={165}
            >
              <Box>
                <Flex align="center" justify="space-between" p={10}>
                  <Text
                    bg="#F13658"
                    px={8}
                    py={6}
                    fz={10}
                    fw={500}
                    c="#ffffff"
                    style={{ borderRadius: "10px" }}
                  >
                    Trending
                  </Text>
                  <ActionIcon
                    variant="filled"
                    color="#ffffff"
                    size="20px"
                    h={25}
                    w={25}
                    radius={"50%"}
                  >
                    <IconHeart size={15} color="#000000" />
                  </ActionIcon>
                </Flex>
              </Box>
            </BackgroundImage>
            <Box>
              <Text fz={12} fw={700} mb={2} mt={10}>
                Modern light Clothes
              </Text>
              <Text fz={10} c="#ADB5BD">
                Dress modern
              </Text>
            </Box>
            <Flex align="center" justify="space-between">
              <Box>
                <Text fz={14} fw={600}>
                  $212
                </Text>
              </Box>
              <Flex align="center" justify="center" gap={5}>
                <Box c={'#FCC419'}>
                  <IconStarFilled size={14} />
                </Box>
                <Text fz={12} c={'#1B2028'}>5.0</Text>
              </Flex>
            </Flex>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box radius="15px" w={150} h={215} my={5}>
            <BackgroundImage
              src="https://images.pexels.com/photos/935760/pexels-photo-935760.jpeg?auto=compress&cs=tinysrgb&w=300"
              radius="15px"
              h={165}
            >
              <Flex align="center" justify="space-between" p={10}>
                  <Text
                    bg="#F13658"
                    px={8}
                    py={6}
                    fz={10}
                    fw={500}
                    c="#ffffff"
                    style={{ borderRadius: "10px" }}
                  >
                    Trending
                  </Text>
                <ActionIcon
                  variant="filled"
                  color="#ffffff"
                  size="20px"
                  h={25}
                  w={25}
                  radius={"50%"}
                  c={'#F87265'}
                >
                  <IconHeartFilled size={15} />
                </ActionIcon>
              </Flex>
            </BackgroundImage>
            <Box>
              <Text fz={12} fw={700} mb={2} mt={10}>
                Modern light Clothes
              </Text>
              <Text fz={10} c="#ADB5BD">
                Dress modern
              </Text>
            </Box>
            <Flex align="center" justify="space-between">
              <Box>
                <Text fz={14} fw={600}>
                  $212
                </Text>
              </Box>
              <Flex align="center" justify="center" gap={5}>
                <Box c={'#FCC419'}>
                  <IconStarFilled size={14} />
                </Box>
                <Text fz={12} c={'#1B2028'}>5.0</Text>
              </Flex>
            </Flex>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box radius="15px" w={150} h={215} my={5}>
            <BackgroundImage
              src="https://images.pexels.com/photos/935760/pexels-photo-935760.jpeg?auto=compress&cs=tinysrgb&w=300"
              radius="15px"
              h={165}
            >
              <Flex align="center" justify="space-between" p={10}>
                  <Text
                    bg="#F13658"
                    px={8}
                    py={6}
                    fz={10}
                    fw={500}
                    c="#ffffff"
                    style={{ borderRadius: "10px" }}
                  >
                    Trending
                  </Text>
                <ActionIcon
                  variant="filled"
                  color="#ffffff"
                  size="20px"
                  h={25}
                  w={25}
                  radius={"50%"}
                >
                  <IconHeart size={15} color="#000000" />
                </ActionIcon>
              </Flex>
            </BackgroundImage>
            <Box>
              <Text fz={12} fw={700} mb={2} mt={10}>
                Modern light Clothes
              </Text>
              <Text fz={10} c="#ADB5BD">
                Dress modern
              </Text>
            </Box>
            <Flex align="center" justify="space-between">
              <Box>
                <Text fz={14} fw={600}>
                  $212
                </Text>
              </Box>
              <Flex align="center" justify="center" gap={5}>
                <Box c={'#FCC419'}>
                  <IconStarFilled size={14} />
                </Box>
                <Text fz={12} c={'#1B2028'}>5.0</Text>
              </Flex>
            </Flex>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box radius="15px" w={150} h={215} my={5}>
            <BackgroundImage
              src="https://images.pexels.com/photos/935760/pexels-photo-935760.jpeg?auto=compress&cs=tinysrgb&w=300"
              radius="15px"
              h={165}
            >
              <Flex align="center" justify="space-between" p={10}>
                  <Text
                    bg="#F13658"
                    px={8}
                    py={6}
                    fz={10}
                    fw={500}
                    c="#ffffff"
                    style={{ borderRadius: "10px" }}
                  >
                    Trending
                  </Text>
                <ActionIcon
                  variant="filled"
                  color="#ffffff"
                  size="20px"
                  h={25}
                  w={25}
                  radius={"50%"}
                >
                  <IconHeart size={15} color="#000000" />
                </ActionIcon>
              </Flex>
            </BackgroundImage>
            <Box>
              <Text fz={12} fw={700} mb={2} mt={10}>
                Modern light Clothes
              </Text>
              <Text fz={10} c="#ADB5BD">
                Dress modern
              </Text>
            </Box>
            <Flex align="center" justify="space-between">
              <Box>
                <Text fz={14} fw={600}>
                  $212
                </Text>
              </Box>
              <Flex align="center" justify="center" gap={5}>
                <Box c={'#FCC419'}>
                  <IconStarFilled size={14} />
                </Box>
                <Text fz={12} c={'#1B2028'}>5.0</Text>
              </Flex>
            </Flex>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box radius="15px" w={150} h={215} my={5}>
            <BackgroundImage
              src="https://images.pexels.com/photos/935760/pexels-photo-935760.jpeg?auto=compress&cs=tinysrgb&w=300"
              radius="15px"
              h={165}
            >
              <Box>
                <Flex align="center" justify="space-between" p={10}>
                  <Text
                    bg="#F13658"
                    px={8}
                    py={6}
                    fz={10}
                    fw={500}
                    c="#ffffff"
                    style={{ borderRadius: "10px" }}
                  >
                    Trending
                  </Text>
                  <ActionIcon
                    variant="filled"
                    color="#ffffff"
                    size="20px"
                    h={25}
                    w={25}
                    radius={"50%"}
                  >
                    <IconHeart size={15} color="#000000" />
                  </ActionIcon>
                </Flex>
              </Box>
            </BackgroundImage>
            <Box>
              <Text fz={12} fw={700} mb={2} mt={10}>
                Modern light Clothes
              </Text>
              <Text fz={10} c="#ADB5BD">
                Dress modern
              </Text>
            </Box>
            <Flex align="center" justify="space-between">
              <Box>
                <Text fz={14} fw={600}>
                  $212
                </Text>
              </Box>
              <Flex align="center" justify="center" gap={5}>
                <Box c={'#FCC419'}>
                  <IconStarFilled size={14} />
                </Box>
                <Text fz={12} c={'#1B2028'}>5.0</Text>
              </Flex>
            </Flex>
          </Box>
        </Grid.Col>
        <Grid.Col span={6}>
          <Box radius="15px" w={150} h={215} my={5}>
            <BackgroundImage
              src="https://images.pexels.com/photos/935760/pexels-photo-935760.jpeg?auto=compress&cs=tinysrgb&w=300"
              radius="15px"
              h={165}
            >
              <Flex align="center" justify="space-between" p={10}>
                  <Text
                    bg="#F13658"
                    px={8}
                    py={6}
                    fz={10}
                    fw={500}
                    c="#ffffff"
                    style={{ borderRadius: "10px" }}
                  >
                    Trending
                  </Text>
                <ActionIcon
                  variant="filled"
                  color="#ffffff"
                  size="20px"
                  h={25}
                  w={25}
                  radius={"50%"}
                  c={'#F87265'}
                >
                  <IconHeartFilled size={15} />
                </ActionIcon>
              </Flex>
            </BackgroundImage>
            <Box>
              <Text fz={12} fw={700} mb={2} mt={10}>
                Modern light Clothes
              </Text>
              <Text fz={10} c="#ADB5BD">
                Dress modern
              </Text>
            </Box>
            <Flex align="center" justify="space-between">
              <Box>
                <Text fz={14} fw={600}>
                  $212
                </Text>
              </Box>
              <Flex align="center" justify="center" gap={5}>
                <Box c={'#FCC419'}>
                  <IconStarFilled size={14} />
                </Box>
                <Text fz={12} c={'#1B2028'}>5.0</Text>
              </Flex>
            </Flex>
          </Box>
        </Grid.Col>
      </Grid>
      {/* Products section end */}
      {/* Bottom tab start */}
      <Box
        pos="absolute"
        bottom={20}
        right={12}
        bg="#292526"
        w={350}
        h={60}
        p={15}
        style={{
          borderRadius: "50px",
          boxShadow: "-3px -14px 55px -1px #DDDDDD",
        }}
      >
        <Flex align="center" justify="space-between" style={{ height: "100%" }}>
          <ActionIcon variant="transparent" size={22}>
            <IconHome2 size={24} color="#E03131" />
          </ActionIcon>
          <ActionIcon variant="transparent" size={22}>
            <IconMessageCircle size={24} color="#fff" />
          </ActionIcon>
          <ActionIcon variant="transparent" size={22}>
            <IconSearch size={24} color="#fff" />
          </ActionIcon>
          <ActionIcon variant="transparent" size={22}>
            <IconShoppingCart size={24} color="#fff" />
          </ActionIcon>
          <ActionIcon variant="transparent" size={22}>
            <IconUser size={24} color="#fff" />
          </ActionIcon>
        </Flex>
      </Box>
      {/* Bottom tab end */}
    </Box>
  );
};

export default Products;
