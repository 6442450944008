import { Carousel } from "@mantine/carousel";
import { Box, Flex, Image, Input, Text } from "@mantine/core";
import { IconAccessible, IconHeadset, IconScooterElectric, IconShirtSport, IconShoe, IconSearch, IconPlus, IconHeartFilled, IconMenu2, IconHome, IconCompass, IconShoppingCart, IconUserCircle } from "@tabler/icons-react";
import React from "react";

const Home = () => {
  return (
    <Box p={8} pos={'relative'}>

      {/* Template Title Start */}
      <Text c={"#ffffff"} pos={'absolute'} top={-65} fw={600} fz={20} left={'50%'} w={"100%"} ta={'center'} style={{ transform: "translateX(-50%)"}}>Home Template</Text>
      {/* Template Title End */}

      {/* Top Navigation Start */}
      <Flex justify={'space-between'}>
        <IconMenu2 style={{color: '#fff', cursor: 'pointer'}}/>
        <Image radius="12px" src="https://images.pexels.com/photos/3778680/pexels-photo-3778680.jpeg" h={32} w={32} />
      </Flex>
      {/* Top Navigation End */}

      {/* Search Bar Start */}
      <Text fz={20} fw={600} c={"#ffffff"} mt={10}> Discover Your Best Gadget </Text>
      <Flex justify="flex-start" align="center" mt={10} h={50} pl={10} style={{ borderRadius: "30px", border: "1px solid #7f8c8d", cursor: "text" }}>
        <IconSearch color="#fff" size={20} />
        <Input variant="unstyled" placeholder="Search" ml={15} size="16" />
      </Flex>
      
      {/* Search Bar End */}

      {/* Categories Carousel Start */}
      <Carousel     
        slideSize={85}
        dragFree
        align="start"
        withControls={false}
        mt={20}
      >
        <Carousel.Slide>
          <Flex w={{ base: 55 }} h={{ base: 55 }} bg={"#181818"} justify="center" align="center" style={{  border: "2px solid #2F3134",borderRadius: "8px", boxShadow: "-4px -4px 12px 0px #25272880" }} >
            <IconScooterElectric color="#00B9D3" size={30} />
          </Flex>
          <Text fz={11} c={"#ffffff"} mt={5}>Electronics</Text>
        </Carousel.Slide>

        <Carousel.Slide>
          <Flex w={{ base: 55 }} h={{ base: 55 }} bg={"#181818"} justify="center" align="center" style={{  border: "2px solid #2F3134",borderRadius: "8px", boxShadow: "-4px -4px 12px 0px #25272880" }} >
            <IconAccessible color="#00B9D3" size={30} />
          </Flex>
          <Text fz={12} c={"#ffffff"} mt={5} ml={-7}>Accessories</Text>
        </Carousel.Slide>

        <Carousel.Slide>
            <Flex w={{ base: 55 }} h={{ base: 55 }} bg={"#181818"} justify="center" align="center" style={{  border: "2px solid #2F3134",borderRadius: "8px", boxShadow: "-4px -4px 12px 0px #25272880" }} >
              <IconShirtSport color="#00B9D3" size={30} />
            </Flex>
            <Text fz={12} c={"#ffffff"} mt={5}>Clothing</Text>
        </Carousel.Slide>

        <Carousel.Slide>
            <Flex w={{ base: 55 }} h={{ base: 55 }} bg={"#181818"} justify="center" align="center" style={{  border: "2px solid #2F3134",borderRadius: "8px", boxShadow: "-4px -4px 12px 0px #25272880" }} >
              <IconShoe color="#00B9D3" size={30} />
            </Flex>
            <Text fz={12} c={"#ffffff"} mt={5} ml={10}>Shoes</Text>
        </Carousel.Slide>

        <Carousel.Slide>
            <Flex w={{ base: 55 }} h={{ base: 55 }} bg={"#181818"} justify="center" align="center" style={{  border: "2px solid #2F3134",borderRadius: "8px", boxShadow: "-4px -4px 12px 0px #25272880" }} >
              <IconHeadset color="#00B9D3" size={30} />
            </Flex>
            <Text fz={12} c={"#ffffff"} mt={5}>Gadgets</Text>
        </Carousel.Slide>

      </Carousel>
      {/* Categories Carousel End */}

      <Box style={{ overflowX: "hidden" }}>
        {/* Section Title Start */}
        <Flex align="center" justify="space-between" mt={30} mb={40}>
          <Text fz={38} fw={600} c={"#ffffff"}>
            Popular
          </Text>
          <Flex align="center" justify="space-between">
            <Box h={12} w={12} bg={'#7f8c8d'} style={{ borderRadius: "50%" }}></Box>
            <Box h={9} w={9} bg={'#c6c6c6'} ml={8} style={{ borderRadius: "50%" }}></Box>
            <Box h={7} w={7} bg={'#cccccc'} ml={8} style={{ borderRadius: "50%" }}></Box>
          </Flex>
        </Flex>
        {/* Section Title End */}

        {/* Product Carousel Start */}
        <Box mt="80px" mb={40}>
          <Carousel slideSize={190} height={250} align="start" withControls={false} dragFree styles={{ viewport: {overflow: "visible", verflowX: "clip",} }}>

            <Carousel.Slide mx={5}>
              <Box p="15px" h={200} bg={"#181818"} style={{ border: "2px solid #2F3134", boxShadow: "-4px -4px 12px 0px #25272880", borderRadius: "10px" }} >

                  <Image src="https://media.wired.com/photos/64daab510aff48ce869e0e3b/master/pass/Lenovo-Slim-Pro-9i-Gear.jpg" alt="Laptop" radius="20px" pos={"absolute"} w={"157px"} h={"145px"} bottom={"65%"} />
                  <Box pos={"absolute"} right={30} bottom={"108%"} c={'#4c6e93'}>
                    <IconHeartFilled />
                  </Box>

                <Box mt={60}>
                  <Text fw={500} fz={16} c="#ffffff" mt={90}> Laptop </Text>
                  <Text c="dimmed" fz={14} fw={400} mb={15}> Electronic </Text>
                  <Text fw={700} size="20px" c={'#ffffff'} mt={-10}> $ 24.2 </Text>
                  <Flex mt={-45} h={50} w={50} justify={"center"} align={"center"} style={{ borderRadius: "13px", backgroundImage: "linear-gradient(126deg, #4C6C93 10%, #6BAEAE 110%)", float: "right", cursor: "pointer" }}>
                    <IconPlus color="#ffffff"/>
                  </Flex>
                </Box>
              </Box>
            </Carousel.Slide>

            <Carousel.Slide mx={5}>
              <Box p="15px" h={200}  bg={"#181818"} style={{ border: "2px solid #2F3134", boxShadow: "-4px -4px 12px 0px #25272880", borderRadius: "10px" }} >

                <Image src="https://media.wired.com/photos/6511aab1189c419c40374c92/1:1/w_1358,h_1358,c_limit/Apple-Watch-Ultra-2-Alt-Gear.jpg" alt="Laptop" radius="20px" pos={"absolute"} w={"157px"} h={"145px"} bottom={"65%"} />
                <Box pos={"absolute"} right={30} bottom={"108%"} c={'#4c6e93'}>
                  <IconHeartFilled />
                </Box>

                <Box mt={60}>
                  <Text fw={500} fz={16} c="#ffffff" mt={90}> Smart Watch </Text>
                  <Text c="dimmed" fz={14} fw={400} mb={15}> Electronic </Text>
                  <Text fw={700} size="20px" c={'#ffffff'} mt={-10}> $ 24.2 </Text>
                  <Flex mt={-45} h={50} w={50} justify={"center"} align={"center"} style={{ borderRadius: "13px", backgroundImage: "linear-gradient(126deg, #4C6C93 10%, #6BAEAE 110%)", float: "right", cursor: "pointer" }}>
                      <IconPlus color="#ffffff"/>
                    </Flex>
                </Box>
              </Box>
            </Carousel.Slide>

            <Carousel.Slide mx={5}>
              <Box p="15px" h={200} bg={"#181818"} style={{ border: "2px solid #2F3134", boxShadow: "-4px -4px 12px 0px #25272880", borderRadius: "10px" }} >

                <Image src="https://cdn.thewirecutter.com/wp-content/media/2023/10/androidphones-2048px-4858.jpg?auto=webp&quality=75&width=1024" alt="Laptop" radius="20px" pos={"absolute"} w={"157px"} h={"145px"} bottom={"65%"} />
                <Box pos={"absolute"} right={30} bottom={"108%"} c={'#4c6e93'}>
                  <IconHeartFilled />
                </Box>

                <Box mt={60}>
                  <Text fw={500} fz={16} c="#ffffff" mt={90}> Pixel 7 Pro </Text>
                  <Text c="dimmed" fz={14} fw={400} mb={15}> Electronic </Text>
                  <Text fw={700} size="20px" c={'#ffffff'} mt={-10}> $ 24.2 </Text>
                  <Flex mt={-45} h={50} w={50} justify={"center"} align={"center"} style={{ borderRadius: "13px", backgroundImage: "linear-gradient(126deg, #4C6C93 10%, #6BAEAE 110%)", float: "right", cursor: "pointer" }}>
                      <IconPlus color="#ffffff"/>
                    </Flex>
                </Box>
              </Box>
            </Carousel.Slide>

          </Carousel>
        </Box>
        {/* Product Carousel End */}
      </Box>
      
      {/* Bottom Nav Start */}
      <Flex justify={'space-between'} align={'center'} pos={'absolute'} bg={'#1A1A1A'} w={'375px'} left={-25} bottom={-25} h={65} py={25} px={30} style={{ borderBottomLeftRadius: '30px', borderBottomRightRadius: '30px', }}>
        <IconHome color="#00B9D3"/>
        <IconCompass color="#A5A5A5"/>
        <IconShoppingCart color="#A5A5A5" />
        <IconUserCircle color="#A5A5A5" />
      </Flex>
      {/* Bottom Nav End */}

    </Box>
  );
};

export default Home;
