import React from "react";
import { Box, Flex } from "@mantine/core";
import Home from "./Home";
import Explore from "./Explore";
import ProductSingle from "./ProductSingle";
import Cart from "./Cart";
import Checkout from "./Checkout";
import Profile from "./Profile";

const FourthRow = () => {
  return (
    <>
      <Flex px={33} align="center" mb={50}>
        {/* Home Screen Start */}
        <Box
          mr={20}
          w={375}
          bg={"#F7F7F9"}
          py={25}
          style={{ borderRadius: "30px" }}
        >
          <Home />
        </Box>
        {/* Home Screen End */}

        {/* Explore Screen Start */}
        <Box
          mr={20}
          w={375}
          bg={"#F7F7F9"}
          pt={25}
          pb={0}
          px={20}
          style={{ borderRadius: "30px" }}
        >
          <Explore />
        </Box>
        {/* Category Single Screen End */}

        {/* Product Single Screen Start */}
        <Box
          mr={20}
          w={375}
          bg={"#F7F7F9"}
          py={25}
          px={20}
          style={{ borderRadius: "30px" }}
        >
          <ProductSingle />
        </Box>
        {/* Product Single Screen End */}

        {/* Cart Screen Start */}
        <Box
          mr={20}
          w={375}
          bg={"#F7F7F9"}
          py={25}
          px={20}
          style={{ borderRadius: "30px" }}
        >
          <Cart />
        </Box>
        {/* Cart Screen End */}

        {/* Checkout Screen Start */}
        <Box
          mr={20}
          w={375}
          bg={"#F7F7F9"}
          py={25}
          px={20}
          style={{ borderRadius: "30px" }}
        >
          <Checkout />
        </Box>
        {/* Checkout Screen End */}

        {/* Profile Screen Start */}
        <Box
          mr={20}
          w={375}
          bg={"#F7F7F9"}
          py={25}
          px={20}
          style={{ borderRadius: "30px" }}
        >
          <Profile />
        </Box>
        {/* Profile Screen End */}
      </Flex>
    </>
  );
};

export default FourthRow;
