import { Box, Button, Flex, Text } from "@mantine/core";
import { IconStarFilled, IconChevronLeft, IconShoppingCart, IconHeartFilled } from "@tabler/icons-react";
import React from "react";

const ProductSingle = () => {
  return (
    <Box p={8}>

        {/* Top Navigation Start */}
        <Flex pos={'absolute'} c={'#ffffff'} top={30} justify={'space-between'} align={'center'} w={'100%'} left={0} px={25}>
            <IconChevronLeft />
            <IconShoppingCart />
        </Flex>
        {/* Top Navigation End */}

        {/* Title Section Start */}
        <Box px={25}>
            <Flex justify={'space-between'} mt={10}>
                <Box>
                    <Text c={'#ffffff'} fz={24} fw={500} mb={5}>Headphone Z</Text>
                    <Flex mb={12} align={'center'}>
                        <Text c={'#585858'} fz={12} fw={500} style={{ textDecoration: "underline" }}>$45.00</Text>
                        <Text c={'#ECEBEB'} fz={16} fw={500} ml={10}>32.00</Text> 
                    </Flex>
                </Box>
                <Box c={'#4c6e93'}>
                    <IconHeartFilled />
                </Box>
            </Flex>
            <Flex>
                <Box c={'#E7B53D'} ml={5} mb={-7} style={{ display: "inline-block" }}>
                    <IconStarFilled style={{ width: "13px" }}/>
                </Box>
                <Text c={'#ffffff'} fz={16} fw={500} ml={5}>4.9</Text>
            </Flex>
        </Box>
        {/* Title Section End */}

        {/* Description Section Start */}
        <Box px={25} mt={15}>
            <Text c={'#ffffff'} fz={16} fw={500}>Descriptoin</Text>
            <Text c={'#A7A7A7'} mt={10} lh={'22px'} fw={500} fz={12} style={{ display: "inline" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo...<Text fz={12} fw={500} c={'#00B9D3'} style={{ display: "inline" }}>Read More</Text></Text>
        </Box>
        {/* Description Section Start */}

        {/* Buttons Start */}
        <Flex px={25} mt={25} justify={'space-between'} align={'center'}>
            <Button variant="default" c={'#ffffff'} fz={16} fw={500} h={50} style={{ borderColor: "#B1B1B1", cursor: 'pointer', flexBasis: "48%", backgroundColor: "transparent", borderRadius: "30px" }}>Add to Cart</Button>
            <Button variant="filled" c={'#ffffff'} fz={16} fw={500} h={50} bg={'#00B9D3'} style={{ cursor: 'pointer', flexBasis: "48%", borderRadius: "30px" }}>Buy Now</Button>
        </Flex>
        {/* Buttons End */}

        {/* Bottom Bar Start */}
        <Box w={128} h={7} bg={'#d9d9d9'} mt={25} mb={10} mx={'auto'} style={{ borderRadius: "12px" }}></Box>
        {/* Bottom Bar End */}
      
    </Box>
  );
};

export default ProductSingle;
