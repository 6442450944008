import "@mantine/carousel/styles.css";
import { createTheme, MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const theme = createTheme({
  colorScheme: "light", // or 'dark'
  colors: {
    // Define your colors here
    orange: [
      "#FFF9DB",

      "#FFF3BF",

      "#FFEC99",

      "#FFE066",

      "#FFD43B",

      "#FCC419",

      "#FAB005",

      "#F59F00",

      "#F08C00",

      "#E67700",
    ],
    black: [
      "#C9C9C9",

      "#B8B8B8",

      "#828282",

      "#696969",

      "#424242",

      "#3B3B3B",

      "#2E2E2E",

      "#242424",

      "#1F1F1F",

      "#141414",
    ],
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <MantineProvider theme={theme}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
    </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
