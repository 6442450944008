import { Carousel } from "@mantine/carousel";
import {
  Box,
  Flex,
  Image,
  Text,
  Title,
} from "@mantine/core";
import {
  IconAccessible,
  IconBrandSafari,
  IconHeadset,
  IconHome,
  IconMenu2,
  IconScooterElectric,
  IconSearch,
  IconShirtSport,
  IconShoe,
  IconShoppingCart,
  IconUserCircle,
  IconHeart,
  IconPlus,
  IconHeartFilled,
} from "@tabler/icons-react";
import React from "react";

const Home = () => {
  return (
    <Box pos={"relative"} h="100%">
      {/* Nav Start */}
      <Flex pt={24} px={24} align="center" justify="space-between">
        <IconMenu2 size={35} />
        <Flex align="center" justify="space-between">
          <Box mr={20}>
            <IconSearch size={25} />
          </Box>
          <Image radius="14px" src="https://images.pexels.com/photos/447186/pexels-photo-447186.jpeg" h={47} w={47} />
        </Flex>
      </Flex>
      {/* Nav End */}

      {/* Popular Sectin Title Start */}
      <Flex px={24} my={15} align="center" justify="space-between">
        <Text fz={35} fw={600}>
          Popular
        </Text>
        <Flex align="center" justify="space-between">
          <Box h={12} w={12} bg={'#7f8c8d'} style={{ borderRadius: "50%" }}></Box>
          <Box h={9} w={9} bg={'#c6c6c6'} ml={8} style={{ borderRadius: "50%" }}></Box>
          <Box h={7} w={7} bg={'#cccccc'} ml={8} style={{ borderRadius: "50%" }}></Box>
        </Flex>
      </Flex>
      {/* Popular Sectin Title End */}

      {/* Product Carousel Start */}
      <Box pl={24} mt="50px">
        <Carousel
          slideSize={190}
          height={250}
          dragFree
          align="start"
          withControls={false}
          styles={{
            viewport: {
              overflow: "visible",
              overflowX: "clip",
            },
          }}
        >
          <Carousel.Slide>
            <Box
              p={10}
              h={200}
              w={160}
              style={{
                boxShadow: "3px -2px 44px -6px #29242469",
                borderRadius: "15px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Box pos="absolute" top="-20px" ta="center" w={140} h={120}>
                <Image
                  src="https://media.wired.com/photos/64daab510aff48ce869e0e3b/master/pass/Lenovo-Slim-Pro-9i-Gear.jpg"
                  alt="Laptop"
                  radius="20px"
                  style={{ width: " 100%", height: " 100%" }}
                />

                <Box pos="absolute" top={12} right={12}>
                  <IconHeart color="#fff" size={20} />
                </Box>
              </Box>

              <Box td="none" w="100%">
                <Text fw={600} fz={18} c="#000000">
                  Laptop
                </Text>
                <Text c="#868E96" fz={12}>
                  Electronic
                </Text>
                <Flex justify="space-between" align="center" pos="relative" w="100%">
                  <Text fw={700} fz={20} c="#1f1f1f">
                    $ 24.2
                  </Text>

                  <Flex
                    align="center"
                    justify="center"
                    w={35}
                    h={35}
                    bg="linear-gradient(150deg, #4c6c93 0%, ##649fa8 100%)"
                    style={{ borderRadius: "10px" }}
                    pos="absolute"
                    bottom={0}
                    right={0}
                  >
                    <IconPlus color="#ffffff" />
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Carousel.Slide>
          <Carousel.Slide>
            <Box
              p={10}
              h={200}
              w={160}
              style={{
                boxShadow: "3px -2px 44px -6px #29242469",
                borderRadius: "15px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Box pos="absolute" top="-20px" ta="center" w={140} h={120}>
                <Image
                  src="https://media.wired.com/photos/6511aab1189c419c40374c92/1:1/w_1358,h_1358,c_limit/Apple-Watch-Ultra-2-Alt-Gear.jpg"
                  alt="Laptop"
                  radius="20px"
                  style={{ width: " 100%", height: " 100%" }}
                />
                <Box pos="absolute" c="#f52029" top={12} right={12}>
                  <IconHeartFilled size={20} />
                </Box>
              </Box>

              <Box td="none" w="100%">
                <Text fw={600} fz={18} c="#000000">
                  Smart Watch
                </Text>
                <Text c="#868E96" fz={12}>
                  Electronic
                </Text>

                <Flex justify="space-between" align="center" pos="relative" w="100%">
                  <Text fw={700} fz={20} c="#1f1f1f">
                    $ 24.2
                  </Text>

                  <Flex
                    align="center"
                    justify="center"
                    w={35}
                    h={35}
                    bg="linear-gradient(150deg, #4c6c93 0%, ##649fa8 100%)"
                    style={{ borderRadius: "10px" }}
                    pos="absolute"
                    bottom={0}
                    right={0}
                  >
                    <IconPlus color="#ffffff" />
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Carousel.Slide>
          <Carousel.Slide>
            <Box
              p={10}
              h={200}
              w={160}
              style={{
                boxShadow: "3px -2px 44px -6px #29242469",
                borderRadius: "15px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Box pos="absolute" top="-20px" ta="center" w={140} h={120}>
                <Image
                  src="https://cdn.thewirecutter.com/wp-content/media/2023/10/androidphones-2048px-4858.jpg?auto=webp&quality=75&width=1024"
                  alt="Laptop"
                  radius="20px"
                  style={{ width: " 100%", height: " 100%" }}
                />
                <Box pos="absolute" top={12} right={12}>
                  <IconHeart color="#fff" size={20} />
                </Box>
              </Box>

              <Box td="none" w="100%">
                <Text fw={600} fz={18} c="#000000">
                  Pixel 7 pro{" "}
                </Text>
                <Text c="#868E96" fz={12}>
                  Electronic
                </Text>
                <Flex justify="space-between" align="center" pos="relative" w="100%">
                  <Text fw={700} fz={20} c="#1f1f1f">
                    $ 24.2
                  </Text>

                  <Flex
                    align="center"
                    justify="center"
                    w={35}
                    h={35}
                    bg="linear-gradient(150deg, #4c6c93 0%, ##649fa8 100%)"
                    style={{ borderRadius: "10px" }}
                    pos="absolute"
                    bottom={0}
                    right={0}
                  >
                    <IconPlus color="#ffffff" />
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Carousel.Slide>
        </Carousel>
      </Box>
      {/* Product Carousel End */}

      {/* Categories section Start */}
      <Carousel
        pl={24}
        slideSize={75}
        height={100}
        dragFree
        align="start"
        withControls={false}

      >
        <Carousel.Slide>
          <Flex align="center" justify="center" direction="column">
            <Flex
              w={{ base: 50 }}
              h={{ base: 50 }}
              bg="#F76707"
              justify="center"
              align="center"
              style={{ borderRadius: "12px" }}
            >
              <IconScooterElectric color="#ffffff" />
            </Flex>
            <Text fz={12} mt={3}>Electronics</Text>
          </Flex>
        </Carousel.Slide>
        <Carousel.Slide>
          <Flex align="center" justify="center" direction="column">
            <Flex
              w={{ base: 50 }}
              h={{ base: 50 }}
              bg="#FF922B"
              justify="center"
              align="center"
              style={{ borderRadius: "12px" }}
            >
              <IconAccessible color="#ffffff" />
            </Flex>
            <Text fz={12} mt={3}>Accessories</Text>
          </Flex>
        </Carousel.Slide>
        <Carousel.Slide>
          <Flex align="center" justify="center" direction="column">
            <Flex
              w={{ base: 50 }}
              h={{ base: 50 }}
              bg="#000000"
              justify="center"
              align="center"
              style={{ borderRadius: "12px" }}
            >
              <IconShirtSport color="#ffffff" />
            </Flex>
            <Text fz={12} mt={3}>Clothing</Text>
          </Flex>
        </Carousel.Slide>
        <Carousel.Slide>
          <Flex align="center" justify="center" direction="column">
            <Flex
              w={{ base: 50 }}
              h={{ base: 50 }}
              bg="#AE3EC9"
              justify="center"
              align="center"
              style={{ borderRadius: "12px" }}
            >
              <IconShoe color="#ffffff" />
            </Flex>
            <Text fz={12} mt={3}>Shoes</Text>
          </Flex>
        </Carousel.Slide>
        <Carousel.Slide>
          <Flex align="center" justify="center" direction="column">
            <Flex
              w={{ base: 50 }}
              h={{ base: 50 }}
              bg="#E64980"
              justify="center"
              align="center"
              style={{ borderRadius: "12px" }}
            >
              <IconHeadset color="#ffffff" />
            </Flex>
            <Text fz={12} mt={3}>Gadgets</Text>
          </Flex>
        </Carousel.Slide>
      </Carousel>
      {/* Categories section End */}

      {/* Collection Section Start */}
      <Box mb={10} pl={24}>
        <Box py={8}>
          <Title order={3}> Collections</Title>
        </Box>
        <Carousel
          slideSize={70}
          height={50}
          slideGap={13}
          dragFree
          align="start"
          withControls={false}
          styles={{
            viewport: {
              overflow: "visible",
              overflowX: "clip",
            },
          }}
        >
          <Carousel.Slide>
            <Flex
              bg="#ffffff"
              p={10}
              w={250}
              align="center"
              gap={15}
              style={{
                borderRadius: "12px",
              }}

            >
              <Box w={45} h={50}>
                <Image
                  src="https://boldoutline.in/wp-content/uploads/2021/05/Web-cover-101.jpg"
                  alt=""
                  radius="10px"
                  style={{ width: " 100%", height: " 100%" }}
                />
              </Box>
              <Box>
                <Text fz={15} fw={600}>
                  Summer Collections
                </Text>
                <Text mt={4} fz={13} c="#868E96">
                  Tshirt
                </Text>
              </Box>
            </Flex>
          </Carousel.Slide>
          <Carousel.Slide>
            <Flex
              bg="#ffffff"
              p={10}
              w={250}
              align="center"
              gap={15}
              style={{
                borderRadius: "12px",
              }}
            >
              <Box w={45} h={50}>
                <Image
                  src="https://efamas.com.bd/wp-content/uploads/2022/11/IMG-20221120-WA0028.jpg"
                  alt=""
                  radius="10px"
                  style={{ width: " 100%", height: " 100%" }}
                />
              </Box>
              <Box>
                <Text fz={15} fw={600}>
                  Winter Collections
                </Text>
                <Text mt={4} fz={13} c="#868E96">
                  Tshirt
                </Text>
              </Box>
            </Flex>
          </Carousel.Slide>
        </Carousel>
      </Box>
      {/* Collection Section End */}

      {/* Bottom Nav Start */}
      <Box pos={"absolute"} py={5} left={0} bottom={0} w={"100%"} bg="#ffffff" style={{ boxShadow: "#83aad11f 0px -4px 4px 0px", borderBottomLeftRadius: "30px" , borderBottomRightRadius: "30px" }}>
        <Flex
          py={14}
          align="center"
          justify="space-around"
        >
          <IconHome color="#E03131" />
          <IconBrandSafari color="#868E96" />
          <IconShoppingCart color="#868E96" />
          <IconUserCircle color="#868E96" />
        </Flex>{" "}
      </Box>
      {/* Bottom Nav Start */}
    </Box>
  );
};

export default Home;
