import { Box, Flex, Text } from "@mantine/core";
import React from "react";
import Home from "./Home";
import Products from "./Products";
import Cart from "./Cart";
import CheckOut from "./CheckOut";
import { ProductSingle } from "./ProductSingle";
import Profile from "./Profile";
const FifthRow = () => {
  return (
    <Box>
      <Flex px={25} align="center" mb={50}>
        {/* Home Screen Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            Home Screen
          </Text>
          <Box
            bg="#F8F9FA"
            m={10}
            w={375}
            h={760}
          >
            <Home />
          </Box>
        </Box>
        {/* Home Screen End */}

        {/* Product Screen Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            Product Screen
          </Text>
          <Box
            bg="#F8F9FA"
            m={10}
            w={375}
            h={760}
          >
            <Products />
          </Box>
        </Box>
        {/* Product Screen End */}

        {/* Product Single Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            Product Single Screen
          </Text>
          <Box
            bg="#F8F9FA"
            m={10}
            w={375}
          >
            <ProductSingle />
          </Box>
        </Box>
        {/* Product Single End */}

        {/* Cart Screen Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            Cart Screen
          </Text>
          <Box
            bg="#F8F9FA"
            m={10}
            w={375}
            h={760}
          >
            <Cart />
          </Box>
        </Box>
        {/* Cart Screen End */}

        {/* Checkout Screen Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            Checkout Screen
          </Text>
          <Box
            bg="#F8F9FA"
            m={10}
            w={375}
            h={760}
          >
            <CheckOut />
          </Box>
        </Box>
        {/* Checkout Screen End */}

        {/* Profile Screen Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#ffffff">
            Profile Screen
          </Text>
          <Box
            bg="#F8F9FA"
            m={10}
            w={375}
          >
            <Profile />
          </Box>
        </Box>
        {/* Profile Screen End */}

      </Flex>
    </Box>
  );
};

export default FifthRow;
