import SecondRow from "./SecondRow/SecondRow";
import FifthRow from "./FifthRow/FifthRow";
import FirstRow from "./FirstRow/FirstRow";
import ThirdRow from "./ThirdRow/ThirdRow";
import FourthRow from "./FourthRow/FourthRow";
import SixthRow from "./SixthRow/SixthRow";
import { Box } from "@mantine/core";
function App() {
  return (
    <Box bg="gray" pb='xl' w={'3000px'}>
      <FirstRow></FirstRow>
      <SecondRow></SecondRow>
      <ThirdRow></ThirdRow>
      <FourthRow></FourthRow>
      <FifthRow />
      <SixthRow></SixthRow>
    </Box>
  );
}

export default App;
