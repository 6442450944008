import {
  Box,
  Flex,
  Image,
  Text,
  ActionIcon
} from "@mantine/core";
import {
  IconMenu2,
  IconSearch,
  IconBellRinging,
  IconHome,
  IconMessage,
  IconShoppingCart,
  IconUserCircle,
  IconCheck,
  IconTrendingUp,
  IconTags,
  IconStarFilled,
  IconTruckDelivery,
  IconLogout
} from "@tabler/icons-react";
import React from "react";


const Profile = () => {
  return (
    <Box h='100%' pos='relative'>
      {/* header start */}
      <Flex px={20} pt={20} align="center" justify="space-between">
        <IconMenu2></IconMenu2>
        <Text ta="center" size={18} lh={1} fw={700}>Profile</Text>

        <ActionIcon bg="#ffffff" variant="filled" radius="50%" aria-label="Settings">
          <IconBellRinging style={{ width: '70%', height: '70%', color: '#cfcfcf' }} stroke={1.5} />
        </ActionIcon>
      </Flex>
      {/* header end */}

      {/* main section start */}
      <Box p={23} h="100%" mt={140} bg="#fff" pos="relative" style={{ borderTopLeftRadius: "30px", borderTopRightRadius: "30px" }}>
        {/* basic info section start */}
        <Box>
          <Image
            h={200}
            w={200}
            pos="absolute"
            top={-100}
            left={0}
            right={0}
            mx="auto"
            src="https://i.postimg.cc/6678S5G0/pexels-italo-melo-2379005.jpg"
            style={{ borderRadius: "50%", objectFit: "cover" }}
          />

          <Box ta="center">
            <Flex mt={100} align="center" justify="center" gap={8}>
              <Flex align="center" justify="center" c="#fff" bg="#00c825" h={22} w={22} p={3} style={{ borderRadius: "50%" }}>
                <IconCheck></IconCheck>
              </Flex>

              <Text fw={500} size="20" lh={1}>Jack Morata</Text>
            </Flex>
            <Text mt={20} fw={500} c="#969696" size="16" lh={1}>jackmorata@gmail.com</Text>
          </Box>
        </Box>
        {/* basic info section end */}

        {/* statics info section strat */}
        <Flex mt={50} gap={20} justify="space-between" align="center">
          <Flex justify="center" align="center" direction="column" p={10} w="100%" span={4} bg="#e7f2ff" style={{ borderRadius: "10px" }}>
            <Text c="#00b8ca">
              <IconTrendingUp></IconTrendingUp>
            </Text>

            <Text c="#888d94" size={11} lh={1}>Progress order</Text>
            <Text c="#888d94" size={14} fw={700} mt={12} lh={1}>10+</Text>
          </Flex>

          <Flex justify="center" align="center" direction="column" p={10} w="100%" span={4} bg="#e7f2ff" style={{ borderRadius: "10px" }}>
            <Text c="#006fde">
              <IconTags></IconTags>
            </Text>

            <Text c="#888d94" size={11} lh={1}>Promo codes</Text>
            <Text c="#888d94" size={14} fw={700} mt={12} lh={1}>5</Text>
          </Flex>

          <Flex justify="center" align="center" direction="column" p={10} w="100%" span={4} bg="#e7f2ff" style={{ borderRadius: "10px" }}>
            <Text c="#ebab00">
              <IconStarFilled></IconStarFilled>
            </Text>

            <Text c="#888d94" size={11} lh={1}>Reviews</Text>
            <Text c="#888d94" size={14} fw={700} mt={12} lh={1}>4.5k</Text>
          </Flex>
        </Flex>
        {/* statics info section end */}

        {/* Link section start */}
        <Box mt={32}>
          <Flex align="center" mb={15} gap={25}>
            <Text c="#979797">
              <IconStarFilled></IconStarFilled>
            </Text>

            <Text fw={500} size={16} lh={1} mb={8}>My Review</Text>
          </Flex>

          <Flex align="center" mb={15} gap={25}>
            <Text c="#979797">
              <IconTruckDelivery></IconTruckDelivery>
            </Text>

            <Text fw={500} size={16} lh={1} mb={8}>Shipment Address</Text>
          </Flex>

          <Flex align="center" gap={25} c="#ff393f">
            <Text>
              <IconLogout></IconLogout>
            </Text>

            <Text fw={500} size={16} lh={1} mb={8}>Logout</Text>
          </Flex>
        </Box>
        {/* Link section end */}
      </Box>
      {/* main section end */}

      {/* bottom nav start */}
      <Flex align="center" w='335px' ml="auto" mr="auto" bg='#fff' py={7} px={7} justify="space-between" pos="absolute" bottom={25} left={0} right={0} style={{ borderRadius: '100vmax', boxShadow: '#64646f33 0px -5px 29px 0px' }}>
        <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '100vmax' }} bg='#eaf2ff' c='#4385f4'>
          <IconHome style={{ maxWidth: "20px" }}></IconHome>
        </Flex>
        <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '50%' }} c='#cacaca'>
          <IconMessage style={{ maxWidth: "20px" }}></IconMessage>
        </Flex>
        <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '50%' }} c='#cacaca'>
          <IconSearch style={{ maxWidth: "20px" }}></IconSearch>
        </Flex>
        <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '50%' }} c='#cacaca'>
          <IconShoppingCart style={{ maxWidth: "20px" }}></IconShoppingCart>
        </Flex>
        <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '50%' }} c='#cacaca'>
          <IconUserCircle style={{ maxWidth: "20px" }}></IconUserCircle>
        </Flex>
      </Flex>
      {/* bottom nav end */}
    </Box>
  );
};

export default Profile;
