import React from "react";
import { Box, Flex, Text } from "@mantine/core";
import Home from "./Home";
import Home2 from "./Home2";
import ProductDetails from "./ProductDetails";
import Cart from "./Cart";
import Checkout from "./Checkout";
import Profile from "./Profile";
import Sidebar from "./Sidebar";

const ThirdRow = () => {
  return (
    <>
      <Flex px={25} align="center" mb={80}>

        {/* Sidebar Template Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#fff">Sidebar Template</Text>
          <Box bg="#f7f6f4" m={10} w={375} h={760} style={{ overflow: "hidden", borderRadius: "30px" }}>
            <Sidebar />
          </Box>
        </Box>
        {/* Sidebar Template End */}

        {/* Home Template Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#fff">Home Template</Text>
          <Box bg="#f7f6f4" m={10} w={375} h={760} style={{ overflow: "hidden", borderRadius: "30px" }}>
            <Home />
          </Box>
        </Box>
        {/* Home Template End */}

        {/* Home 2nd Template Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#fff">Home 2nd Template</Text>
          <Box bg="#f7f6f4" m={10} w={375} h={760} style={{ overflow: "hidden", borderRadius: "30px" }}>
            <Home2 />
          </Box>
        </Box>
        {/* Home 2nd Template End */}

        {/* Product Details Template Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#fff">Product Details Template</Text>
          <Box bg="#f7f6f4" m={10} w={375} h={760} style={{ overflow: "hidden", borderRadius: "30px" }}>
            <ProductDetails />
          </Box>
        </Box>
        {/* Product Details Template End */}

        {/* Cart Template Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#fff">Cart Template</Text>
          <Box bg="#f7f6f4" m={10} w={375} h={760} style={{ overflow: "hidden", borderRadius: "30px" }}>
            <Cart />
          </Box>
        </Box>
        {/* Cart Template End */}

        {/* Checkout Template Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#fff">Checkout Template</Text>
          <Box bg="#f7f6f4" m={10} w={375} h={760} style={{ overflow: "hidden", borderRadius: "30px" }}>
            <Checkout />
          </Box>
        </Box>
        {/* Checkout Template End */}

        {/* Profile Template Start */}
        <Box>
          <Text ta="center" fw={600} fz={20} c="#fff">Profile Template</Text>
          <Box bg="#f7f6f4" m={10} w={375} h={760} style={{ overflow: "hidden", borderRadius: "30px" }}>
            <Profile />
          </Box>
        </Box>
        {/* Profile Template End */}
      </Flex>
    </>
  );
};

export default ThirdRow;
