import { ActionIcon, Box, Card, Flex, Image, Text, Title } from "@mantine/core";
import {
  IconChevronDown,
  IconChevronLeft,
  IconDots,
  IconMenu2,
  IconMinus,
  IconPlus,
} from "@tabler/icons-react";
import React from "react";
const CheckOut = () => {
  return (
    <Box p={24} pos="relative" h={760}>
      {/* Nav start */}
      <Flex align="center" justify="space-between" mb={30}>
        <ActionIcon
          variant="filled"
          color="#fffff"
          size={40}
          style={{ borderRadius: "100%", border: "1px solid #EDEDED" }}
        >
          <IconChevronLeft
            style={{ width: "30px", height: "30px" }}
            stroke={1.5}
            color="#F13658"
          />
        </ActionIcon>
        <Text fz={16} fw={600} c={"#1B2028"}>
          Checkout
        </Text>
        <ActionIcon
          variant="filled"
          color="#ffffff"
          size={40}
          style={{ borderRadius: "100%", border: "1px solid #EDEDED" }}
        >
          <IconMenu2
            style={{ width: "30px", height: "30px" }}
            color="#F13658"
            stroke={1.5}
          />
        </ActionIcon>
      </Flex>
      {/* Nav End */}
      {/* cart item Start */}
      <Box>
        <Flex align="center" justify="space-between">
          <Image
            src="https://images.pexels.com/photos/298863/pexels-photo-298863.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt=""
            w={70}
            h={75}
            radius={"12px"}
          />
          <Box>
            <Title order={6} fz={14} fw={600} c={"#1B2028"}>
              Modern Light dclothes
            </Title>
            <Text fz={10} c="#A4AAAD" fw={400} mt={-2} mb={10}>
              {" "}
              Dress{" "}
            </Text>
            <Text fz={13} fw={600}>
              $233.5
            </Text>
          </Box>
          <Flex direction="column" gap={10}>
            <Flex align="center" justify="flex-end" gap={2}>
              <IconDots size={25} color="#F13658" />
            </Flex>
            <Flex
              align="center"
              justify="flex-end"
              gap={10}
              bg="#F13658"
              w={80}
              px={5}
              py={3}
              style={{ borderRadius: "8px" }}
            >
              <IconMinus size={18} color="#ffffff" />
              <Text fz={13} fw={600} c="#ffffff">
                4
              </Text>
              <IconPlus size={18} color="#ffffff" />
            </Flex>
          </Flex>
        </Flex>

        <Box h={1} bg={"#EDEDED"} my={20}></Box>

        <Flex align="center" justify="space-between">
          <Image
            src="https://images.pexels.com/photos/298863/pexels-photo-298863.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt=""
            w={70}
            h={75}
            radius={"12px"}
          />
          <Box>
            <Title order={6} fz={14} fw={600} c={"#1B2028"}>
              Modern Light dclothes
            </Title>
            <Text fz={10} c="#A4AAAD" fw={400} mt={-2} mb={10}>
              {" "}
              Dress{" "}
            </Text>
            <Text fz={13} fw={600}>
              $233.5
            </Text>
          </Box>
          <Flex direction="column" gap={10}>
            <Flex align="center" justify="flex-end" gap={2}>
              <IconDots size={25} color="#F13658" />
            </Flex>
            <Flex
              align="center"
              justify="flex-end"
              gap={10}
              bg="#F13658"
              w={80}
              px={5}
              py={3}
              style={{ borderRadius: "8px" }}
            >
              <IconMinus size={18} color="#ffffff" />
              <Text fz={13} fw={600} c="#ffffff">
                4
              </Text>
              <IconPlus size={18} color="#ffffff" />
            </Flex>
          </Flex>
        </Flex>

        <Box h={1} bg={"#EDEDED"} my={20}></Box>

        <Flex align="center" justify="space-between">
          <Image
            src="https://images.pexels.com/photos/298863/pexels-photo-298863.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt=""
            w={70}
            h={75}
            radius={"12px"}
          />
          <Box>
            <Title order={6} fz={14} fw={600} c={"#1B2028"}>
              Modern Light dclothes
            </Title>
            <Text fz={10} c="#A4AAAD" fw={400} mt={-2} mb={10}>
              {" "}
              Dress{" "}
            </Text>
            <Text fz={13} fw={600}>
              $233.5
            </Text>
          </Box>
          <Flex direction="column" gap={10}>
            <Flex align="center" justify="flex-end" gap={2}>
              <IconDots size={25} color="#F13658" />
            </Flex>
            <Flex
              align="center"
              justify="flex-end"
              gap={10}
              bg="#F13658"
              w={80}
              px={5}
              py={3}
              style={{ borderRadius: "8px" }}
            >
              <IconMinus size={18} color="#ffffff" />
              <Text fz={13} fw={600} c="#ffffff">
                4
              </Text>
              <IconPlus size={18} color="#ffffff" />
            </Flex>
          </Flex>
        </Flex>
      </Box>
      {/* cart item end */}
      <Box
        pos="absolute"
        bottom={20}
        bg="#F8F9FA"
        w={375}
        left={0}
        px={25}
        style={{
          borderRadius: "20px",
          boxShadow: "0px -4px 4px 0px #83aad11f",
        }}
      >
        <Box>
          <Text fw={600} my={15}>
            Shipping Information
          </Text>
          <Box style={{ width: "100%" }} h={60} mb={15}>
            <Card
              shadow={15}
              style={{
                borderRadius: "10px",
                backgroundColor: "#ffffff",
              }}
            >
              <Flex align="center" justify="space-between">
                <Box bg="#1864AB" style={{ borderRadius: "5px" }}>
                  <Text c="#ffffff" fw={600} p={5}>
                    VISA
                  </Text>
                </Box>
                <Text fz={14} fw={500} c={"#1B2028"}>
                  {" "}
                  **** **** **** 2143
                </Text>
                <IconChevronDown color="#E64980" />
              </Flex>
            </Card>
          </Box>
          <Flex direction="column" gap={1}>
            <Flex
              align="center"
              justify="space-between"
              my={10}
              style={{ width: "100%" }}
            >
              <Text fz={14} fw={400} c={"#1B2028"}>
                Total(9 items)
              </Text>
              <Text fz={14} fw={600} c={"#1B2028"} style={{ flexBasis: "13%" }}>
                $105
              </Text>
            </Flex>
            <Flex
              align="center"
              justify="space-between"
              style={{ width: "100%" }}
            >
              <Text fz={14} fw={400} c={"#1B2028"}>
                Shipping Fee
              </Text>
              <Text fz={14} fw={600} c={"#1B2028"} style={{ flexBasis: "13%" }}>
                $0.00
              </Text>
            </Flex>
          </Flex>
          <Box h={1} bg={"#EDEDED"} mt={15} mb={5}></Box>
        </Box>
        <Flex justify="center" direction="column" w={327}>
          <Flex
            align="center"
            justify="space-between"
            my={10}
            style={{ width: "100%" }}
          >
            <Text fz={14} fw={400} c={"#1B2028"}>
              Sub Total
            </Text>
            <Text fz={14} fw={600} c={"#1B2028"} style={{ flexBasis: "13%" }}>
              $105
            </Text>
          </Flex>
          <Box bg="#292526" style={{ borderRadius: "50px" }}>
            <Text ta="center" py="15px" c="#ffffff" fw={700}>
              Pay
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default CheckOut;
