import { Box, Flex, Image, Text, Title } from "@mantine/core";
import {
  IconStarFilled,
  IconChevronLeft,
  IconMinus,
  IconPlus,
  IconHeartFilled,
  IconShoppingCart,
} from "@tabler/icons-react";
import React from "react";

export const ProductSingle = () => {
  return (
    <Box p={24} pos="relative" h={760}>
      {/* Product Thumbnail Start */}
      <Box
        w={"100%"}
        h={350}
        style={{ borderRadius: "20px", overflow: "hidden" }}
      >
        <Image src="https://images.pexels.com/photos/1391498/pexels-photo-1391498.jpeg" />
        <Flex
          pos={"absolute"}
          justify={"space-between"}
          align={"center"}
          w={"375px"}
          top={40}
          left={0}
          px={40}
        >
          <Flex
            justify={"center"}
            align={"center"}
            w={40}
            h={40}
            bg={"#ffffff"}
            style={{ borderRadius: "50%" }}
          >
            <IconChevronLeft color="#F87265" size={22} />
          </Flex>
          <Flex
            justify={"center"}
            align={"center"}
            w={40}
            h={40}
            bg={"#ffffff"}
            c={"#F87265"}
            style={{ borderRadius: "50%" }}
          >
            <IconHeartFilled size={22} />
          </Flex>
        </Flex>
      </Box>
      {/* Product Thumbnail End */}

      {/* Product Content Start */}
      <Flex mt={15}>
        <Box>
          <Title c={"#1B2028"} fz={24} fw={600} mb={5}>
            {" "}
            Modern light clothes{" "}
          </Title>
          <Flex justify={"flex-start"} align={"center"} mb={5}>
            <Flex justify={"space-between"} align={"center"}>
              <Box c={"#FCC915"} mr={5}>
                {" "}
                <IconStarFilled size={16} />{" "}
              </Box>
              <Box c={"#FCC915"} mr={5}>
                {" "}
                <IconStarFilled size={16} />{" "}
              </Box>
              <Box c={"#FCC915"} mr={5}>
                {" "}
                <IconStarFilled size={16} />{" "}
              </Box>
              <Box c={"#FCC915"} mr={5}>
                {" "}
                <IconStarFilled size={16} />{" "}
              </Box>
              <Box c={"#FCC915"}>
                {" "}
                <IconStarFilled size={16} />{" "}
              </Box>
            </Flex>
            <Flex justify={"flex-start"} align={"center"} ml={10}>
              <Text c={"#878787"} fz={12} fw={600} mr={5}>
                {" "}
                5.0{" "}
              </Text>
              <Text c={"#F13658"} fz={12} fw={400}>
                {" "}
                (7.932 reviews){" "}
              </Text>
            </Flex>
          </Flex>
          <Text c={"#878787"} fz={12} fw={400} lh={"18px"}>
            Its simple and elegant shape makes it perfect for those of you who
            like you who want minimalist clothes{" "}
            <Text
              c={"#1B2028"}
              fz={14}
              fw={600}
              style={{ display: "inline", cursor: "pointer" }}
            >
              {" "}
              Read More. . .{" "}
            </Text>
          </Text>
        </Box>
        <Flex ml={5}>
          <Flex
            justify={"center"}
            align={"center"}
            h={30}
            w={30}
            style={{ borderRadius: "50%", border: "1px solid #EAEAEA" }}
          >
            <IconMinus color="#F13658" size={18} />
          </Flex>
          <Text c={"#1B2028"} fz={15} fw={700} mx={10} mt={3}>
            {" "}
            1{" "}
          </Text>
          <Flex
            justify={"center"}
            align={"center"}
            h={30}
            w={30}
            style={{ borderRadius: "50%", border: "1px solid #EAEAEA" }}
          >
            <IconPlus color="#F13658" size={18} />
          </Flex>
        </Flex>
      </Flex>
      {/* Product Content End */}

      {/* Section Divider */}
      <Box h={1} bg={"#EDEDED"} my={15}></Box>

      {/* Inventory Section Start */}
      <Flex justify={"space-between"} align={"center"}>
        {/* Size */}
        <Box style={{ flexBasis: "70% " }}>
          <Text mb={10}> Choose Size </Text>
          <Flex justify={"flex-start"} align={"center"}>
            <Flex
              justify={"center"}
              align={"center"}
              w={26}
              h={26}
              mr={10}
              style={{ border: "1px solid #EDEDED", borderRadius: "50%" }}
            >
              <Text c={"#1B2028"} fz={12} fw={400}>
                {" "}
                S{" "}
              </Text>
            </Flex>
            <Flex
              justify={"center"}
              align={"center"}
              w={26}
              h={26}
              mr={10}
              style={{ border: "1px solid #EDEDED", borderRadius: "50%" }}
            >
              <Text c={"#1B2028"} fz={12} fw={400}>
                {" "}
                M{" "}
              </Text>
            </Flex>
            <Flex
              justify={"center"}
              align={"center"}
              w={26}
              h={26}
              mr={10}
              bg={"#F13658"}
              style={{ border: "1px solid #EDEDED", borderRadius: "50%" }}
            >
              <Text c={"#ffffff"} fz={12} fw={400}>
                {" "}
                L{" "}
              </Text>
            </Flex>
            <Flex
              justify={"center"}
              align={"center"}
              w={26}
              h={26}
              style={{ border: "1px solid #EDEDED", borderRadius: "50%" }}
            >
              <Text c={"#1B2028"} fz={12} fw={400}>
                {" "}
                XL{" "}
              </Text>
            </Flex>
          </Flex>
        </Box>
        {/* Color */}
        <Box style={{ flexBasis: "30% " }}>
          <Text mb={10}> Color </Text>
          <Flex justify={"flex-start"} align={"center"}>
            <Box
              w={26}
              h={26}
              bg={"#1b20284d"}
              mr={10}
              style={{ border: "1px solid #EDEDED", borderRadius: "50%" }}
            ></Box>
            <Box
              w={25}
              h={26}
              bg={"#000000"}
              mr={10}
              style={{ border: "2px solid #F13658", borderRadius: "50%" }}
            ></Box>
            <Box
              w={26}
              h={26}
              bg={"#000000"}
              style={{ border: "1px solid #000000", borderRadius: "50%" }}
            ></Box>
          </Flex>
        </Box>
      </Flex>
      {/* Inventory Section End */}

      {/* Action Button Start */}
      <Box pb={10}>
        <Flex
          h={60}
          bg={"#292526"}
          justify={"center"}
          align={"center"}
          mt={25}
          style={{ borderRadius: "40px" }}
        >
          <IconShoppingCart color="#ffffff" size={22} />
          <Text c={"#ffffff"} fz={14} fw={700} ml={10}>
            {" "}
            Add to Cart | $100.99{" "}
          </Text>
          <Text
            c={"#ffffff"}
            fz={10}
            fw={400}
            ml={5}
            style={{ textDecoration: "line-through" }}
          >
            {" "}
            $199.99{" "}
          </Text>
        </Flex>
      </Box>
      {/* Action Button End */}

      {/* Bottom Nav Start */}
      <Box
        pos={"absolute"}
        bottom={10}
        left={"50%"}
        w={135}
        h={5}
        bg={"#101010"}
        style={{ borderRadius: "25px", transform: "translateX(-50%)" }}
      ></Box>
      {/* Bottom Nav End */}
    </Box>
  );
};
