import { Carousel } from "@mantine/carousel";
import {
  Box,
  Divider,
  Flex,
  Image,
  Text,
  useMantineTheme,
} from "@mantine/core";
import {
  IconAccessible,
  IconBrandSafari,
  IconHeadset,
  IconHome,
  IconLayoutGrid,
  IconMenu2,
  IconScooterElectric,
  IconSearch,
  IconShirtSport,
  IconShoe,
  IconShoppingCart,
  IconUserCircle,
  IconHeart,
  IconHeartFilled,
} from "@tabler/icons-react";
import React from "react";
const Products = () => {
  const theme = useMantineTheme();

  return (
    <Box pos="relative" h="100%">
      {/* Nav Start */}
      <Flex px={24} pt={24} align="center" justify="space-between">
        <IconMenu2 size={35} />
        <Flex align="center" justify="space-between">
          <Box mr={20}>
            <IconSearch size={25} />
          </Box>
          <Image
            radius="14px"
            src="https://images.pexels.com/photos/447186/pexels-photo-447186.jpeg"
            h={47}
            w={47}
          />
        </Flex>
      </Flex>
      {/* Nav End */}
      {/* Categories Carousel Start */}
      <Box pl={24} mt={20}>
        <Carousel
          slideSize={75}
          height={80}
          dragFree
          align="start"
          withControls={false}
        >
          <Carousel.Slide>
            <Flex align="center" justify="center" direction="column">
              <Flex
                w={50}
                h={50}
                bg="#F76707"
                justify="center"
                align="center"
                style={{ borderRadius: "12px" }}
              >
                <IconScooterElectric color="#ffffff" />
              </Flex>
              <Text fz={12} mt={3}>
                Electronics
              </Text>
            </Flex>
          </Carousel.Slide>
          <Carousel.Slide>
            <Flex align="center" justify="center" direction="column">
              <Flex
                w={50}
                h={50}
                bg="#F76707"
                opacity={0.4}
                justify="center"
                align="center"
                style={{ borderRadius: "12px" }}
              >
                <IconAccessible color="#ffffff" />
              </Flex>
              <Text fz={12} mt={3}>
                Accessories
              </Text>
            </Flex>
          </Carousel.Slide>
          <Carousel.Slide>
            <Flex align="center" justify="center" direction="column">
              <Flex
                w={50}
                h={50}
                bg="#3B3B3B"
                opacity={0.4}
                justify="center"
                align="center"
                style={{ borderRadius: "12px" }}
              >
                <IconShirtSport color="#ffffff" />
              </Flex>
              <Text fz={12} mt={3}>
                Clothing
              </Text>
            </Flex>
          </Carousel.Slide>
          <Carousel.Slide>
            <Flex align="center" justify="center" direction="column">
              <Flex
                w={50}
                h={50}
                bg="#9C36B5"
                opacity={0.4}
                justify="center"
                align="center"
                style={{ borderRadius: "12px" }}
              >
                <IconShoe color="#ffffff" />
              </Flex>
              <Text fz={12} mt={3}>
                Shoes
              </Text>
            </Flex>
          </Carousel.Slide>
          <Carousel.Slide>
            <Flex align="center" justify="center" direction="column">
              <Flex
                w={50}
                h={50}
                bg="#E64980"
                opacity={0.4}
                justify="center"
                align="center"
                style={{ borderRadius: "12px" }}
              >
                <IconHeadset color="#ffffff" />
              </Flex>
              <Text fz={12} mt={3}>
                Shoes
              </Text>
            </Flex>
          </Carousel.Slide>
        </Carousel>
      </Box>
      {/* Categories Carousel End */}

      {/* DIVIDER */}
      <Divider my={5} />

      {/* FILTER MENU Start */}
      <Flex px={24} align="center" justify="space-between" mt={4} mb={35}>
        <Flex align="center" justify="space-between" gap={8}>
          <IconLayoutGrid />
          <IconMenu2 />
        </Flex>
        <Text>12 item</Text>
      </Flex>
      {/* FILTER MENU End */}

      {/* PRODUCTS Start */}
      <Box px={24}>
        <Box style={{ float: "left" }} mr={20} mb={40}>
          <Box
            p={10}
            h={170}
            w={150}
            pos="relative"
            style={{
              boxShadow: "3px -2px 44px -6px #29242469",
              borderRadius: "15px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Box pos="absolute" top="-20px" ta="center" w={130} h={100}>
              <Image
                src="https://images.unsplash.com/photo-1505740420928-5e560c06d30e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aGVhZHBob25lfGVufDB8fDB8fHww"
                alt="Laptop"
                radius="20px"
                style={{ width: " 100%", height: " 100%" }}
              />

              <Box pos="absolute" top={12} right={12}>
                <IconHeart color="#fff" size={20} />
              </Box>
            </Box>

            <Box>
              <Text fw={600} fz={15}>
                Headphone{" "}
              </Text>
              <Text c="#868E96" fz={12}>
                Electronic
              </Text>
              <Text c="#0B7285" fw={700}>
                $ 23,7
              </Text>
            </Box>
          </Box>
        </Box>

        {/* product single */}
        <Box style={{ float: "left" }} mt={30}>
          <Box
            p={10}
            h={170}
            w={150}
            pos="relative"
            style={{
              boxShadow: "3px -2px 44px -6px #29242469",
              borderRadius: "15px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Box pos="absolute" top="-20px" ta="center" w={130} h={100}>
              <Image
                src="https://cdn.thewirecutter.com/wp-content/media/2023/10/androidphones-2048px-4858.jpg?auto=webp&quality=75&width=1024"
                alt="Laptop"
                radius="20px"
                style={{ width: " 100%", height: " 100%" }}
              />

              <Box pos="absolute" top={12} right={12}>
                <IconHeart color="#fff" size={20} />
              </Box>
            </Box>

            <Box>
              <Text fw={600} fz={15}>
                Mobile phone{" "}
              </Text>
              <Text c="#868E96" fz={12}>
                Electronic
              </Text>
              <Text c="#0B7285" fw={700}>
                $ 23,7
              </Text>
            </Box>
          </Box>
        </Box>

        <Box style={{ float: "left" }} mr={20}>
          <Box
            p={10}
            h={170}
            w={150}
            pos="relative"
            style={{
              boxShadow: "3px -2px 44px -6px #29242469",
              borderRadius: "15px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Box pos="absolute" top="-20px" ta="center" w={130} h={100}>
              <Image
                src="https://media.wired.com/photos/6511aab1189c419c40374c92/1:1/w_1358,h_1358,c_limit/Apple-Watch-Ultra-2-Alt-Gear.jpg"
                alt="Laptop"
                radius="20px"
                style={{ width: " 100%", height: " 100%" }}
              />

              <Box pos="absolute" top={12} right={12}>
                <IconHeart color="#fff" size={20} />
              </Box>
            </Box>

            <Box>
              <Text fw={600} fz={15}>
                Smart Watch
              </Text>
              <Text c="#868E96" fz={12}>
                Electronic
              </Text>
              <Text c="#0B7285" fw={700}>
                $ 23,7
              </Text>
            </Box>
          </Box>
        </Box>

        {/* product single */}
        <Box style={{ float: "left" }} mt={30}>
          <Box
            p={10}
            h={170}
            w={150}
            pos="relative"
            style={{
              boxShadow: "3px -2px 44px -6px #29242469",
              borderRadius: "15px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Box pos="absolute" top="-20px" ta="center" w={130} h={100}>
              <Image
                src="https://media.wired.com/photos/64daab510aff48ce869e0e3b/master/pass/Lenovo-Slim-Pro-9i-Gear.jpg"
                alt="Laptop"
                radius="20px"
                style={{ width: " 100%", height: " 100%" }}
              />
              <Box pos="absolute" c="#f52029" top={12} right={12}>
                <IconHeartFilled size={20} />
              </Box>
            </Box>

            <Box>
              <Text fw={600} fz={15}>
                Laptop
              </Text>
              <Text c="#868E96" fz={12}>
                Electronic
              </Text>
              <Text c="#0B7285" fw={700}>
                $ 23,7
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* PRODUCTS End */}

      {/* Bottom Nav Start */}
      <Box
        pos={"absolute"}
        py={5}
        left={0}
        bottom={0}
        w={"100%"}
        bg="#ffffff"
        style={{
          boxShadow: "#83aad11f 0px -4px 4px 0px",
          borderBottomLeftRadius: "30px",
          borderBottomRightRadius: "30px",
        }}
      >
        <Flex py={14} align="center" justify="space-around">
          <IconHome color="#E03131" />
          <IconBrandSafari color="#868E96" />
          <IconShoppingCart color="#868E96" />
          <IconUserCircle color="#868E96" />
        </Flex>{" "}
      </Box>
      {/* Bottom Nav Start */}
    </Box>
  );
};

export default Products;
