import { Carousel } from "@mantine/carousel";
import { Box, Flex, Image, Text } from "@mantine/core";
import React from "react";

import {
  IconBrandSafari,
  IconCheck,
  IconChevronLeft,
  IconHeart,
  IconHome,
  IconArrowsMaximize,
  IconMinus,
  IconPlus,
  IconShoppingCart,
  IconUserCircle,
  IconX,
} from "@tabler/icons-react";
const ProductDetailsWithModal = () => {
  return (
    <Box pos="relative" style={{ height: "100%" }}>
      <Box h={420} style={{ width: "100%" }} pos="relative">
        {/* Product Thumbnail Carousel Start */}
        <Carousel
          height={420}
          slideSize="100%"
          slideGap={16}
          align="center"
          withControls={false}
        >
          <Carousel.Slide pos="relative">
            <Image
              style={{ width: "100%", height: "100%" }}
              radius="30px"
              src="https://s3-alpha-sig.figma.com/img/d6c0/8a5d/65a26a156f0b866c993caf81637a26fc?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h~~nRAP7ZLRvmqHfXY54iPY-w7hWJdQJ4wk6s7zRgzbhg9l0Y3WH2h4yV5rMfpZ3Sg8qpKvFdqeRBjPU1mCaXJpopU0VLcHxL97t74UYt5h99Lb0viDFJSYQN5SPG~RtyJ60MntlVNyBZYkW9coeGR7AIn-9zlY2NFnvaTo0MUkSTxhe4OFIDVnXcuk6DACmoMFHCQMPGGpk8ySyQxwdGq6vf5xOQgjNpVwc-otzBtMQ4tzZctgZHsPNqDawQGASTZ2NfVOkAiyncKUKNktMy4sWiQJqDEY2UQeWtWKBFZtnzbkRZ3gSBjH6FB53FNYu4V6bpzEV-1lvcFUjTUzYSw__"
              alt="T-shirt"
            />
            <Box pos="absolute" bottom={30} left={20}>
              <Text fz={15} c="#F8F9FA">
                T-shirt
              </Text>
              <Text fw={600} c="#ffffff">
                Outcast Orange
              </Text>
              <Text fw={600} c="#ffffff">
                $ 24.5
              </Text>
            </Box>
          </Carousel.Slide>
          <Carousel.Slide pos="relative">
            <Image
              style={{ width: "100%", height: "100%" }}
              radius="30px"
              src="https://s3-alpha-sig.figma.com/img/d6c0/8a5d/65a26a156f0b866c993caf81637a26fc?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h~~nRAP7ZLRvmqHfXY54iPY-w7hWJdQJ4wk6s7zRgzbhg9l0Y3WH2h4yV5rMfpZ3Sg8qpKvFdqeRBjPU1mCaXJpopU0VLcHxL97t74UYt5h99Lb0viDFJSYQN5SPG~RtyJ60MntlVNyBZYkW9coeGR7AIn-9zlY2NFnvaTo0MUkSTxhe4OFIDVnXcuk6DACmoMFHCQMPGGpk8ySyQxwdGq6vf5xOQgjNpVwc-otzBtMQ4tzZctgZHsPNqDawQGASTZ2NfVOkAiyncKUKNktMy4sWiQJqDEY2UQeWtWKBFZtnzbkRZ3gSBjH6FB53FNYu4V6bpzEV-1lvcFUjTUzYSw__"
              alt="T-shirt"
            />
            <Box pos="absolute" bottom={30} left={20}>
              <Text fz={15} c="#F8F9FA">
                T-shirt
              </Text>
              <Text fw={600} c="#ffffff">
                Forest Orange
              </Text>
              <Text fw={600} c="#ffffff">
                $ 24.5
              </Text>
            </Box>
          </Carousel.Slide>
        </Carousel>
        {/* Product Thumbnail Carousel End */}
        <Flex
          align="center"
          justify="space-between"
          p={15}
          pos="absolute"
          top={20}
          style={{ width: "100%" }}
        >
          <IconChevronLeft color="#ffffff" />
          <Flex align="center" gap={14}>
            <IconArrowsMaximize color="#ffffff" />
            <IconHeart color="#F03E3E" />
          </Flex>
        </Flex>
        <Flex
          align="center"
          justify="center"
          w={50}
          h={50}
          bg="#E03131"
          style={{ borderRadius: "10px" }}
          pos="absolute"
          bottom={-25}
          right={30}
        >
          <IconPlus color="#ffffff" />
        </Flex>
      </Box>
      <Box
        style={{ width: "100%", borderRadius: "40px", overflow: "hidden" }}
        h={530}
        bg="#ffffff"
        pos="absolute"
        bottom={0}
      >
        {/* Modal Top Title start */}
        <Flex align="center" justify="space-between" mt={10} px={25} pt={15}>
          <Text fz={20} fw={600} mb={10}>
            Complete order
          </Text>
          <IconX />
        </Flex>
        {/* Modal Top Title start */}

        {/* Size start */}
        <Text fz={17} px={25} fw={600} mb={10} c="#545454">
          Size
        </Text>
        <Flex px={25} align="center" gap={20}>
          <Flex
            align="center"
            justify="center"
            w={50}
            h={50}
            bg="#dae1e9"
            style={{ borderRadius: "10px" }}
          >
            <Text c="#4c6c93">S</Text>
          </Flex>
          <Flex
            align="center"
            justify="center"
            w={50}
            h={50}
            style={{ borderRadius: "10px", border: "1px solid #D0EBFF" }}
          >
            <Text c="#4c6c93">M</Text>
          </Flex>
          <Flex
            align="center"
            justify="center"
            w={50}
            h={50}
            style={{ borderRadius: "10px", border: "1px solid #D0EBFF" }}
          >
            <Text c="#4c6c93">L</Text>
          </Flex>
          <Flex
            align="center"
            justify="center"
            w={50}
            h={50}
            style={{ borderRadius: "10px", border: "1px solid #D0EBFF" }}
          >
            <Text c="#4c6c93">XL</Text>
          </Flex>
        </Flex>
        {/* Size End */}

        {/* Color start */}
        <Text px={25} fz={17} fw={600} my={10} c="#545454">
          Color
        </Text>
        <Box pl={25} my={10}>
          <Flex align="center" gap={20}>
            <Flex
              align="center"
              justify="center"
              maw={55}
              miw={55}
              mah={55}
              mih={55}
              bg="#FFA94D"
              style={{ borderRadius: "10px" }}
            >
              <IconCheck color="#ffffff" />
            </Flex>
            <Flex
              align="center"
              justify="center"
              maw={55}
              miw={55}
              mah={55}
              mih={55}
              bg="#69DB7C"
              style={{ borderRadius: "10px" }}
            >
              {/* <IconCheck color="#ffffff" /> */}
            </Flex>
            <Flex
              align="center"
              justify="center"
              maw={55}
              miw={55}
              mah={55}
              mih={55}
              bg="#3BC9DB"
              style={{ borderRadius: "10px" }}
            >
              {/* <IconCheck color="#ffffff" /> */}
            </Flex>
            <Flex
              align="center"
              justify="center"
              maw={55}
              miw={55}
              mah={55}
              mih={55}
              bg="#845EF7"
              style={{ borderRadius: "10px" }}
            >
              {/* <IconCheck color="#ffffff" /> */}
            </Flex>
            <Flex
              align="center"
              justify="center"
              maw={55}
              miw={55}
              mah={55}
              mih={55}
              bg="#ee72cb"
              style={{ borderRadius: "10px" }}
            >
              {/* <IconCheck color="#ffffff" /> */}
            </Flex>
          </Flex>
        </Box>
        {/* Color End */}

        {/* Qty start */}
        <Box my={25} px={25}>
          <Flex align="center" justify="space-between">
            <Text fz={17} fw={600} c="#545454">
              Qty
            </Text>
            <Text fz={17} fw={600} c="#4c6c93">
              + Add note
            </Text>
          </Flex>
        </Box>
        {/* Qty End */}

        {/* Quantity Section Start */}
        <Flex align="center" justify="center" my={5} px={25}>
          <Flex align="center" justify="space-between" w={"100%"} mb={5}>
            <Flex
              align="center"
              justify="center"
              w={70}
              h={40}
              style={{ borderRadius: "10px", border: "1px solid #F1F3F5" }}
            >
              <IconMinus color="#4c6c93" />
            </Flex>
            <Text fz={18} fw={600}>
              2
            </Text>
            <Flex
              align="center"
              justify="center"
              w={70}
              h={40}
              style={{ borderRadius: "10px", border: "1px solid #F1F3F5" }}
            >
              <IconPlus color="#4c6c93" />
            </Flex>
          </Flex>
        </Flex>
        {/* Quantity Section End */}

        {/* Add to Cart Btn Start */}
        <Box bg="#F24545" style={{ borderRadius: "20px" }} mx={25}>
          <Text ta="center" mt={15} py="15px" c="#ffffff" fw={700}>
            Add To Cart
          </Text>
        </Box>
        {/* Add to Cart Btn End */}
      </Box>

      {/* Bottom Nav Start */}
      <Box
        pos={"absolute"}
        py={5}
        left={0}
        bottom={0}
        w={"100%"}
        bg="#ffffff"
        style={{
          boxShadow: "#83aad11f 0px -4px 4px 0px",
          borderBottomLeftRadius: "30px",
          borderBottomRightRadius: "30px",
        }}
      >
        <Flex py={14} align="center" justify="space-around">
          <IconHome color="#E03131" />
          <IconBrandSafari color="#868E96" />
          <IconShoppingCart color="#868E96" />
          <IconUserCircle color="#868E96" />
        </Flex>{" "}
      </Box>
      {/* Bottom Nav Start */}
    </Box>
  );
};

export default ProductDetailsWithModal;
