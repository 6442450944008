import { Box, Flex, Image, Text } from "@mantine/core";
import { IconChevronRight, IconQuestionMark, IconLogout2, IconLock, IconStar, IconScan, IconSettings, IconChevronLeft, IconLanguage, IconCrown, IconPencil, IconCamera, IconHome, IconGripVertical, IconClipboardList, IconWallet, IconUser } from "@tabler/icons-react";
import React from "react";

export const Profile = () => {
  return (
    <Box h="100%" pos="relative">
      {/* Top Navigation Start */}
      <Flex justify={'flex-start'} align={'center'} px={20} pt={20} mb={25}>
        <IconChevronLeft color="#27733E" size={22} />
      </Flex>
      {/* Top Navigation End */}

      <Box bg="#f6f6f6" h="100%">
        {/* Basic info page start */}
        <Box ta="center" pos="relative">
          <Box pos="relative" top={-20} maw={95} mx="auto">
            <Image
              mih={95}
              miw={95}
              mah={95}
              maw={95}
              src="https://i.postimg.cc/6678S5G0/pexels-italo-melo-2379005.jpg"
              style={{ border: "4px solid #fff", borderRadius: "50%" }}
              fit="cover"
            />
            <Flex pos="absolute" right={0} bottom={0} justify="center" align="center" bg="#27733E" h={30} w={30} style={{ border: "3px solid #fff", borderRadius: "50%" }}>
              <IconCamera color="#fff" size={18}></IconCamera>
            </Flex>
          </Box>

          <Box pos="relative">
            <Box>
              <Text fw={700} size={20} lh={1} c="#2F2E36">NoorKhan</Text>
              <Text fw={400} size={12} lh={1} c="#27733E" mt="12">Noorkhan@gmail.com</Text>
            </Box>

            <Flex pos="absolute" top={10} right={40} justify="center" align="center" bg="#fff" c="#27733E" w={35} h={35} style={{ boxShadow: "#3c40434d 0px 1px 2px 0px, #3c404326 0px 2px 6px 2px", borderRadius: "50%" }}>
              <IconPencil size={23}></IconPencil>
            </Flex>
          </Box>

          <Box bg="#fff" mt="20" m="12" p="12" style={{ borderRadius: "10px" }}>
            <Box style={{ borderBottom: "1px solid #dfdfdf" }}>
              <Flex justify="space-between" align="center" mb={10}>
                <Flex align="center" gap={15}>
                  <Flex justify="center" align="center" c="#27733E" w={40} h={40} style={{ borderRadius: "12px", border: "1px solid #e3e3e3" }}>
                    <IconCrown></IconCrown>
                  </Flex>
                  <Text fz={14} fw={500} c="#8A8787">Add a Post</Text>
                </Flex>

                <IconChevronRight color="#27733E"></IconChevronRight>
              </Flex>

              <Flex justify="space-between" align="center" mb={10}>
                <Flex align="center" gap={15}>
                  <Flex justify="center" align="center" c="#27733E" w={40} h={40} style={{ borderRadius: "12px", border: "1px solid #e3e3e3" }}>
                    <IconUser></IconUser>
                  </Flex>
                  <Text fz={14} fw={500} c="#8A8787">Account Info</Text>
                </Flex>

                <IconChevronRight color="#27733E"></IconChevronRight>
              </Flex>

              <Flex justify="space-between" align="center" mb={10}>
                <Flex align="center" gap={15}>
                  <Flex justify="center" align="center" c="#27733E" w={40} h={40} style={{ borderRadius: "12px", border: "1px solid #e3e3e3" }}>
                    <IconLanguage></IconLanguage>
                  </Flex>
                  <Text fz={14} fw={500} c="#8A8787">Language</Text>
                </Flex>

                <IconChevronRight color="#27733E"></IconChevronRight>
              </Flex>
            </Box>

            <Box mt="12" style={{ borderBottom: "1px solid #dfdfdf" }}>
              <Flex justify="space-between" align="center" mb={10}>
                <Flex align="center" gap={15}>
                  <Flex justify="center" align="center" c="#27733E" w={40} h={40} style={{ borderRadius: "12px", border: "1px solid #e3e3e3" }}>
                    <IconSettings></IconSettings>
                  </Flex>
                  <Text fz={14} fw={500} c="#8A8787">General Setting</Text>
                </Flex>

                <IconChevronRight color="#27733E"></IconChevronRight>
              </Flex>

              <Flex justify="space-between" align="center" mb={10}>
                <Flex align="center" gap={15}>
                  <Flex justify="center" align="center" c="#27733E" w={40} h={40} style={{ borderRadius: "12px", border: "1px solid #e3e3e3" }}>
                    <IconLock></IconLock>
                  </Flex>
                  <Text fz={14} fw={500} c="#8A8787">Change Password</Text>
                </Flex>

                <IconChevronRight color="#27733E"></IconChevronRight>
              </Flex>

              <Flex justify="space-between" align="center" mb={10}>
                <Flex align="center" gap={15}>
                  <Flex justify="center" align="center" c="#27733E" w={40} h={40} style={{ borderRadius: "12px", border: "1px solid #e3e3e3" }}>
                    <IconScan></IconScan>
                  </Flex>
                  <Text fz={14} fw={500} c="#8A8787">Change Log In PIN</Text>
                </Flex>

                <IconChevronRight color="#27733E"></IconChevronRight>
              </Flex>
            </Box>

            <Box mt="12">
              <Flex justify="space-between" align="center" mb={10}>
                <Flex align="center" gap={15}>
                  <Flex justify="center" align="center" c="#27733E" w={40} h={40} style={{ borderRadius: "12px", border: "1px solid #e3e3e3" }}>
                    <IconQuestionMark></IconQuestionMark>
                  </Flex>
                  <Text fz={14} fw={500} c="#8A8787">FAQs</Text>
                </Flex>

                <IconChevronRight color="#27733E"></IconChevronRight>
              </Flex>

              <Flex justify="space-between" align="center" mb={10}>
                <Flex align="center" gap={15}>
                  <Flex justify="center" align="center" c="#27733E" w={40} h={40} style={{ borderRadius: "12px", border: "1px solid #e3e3e3" }}>
                    <IconStar></IconStar>
                  </Flex>
                  <Text fz={14} fw={500} c="#8A8787">Rate Us</Text>
                </Flex>

                <IconChevronRight color="#27733E"></IconChevronRight>
              </Flex>

              <Flex justify="space-between" align="center">
                <Flex align="center" gap={15}>
                  <Flex justify="center" align="center" c="#27733E" w={40} h={40} style={{ borderRadius: "12px", border: "1px solid #e3e3e3" }}>
                    <IconLogout2></IconLogout2>
                  </Flex>
                  <Text fz={14} fw={500} c="#27733E">LogOut</Text>
                </Flex>
              </Flex>
            </Box>
          </Box>
        </Box>
        {/* Basic info page end */}
      </Box>

      {/* bottom nav start */}
      <Flex align="center" w='100%' ml="auto" mr="auto" bg='#fff' py={15} px={25} justify="space-between" pos="absolute" bottom={0} left={0} right={0} style={{ boxShadow: '#64646f36 0px 3px 8px 1px' }}>
        <Box ta="center" style={{ cursor: 'pointer' }} c='#8b8787'>
          <IconHome size={23}></IconHome>
          <Text size={12} lh={1}>Home</Text>
        </Box>
        <Box ta="center" style={{ cursor: 'pointer' }} c='#8b8787'>
          <IconGripVertical size={23}></IconGripVertical>
          <Text size={12} lh={1}>Explore</Text>
        </Box>

        <Box ta="center" style={{ cursor: 'pointer' }} c='#8b8787'>
          <IconClipboardList size={23}></IconClipboardList>
          <Text size={12} lh={1}>Orders</Text>
        </Box>

        <Box ta="center" style={{ cursor: 'pointer' }} c='#8b8787'>
          <IconWallet size={23}></IconWallet>
          <Text size={12} lh={1}>E Wallet</Text>
        </Box>

        <Box ta="center" style={{ cursor: 'pointer' }} c='#00743d'>
          <IconUser size={23}></IconUser>
          <Text size={12} lh={1}>Profile</Text>
        </Box>
      </Flex>
      {/* bottom nav end */}
    </Box>
  )
}
