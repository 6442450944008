import React from "react";
import { Box, Flex, Text } from "@mantine/core";
import Home from "./Home";
import CategorySingle from "./CategorySingle";
import ProductSingle from "./ProductSingle";
import Cart from "./Cart";
import Checkout from "./Checkout";
import Profile from "./Profile";

const SecondRow = () => {
  return (
    <>
      <Flex px={35} align="center" mb={50}>

        {/* Home Screen Start */}
        <Box mr={20} w={375} p={25} style={{ background: 'linear-gradient(55deg, #010024 75%, #095e79 100%, #00d4ff 100%)', borderRadius: '30px' }} >
          <Home />
        </Box>
        {/* Home Screen End */}

        {/* Category Single Screen Start */}
        <Box mr={20} w={375} p={25} style={{ background: 'linear-gradient(55deg, #010024 75%, #095e79 100%, #00d4ff 100%)', borderRadius: '30px' }} >
          <CategorySingle />
        </Box>
        {/* Category Single Screen End */}

        {/* Product Single Screen Start */}
        <Box pos={'relative'} mr={20} w={375} bg={'#000000'} style={{ borderRadius: "30px" }}>

          {/* Template Title Start */}
          <Text c={"#ffffff"} pos={'absolute'} top={-40} fw={600} fz={20} left={'50%'} w={"100%"} ta={'center'} style={{ transform: "translateX(-50%)" }}>Product Single Template</Text>
          {/* Template Title End */}

          <Box h={355} style={{ background: 'url(https://images.pexels.com/photos/375751/pexels-photo-375751.jpeg)', borderRadius: '30px', backgroundRepeat: "no-repeat", backgroundSize: "cover" }}></Box>
          <ProductSingle />
        </Box>
        {/* Product Single Screen End */}

        {/* Cart Screen Start */}
        <Box mr={20} w={375} px={25} pt={25} bg={'#000'} style={{ borderRadius: '30px' }} >
          <Cart />
        </Box>
        {/* Cart Screen End */}

        {/* Checkout Screen Start */}
        <Box mr={20} w={375} px={25} pt={25} bg={'#000'} style={{ borderRadius: '30px' }} >
          <Checkout />
        </Box>
        {/* Checkout Screen End */}

        {/* Profile Screen Start */}
        <Box mr={20} w={375} py={25} bg={'#000'} style={{ borderRadius: '30px' }} >
          <Profile />
        </Box>
        {/* Profile Screen End */}

      </Flex>
    </>
  );
};

export default SecondRow;
