import { Box, Flex, Image, Text, Title } from "@mantine/core";
import {
  IconSearch,
  IconPlus,
  IconStarFilled,
  IconHeart,
  IconMenu2,
  IconShoppingBag,
  IconHeartFilled,
} from "@tabler/icons-react";
import React from "react";

const Explore = () => {
  return (
    <Box p={8} pos={"relative"}>
      {/* Template Title Start */}
      <Text
        c={"#ffffff"}
        pos={"absolute"}
        top={-65}
        fw={600}
        fz={20}
        left={"50%"}
        w={"100%"}
        ta={"center"}
        style={{ transform: "translateX(-50%)" }}
      >
        Explore Template
      </Text>
      {/* Template Title End */}

      {/* Top Navigation Start */}
      <Flex justify={"space-between"} align={"center"} mb={20}>
        <IconMenu2 size={30} style={{ cursor: "pointer" }} />
        <Flex>
          <Flex
            justify={"center"}
            align={"center"}
            w={45}
            h={45}
            bg={"#ffffff"}
            style={{ borderRadius: "50%" }}
          >
            <IconSearch color="#1b77fe" size={22} />
          </Flex>
          <Flex
            justify={"center"}
            align={"center"}
            w={45}
            h={45}
            bg={"#ffffff"}
            ml={10}
            style={{ borderRadius: "50%" }}
          >
            <IconShoppingBag color="#1b77fe" size={22} />
          </Flex>
        </Flex>
      </Flex>
      {/* Top Navigation End */}

      {/* Section Title Start */}
      <Box>
        <Text c={"#0D6EFD"} fz={12} fw={500}>
          {" "}
          Showing Results{" "}
        </Text>
        <Flex justify={"space-between"} align={"center"} mb={20}>
          <Title size={14} fw={600} c={"#2B2B2B"}>
            {" "}
            All Shoes{" "}
          </Title>
          <Text c={"#0D6EFD"} fz={12} fw={600} style={{ cursor: "pointer" }}>
            Reset
          </Text>
        </Flex>
      </Box>
      {/* Section Title End */}

      {/* Products Section Start */}
      <Flex justify={"space-between"} align={"center"} wrap={"wrap"}>
        <Box
          pos={"relative"}
          p={12}
          bg={"#ffffff"}
          mb={13}
          style={{ borderRadius: "15px", flexBasis: "48%", cursor: "pointer" }}
        >
          <Image
            h={80}
            w={"100%"}
            fit="contain"
            src="https://m.media-amazon.com/images/I/61KOUS5dKGL._AC_UF1000,1000_QL80_.jpg"
          />
          <Box mt={12}>
            <Flex align={"center"} justify={"space-between"}>
              <Text c={"#8C8C8C"} fz={12} fw={500}>
                BEST SELLER
              </Text>
              <Flex align={"center"} justify={"flex-end"}>
                <Box c={"#FCC915"}>
                  <IconStarFilled size={12} />
                </Box>
                <Text c={"#FFCE31"} fz={14} fw={600} ml={3}>
                  5
                </Text>
              </Flex>
            </Flex>
            <Title c={"#222222"} fz={16} fw={600} mb={5}>
              Nike Jordan
            </Title>
            <Text c={"#0D6EFD"} fz={14} fw={600}>
              $302.00
            </Text>
            <Box pos={"absolute"} top={"10px"} left={"10px"}>
              <IconHeart color="#000000" size={22} />
            </Box>
            <Flex
              justify={"center"}
              align={"center"}
              pos={"absolute"}
              bottom={0}
              right={0}
              h={35}
              w={35}
              style={{
                background: "linear-gradient(180deg, #277EFF 0%, #0C65E8 100%)",
                borderTopLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              <IconPlus color="#ffffff" size={22} />
            </Flex>
          </Box>
        </Box>

        <Box
          pos={"relative"}
          p={12}
          bg={"#ffffff"}
          mb={13}
          style={{ borderRadius: "15px", flexBasis: "48%", cursor: "pointer" }}
        >
          <Image
            h={80}
            w={"100%"}
            fit="contain"
            src="https://m.media-amazon.com/images/I/41d723SadlL._SR600%2C315_PIWhiteStrip%2CBottomLeft%2C0%2C35_SCLZZZZZZZ_FMpng_BG255%2C255%2C255.jpg"
          />
          <Box mt={12}>
            <Flex align={"center"} justify={"space-between"}>
              <Text c={"#8C8C8C"} fz={12} fw={500}>
                OFFER 20% OFF
              </Text>
              <Flex align={"center"} justify={"flex-end"}>
                <Box c={"#FCC915"}>
                  <IconStarFilled size={12} />
                </Box>
                <Text c={"#FFCE31"} fz={14} fw={600} ml={3}>
                  5
                </Text>
              </Flex>
            </Flex>
            <Title c={"#222222"} fz={16} fw={600} mb={5}>
              Nike Air Max
            </Title>
            <Text c={"#0D6EFD"} fz={14} fw={600}>
              $752.00
            </Text>
            <Box pos={"absolute"} top={"10px"} left={"10px"} c={"#F87265"}>
              <IconHeartFilled size={22} />
            </Box>
            <Flex
              justify={"center"}
              align={"center"}
              pos={"absolute"}
              bottom={0}
              right={0}
              h={35}
              w={35}
              style={{
                background: "linear-gradient(180deg, #277EFF 0%, #0C65E8 100%)",
                borderTopLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              <IconPlus color="#ffffff" size={22} />
            </Flex>
          </Box>
        </Box>

        <Box
          pos={"relative"}
          p={12}
          bg={"#ffffff"}
          mb={13}
          style={{ borderRadius: "15px", flexBasis: "48%", cursor: "pointer" }}
        >
          <Image
            h={80}
            w={"100%"}
            fit="contain"
            src="https://m.media-amazon.com/images/I/41d723SadlL._SR600%2C315_PIWhiteStrip%2CBottomLeft%2C0%2C35_SCLZZZZZZZ_FMpng_BG255%2C255%2C255.jpg"
          />
          <Box mt={12}>
            <Flex align={"center"} justify={"space-between"}>
              <Text c={"#8C8C8C"} fz={12} fw={500}>
                OFFER 20% OFF
              </Text>
              <Flex align={"center"} justify={"flex-end"}>
                <Box c={"#FCC915"}>
                  <IconStarFilled size={12} />
                </Box>
                <Text c={"#FFCE31"} fz={14} fw={600} ml={3}>
                  5
                </Text>
              </Flex>
            </Flex>
            <Title c={"#222222"} fz={16} fw={600} mb={5}>
              Nike Air Max
            </Title>
            <Text c={"#0D6EFD"} fz={14} fw={600}>
              $752.00
            </Text>
            <Box pos={"absolute"} top={"10px"} left={"10px"}>
              <IconHeart color="#000000" size={22} />
            </Box>
            <Flex
              justify={"center"}
              align={"center"}
              pos={"absolute"}
              bottom={0}
              right={0}
              h={35}
              w={35}
              style={{
                background: "linear-gradient(180deg, #277EFF 0%, #0C65E8 100%)",
                borderTopLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              <IconPlus color="#ffffff" size={22} />
            </Flex>
          </Box>
        </Box>

        <Box
          pos={"relative"}
          p={12}
          bg={"#ffffff"}
          mb={13}
          style={{ borderRadius: "15px", flexBasis: "48%", cursor: "pointer" }}
        >
          <Image
            h={80}
            w={"100%"}
            fit="contain"
            src="https://m.media-amazon.com/images/I/61KOUS5dKGL._AC_UF1000,1000_QL80_.jpg"
          />
          <Box mt={12}>
            <Flex align={"center"} justify={"space-between"}>
              <Text c={"#8C8C8C"} fz={12} fw={500}>
                BEST SELLER
              </Text>
              <Flex align={"center"} justify={"flex-end"}>
                <Box c={"#FCC915"}>
                  <IconStarFilled size={12} />
                </Box>
                <Text c={"#FFCE31"} fz={14} fw={600} ml={3}>
                  5
                </Text>
              </Flex>
            </Flex>
            <Title c={"#222222"} fz={16} fw={600} mb={5}>
              Nike Jordan
            </Title>
            <Text c={"#0D6EFD"} fz={14} fw={600}>
              $302.00
            </Text>
            <Box pos={"absolute"} top={"10px"} left={"10px"}>
              <IconHeart color="#000000" size={22} />
            </Box>
            <Flex
              justify={"center"}
              align={"center"}
              pos={"absolute"}
              bottom={0}
              right={0}
              h={35}
              w={35}
              style={{
                background: "linear-gradient(180deg, #277EFF 0%, #0C65E8 100%)",
                borderTopLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              <IconPlus color="#ffffff" size={22} />
            </Flex>
          </Box>
        </Box>

        <Box
          pos={"relative"}
          p={12}
          bg={"#ffffff"}
          mb={13}
          style={{ borderRadius: "15px", flexBasis: "48%", cursor: "pointer" }}
        >
          <Image
            h={80}
            w={"100%"}
            fit="contain"
            src="https://m.media-amazon.com/images/I/61KOUS5dKGL._AC_UF1000,1000_QL80_.jpg"
          />
          <Box mt={12}>
            <Flex align={"center"} justify={"space-between"}>
              <Text c={"#8C8C8C"} fz={12} fw={500}>
                BEST SELLER
              </Text>
              <Flex align={"center"} justify={"flex-end"}>
                <Box c={"#FCC915"}>
                  <IconStarFilled size={12} />
                </Box>
                <Text c={"#FFCE31"} fz={14} fw={600} ml={3}>
                  5
                </Text>
              </Flex>
            </Flex>
            <Title c={"#222222"} fz={16} fw={600} mb={5}>
              Nike Jordan
            </Title>
            <Text c={"#0D6EFD"} fz={14} fw={600}>
              $302.00
            </Text>
            <Box pos={"absolute"} top={"10px"} left={"10px"}>
              <IconHeart color="#000000" size={22} />
            </Box>
            <Flex
              justify={"center"}
              align={"center"}
              pos={"absolute"}
              bottom={0}
              right={0}
              h={35}
              w={35}
              style={{
                background: "linear-gradient(180deg, #277EFF 0%, #0C65E8 100%)",
                borderTopLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              <IconPlus color="#ffffff" size={22} />
            </Flex>
          </Box>
        </Box>

        <Box
          pos={"relative"}
          p={12}
          bg={"#ffffff"}
          mb={13}
          style={{ borderRadius: "15px", flexBasis: "48%", cursor: "pointer" }}
        >
          <Image
            h={80}
            w={"100%"}
            fit="contain"
            src="https://m.media-amazon.com/images/I/41d723SadlL._SR600%2C315_PIWhiteStrip%2CBottomLeft%2C0%2C35_SCLZZZZZZZ_FMpng_BG255%2C255%2C255.jpg"
          />
          <Box mt={12}>
            <Flex align={"center"} justify={"space-between"}>
              <Text c={"#8C8C8C"} fz={12} fw={500}>
                OFFER 20% OFF
              </Text>
              <Flex align={"center"} justify={"flex-end"}>
                <Box c={"#FCC915"}>
                  <IconStarFilled size={12} />
                </Box>
                <Text c={"#FFCE31"} fz={14} fw={600} ml={3}>
                  5
                </Text>
              </Flex>
            </Flex>
            <Title c={"#222222"} fz={16} fw={600} mb={5}>
              Nike Air Max
            </Title>
            <Text c={"#0D6EFD"} fz={14} fw={600}>
              $752.00
            </Text>
            <Box pos={"absolute"} top={"10px"} left={"10px"} c={"#F87265"}>
              <IconHeartFilled size={22} />
            </Box>
            <Flex
              justify={"center"}
              align={"center"}
              pos={"absolute"}
              bottom={0}
              right={0}
              h={35}
              w={35}
              style={{
                background: "linear-gradient(180deg, #277EFF 0%, #0C65E8 100%)",
                borderTopLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              <IconPlus color="#ffffff" size={22} />
            </Flex>
          </Box>
        </Box>
      </Flex>
      {/* Products Section End */}
    </Box>
  );
};

export default Explore;
