import { Box, Flex, Image, Text, Title } from "@mantine/core";
import { IconStarFilled, IconShoppingBag, IconChevronLeft, IconChevronRight, IconHeartFilled } from "@tabler/icons-react";
import React from "react";

const ProductSingle = () => {
  return (
    <Box p={8} pos={'relative'}>

        {/* Template Title Start */}
        <Text c={"#ffffff"} pos={'absolute'} top={-65} fw={600} fz={20} left={'50%'} w={"100%"} ta={'center'} style={{ transform: "translateX(-50%)" }}>Product Single Template</Text>
        {/* Template Title End */}

        {/* Top Navigation Start */}
        <Flex justify={'space-between'} align={'center'} mb={20}>
            <Flex justify={'center'} align={'center'} w={45} h={45} bg={"#ffffff"} style={{ borderRadius: "50%" }}>
                <IconChevronLeft color="#1b77fe" size={22} />
            </Flex>
            <Text c={'#2B2B2B'} fz={16} fw={600}> Product </Text>
            <Flex justify={'center'} align={'center'} w={45} h={45} bg={"#ffffff"} ml={10} style={{ borderRadius: "50%" }}>
                <IconShoppingBag color="#1b77fe" size={22} />
            </Flex>
        </Flex>
        {/* Top Navigation End */}

        {/* Product Heading Start */}
        <Box mt={25}>
            <Title c={'#000000'} fz={20} fw={600} mb={8}> Nike Air Max 270 Essential </Title>
            <Flex justify={'flex-start'} align={'center'} mb={0}>
                <Box c={'#FCC915'} mr={5}> <IconStarFilled size={22} /> </Box>
                <Box c={'#FCC915'} mr={5}> <IconStarFilled size={22} /> </Box>
                <Box c={'#FCC915'} mr={5}> <IconStarFilled size={22} /> </Box>
                <Box c={'#FCC915'} mr={5}> <IconStarFilled size={22} /> </Box>
                <Box c={'#FCC915'} mr={5}> <IconStarFilled size={22} /> </Box>
            </Flex>
            <Text c={'#707B81'} fz={16} fw={500}> Men's Shoes </Text>
            <Text c={'#0D6EFD'} fz={20} fw={600}> $179.39 </Text>
        </Box>
        {/* Product Heading End */}

        {/* Product Thumbnail Start */}
        <Box pos={'relative'} pt={250} mb={-15
        }>
            <Image src={"shoe2.png"} pos={'absolute'} top={30} left={'50%'} w={250} style={{ transform: "rotate(20deg) translateX(-50%)", zIndex: "9" }} />
            <Box pos={'absolute'} w={320} h={65} top={130} left={"50%"} bg={'#ffffff'} style={{ borderRadius: "50%", border: "3px solid #0d6efd", borderLeftWidth: "1px", borderRightWidth: "1px", borderTopWidth: "0", transform: "translateX(-50%)" }}></Box>
            <Flex justify={'space-between'} align={'center'} pos={'absolute'} top={184} left={'50%'} w={40} h={20} bg={'#0d6efd'} style={{ borderRadius: "10px", transform: "translateX(-50%)", zIndex: "99", cursor: "pointer" }}>
                <IconChevronLeft color="#ffffff" size={16} />
                <IconChevronRight color="#ffffff" size={16} />
            </Flex>
        </Box>
        {/* Product Thumbnail End */}

        {/* Product Gallery Start */}
        <Flex justify={'space-between'} align={'center'}>
            <Flex w={55} h={55} bg={'#ffffff'} style={{ border: "3px solid #0D6EFD", borderRadius: "20px", overflow: "hidden", boxShadow: "0px 4px 4px #00000005" }}>
                <Image src={"shoe2.png"} mt={5} style={{ transform: "rotate(20deg)" }} />
            </Flex>
            <Flex w={55} h={55} bg={'#ffffff'} style={{ borderRadius: "20px", overflow: "hidden", boxShadow: "0px 4px 4px #00000005" }}>
                <Image src={"shoe2.png"} mt={5} style={{ transform: "rotate(20deg)" }} />
            </Flex>
            <Flex w={55} h={55} bg={'#ffffff'} style={{ borderRadius: "20px", overflow: "hidden", boxShadow: "0px 4px 4px #00000005" }}>
                <Image src={"shoe2.png"} mt={5} style={{ transform: "rotate(20deg)" }} />
            </Flex>
            <Flex w={55} h={55} bg={'#ffffff'} style={{ borderRadius: "20px", overflow: "hidden", boxShadow: "0px 4px 4px #00000005" }}>
                <Image src={"shoe2.png"} mt={5} style={{ transform: "rotate(20deg)" }} />
            </Flex>
            <Flex w={55} h={55} bg={'#ffffff'} style={{ borderRadius: "20px", overflow: "hidden", boxShadow: "0px 4px 4px #00000005" }}>
                <Image src={"shoe2.png"} mt={5} style={{ transform: "rotate(20deg)" }} />
            </Flex>
        </Flex>
        {/* Product Gallery End */}

        {/* Description Start */}
        <Box mt={25} mb={90} p={15} style={{ borderRadius: "10px", boxShadow: "0px 0px 8px 0px #0000000d" }}>
            <Text c={'#707B81'} fz={13} lh={'24px'} fw={400}>
                The Max Air 270 unit delivers unrivaled, all-day comfort. The sleek, running-inspired design roots you to everything Nike........ <Text  c={'#0D6EFD'} fz={13} lh={'24px'} fw={400} style={{ display: "inline" }}>Read More</Text>
            </Text>
        </Box>
        {/* Description End */}

        {/* Bottom Nav Start */}
        <Flex justify={'space-between'} align={'center'} pos={'absolute'} left={-20} bottom={-25} w={375} p={25} bg={'#ffffff'} style={{ borderBottomLeftRadius: "30px",  borderBottomRightRadius: "30px", borderTopLeftRadius: "15px", borderTopRightRadius: "15px", boxShadow: "0px -4px 4px 0px #83aad11f" }}>
            <Flex justify={'center'} align={'center'} c={'#f87265'} w={50} h={50} bg={'#F7F7F9'} style={{ borderRadius: "50%", boxShadow: "0px 0px 2px 0px #00000066" }}>
                <IconHeartFilled />
            </Flex>
            <Flex justify={'center'} align={'center'} py={15} style={{ flexBasis: "70%", borderRadius: "12px", background: "linear-gradient(180deg, #3184FF 0%, #0969F5 100%)", cursor: "pointer" }}>
                <IconShoppingBag color="#ffffff" size={22} />
                <Text c={'#ffffff'} fz={14} fw={600} ml={15}> Add To Cart </Text>
            </Flex>
        </Flex>
        {/* Bottom Nav End */}
        
    </Box>
  );
};

export default ProductSingle;
