import React from "react";
import { Box, Flex, Text } from "@mantine/core";
import Home from "./Home";
import Explore from "./Explore";
import ProductDetails from "./ProductDetails";
import { Cart } from "./Cart";
import { Checkout } from "./Checkout";
import { Profile } from "./Profile";

const SixthRow = () => {
    return (
        <>
            <Flex px={25} align="center" mb={50}>

                {/* Home Template Start */}
                <Box>
                    <Text ta="center" fw={600} fz={20} c="#fff">Home Template</Text>
                    <Box bg="#ffffff" m={10} w={375} h={810} style={{ overflow: "hidden" }}>
                        <Home />
                    </Box>
                </Box>
                {/* Home Template End */}

                {/* Explore Template Start */}
                <Box>
                    <Text ta="center" fw={600} fz={20} c="#fff">Explore Template</Text>
                    <Box bg="#ffffff" m={10} w={375} h={810} style={{ overflow: "hidden" }}>
                        <Explore />
                    </Box>
                </Box>
                {/* Explore Template End */}

                {/* Product Single Template Start */}
                <Box>
                    <Text ta="center" fw={600} fz={20} c="#fff">Product Single Template</Text>
                    <Box bg="#ffffff" m={10} w={375} h={810} style={{ overflow: "hidden" }}>
                        <ProductDetails />
                    </Box>
                </Box>
                {/* Product Single Template End */}

                {/* Cart Template Start */}
                <Box>
                    <Text ta="center" fw={600} fz={20} c="#fff">Cart Template</Text>
                    <Box bg="#ffffff" m={10} w={375} style={{ overflow: "hidden" }}>
                        <Cart />
                    </Box>
                </Box>
                {/* Cart Template End */}

                {/* Checkout Template Start */}
                <Box>
                    <Text ta="center" fw={600} fz={20} c="#fff">Checkout Template</Text>
                    <Box bg="#ffffff" m={10} w={375} h={810} style={{ overflow: "hidden" }}>
                        <Checkout />
                    </Box>
                </Box>
                {/* Checkout Template End */}

                {/* Profile Template Start */}
                <Box>
                    <Text ta="center" fw={600} fz={20} c="#fff">Profile Template</Text>
                    <Box bg="#ffffff" m={10} w={375} h={810} style={{ overflow: "hidden" }}>
                        <Profile />
                    </Box>
                </Box>
                {/* Profile Template End */}

            </Flex>
        </>
    );
};

export default SixthRow;
