import { Box, Button, Flex, Image, Text, Title } from "@mantine/core";
import {
  IconChevronLeft,
  IconMail,
  IconEdit,
  IconChevronUp,
  IconMapPinFilled,
  IconPhone,
} from "@tabler/icons-react";
import React from "react";

const Checkout = () => {
  return (
    <Box p={8} pos={"relative"}>
      {/* Template Title Start */}
      <Text
        c={"#ffffff"}
        pos={"absolute"}
        top={-65}
        fw={600}
        fz={20}
        left={"50%"}
        w={"100%"}
        ta={"center"}
        style={{ transform: "translateX(-50%)" }}
      >
        Checkout Template
      </Text>
      {/* Template Title End */}

      {/* Top Navigation Start */}
      <Flex justify={"flex-start"} align={"center"} mb={10}>
        <Flex
          justify={"center"}
          align={"center"}
          w={45}
          h={45}
          bg={"#ffffff"}
          style={{ borderRadius: "50%" }}
        >
          <IconChevronLeft color="#1b77fe" size={22} />
        </Flex>
        <Text c={"#2B2B2B"} fz={16} fw={600} ml={80}>
          {" "}
          Checkout{" "}
        </Text>
      </Flex>
      {/* Top Navigation End */}

      {/* Contact Info Start */}
      <Box bg={"#ffffff"} p={15} mb={225} style={{ borderRadius: "15px" }}>
        <Title c={"#1A2530"} fz={14} fw={500} mb={10}>
          {" "}
          Contact Information{" "}
        </Title>

        {/* Email */}
        <Flex justify={"space-between"} align={"center"} mb={10}>
          <Flex justify={"flex-start"} align={"center"}>
            {/* Icon Box */}
            <Flex
              justify={"center"}
              align={"center"}
              bg={"#F8F9FA"}
              w={42}
              h={42}
              mr={12}
              style={{ borderRadius: "12px" }}
            >
              <IconMail color="#0D6EFD" size={22} />
            </Flex>
            {/* Email box */}
            <Box>
              <Text c={"#1A2530"} fz={14} fw={500}>
                {" "}
                sample@gmail.com{" "}
              </Text>
              <Text c={"#707B81"} fz={12} fw={500}>
                {" "}
                Email{" "}
              </Text>
            </Box>
          </Flex>
          {/* Edit Icon */}
          <IconEdit color="#2C80FD" size={22} />
        </Flex>

        {/* Phone */}
        <Flex justify={"space-between"} align={"center"}>
          <Flex justify={"flex-start"} align={"center"}>
            {/* Icon Box */}
            <Flex
              justify={"center"}
              align={"center"}
              bg={"#F8F9FA"}
              w={42}
              h={42}
              mr={12}
              style={{ borderRadius: "12px" }}
            >
              <IconPhone color="#0D6EFD" size={22} />
            </Flex>
            {/* Text box */}
            <Box>
              <Text c={"#1A2530"} fz={14} fw={500}>
                {" "}
                +234-811-732-5298{" "}
              </Text>
              <Text c={"#707B81"} fz={12} fw={500}>
                {" "}
                Phone{" "}
              </Text>
            </Box>
          </Flex>
          {/* Edit Icon */}
          <IconEdit color="#2C80FD" size={22} />
        </Flex>

        {/* Address Box */}
        <Box mb={10}>
          <Title c={"#1A2530"} fz={14} fw={500} mt={10} mb={10}>
            {" "}
            Address{" "}
          </Title>
          <Flex justify={"space-between"} align={"center"}>
            <Text c={"#707B81"} fz={12} fw={500}>
              {" "}
              1082 Airport Road, Bangladesh{" "}
            </Text>
            <IconChevronUp size={22} color="#0D6EFD" />
          </Flex>
        </Box>

        {/* Map */}
        <Box
          pos={"relative"}
          h={100}
          style={{ borderRadius: "15px", overflow: "hidden" }}
        >
          <img src="location.png" alt="Demo" style={{}} />
          <Box
            pos={"absolute"}
            w={"100%"}
            h={"100%"}
            bg={"#00000054"}
            top={"50%"}
            left={"50%"}
            ta={"center"}
            style={{ zIndex: "999", transform: "translate(-50%, -50%)" }}
          >
            <Text c={"#ffffff"} fz={20} fw={700} mt={15}>
              {" "}
              View Map{" "}
            </Text>
            <Flex
              pos={"absolute"}
              left={"50%"}
              justify={"center"}
              align={"center"}
              c={"#ffffff"}
              w={35}
              h={35}
              bg={"#0D6EFD"}
              style={{
                borderRadius: "50%",
                boxShadow: "0px 10px 24px 0px #5b9ee180",
                transform: "translateX(-50%)",
              }}
            >
              <IconMapPinFilled size={20} />
            </Flex>
          </Box>
        </Box>

        {/* Payment Box */}
        <Box>
          <Title c={"#1A2530"} fz={14} fw={500} mt={15} mb={15}>
            {" "}
            Payment Method{" "}
          </Title>
          <Flex justify={"space-between"} align={"center"}>
            <Flex>
              <Flex
                justify={"center"}
                align={"center"}
                h={40}
                w={40}
                bg={"#F8F9FA"}
                p={5}
                mr={12}
                style={{ borderRadius: "12px" }}
              >
                <Image
                  src={
                    "https://seeklogo.com/images/D/dutch-bangla-bank-ltd-logo-96BD5A58D8-seeklogo.com.png"
                  }
                />
              </Flex>
              <Box>
                <Title c={"#1A2530"} fz={14} fw={500} mb={5}>
                  {" "}
                  Debit Card{" "}
                </Title>
                <Text c={"#707B81"} fz={12} fw={500}>
                  {" "}
                  **** **** 0696 4629{" "}
                </Text>
              </Box>
            </Flex>
            <IconChevronUp size={22} color="#0D6EFD" />
          </Flex>
        </Box>
      </Box>
      {/* Contact Info End */}

      {/* Checkout Table Start */}
      <Box
        bg={"#ffffff"}
        pos={"absolute"}
        w={"375px"}
        left={-20}
        bottom={-25}
        p={25}
        style={{
          borderBottomLeftRadius: "30px",
          borderBottomRightRadius: "30px",
        }}
      >
        <Flex justify={"space-between"} align={"center"} mb={10}>
          <Text c={"#707B81"} fz={16} fw={500} style={{ flexBasis: "250px" }}>
            {" "}
            Subtotal{" "}
          </Text>
          <Text c={"#1A2530"} fz={16} fw={500} ta={"left"}>
            {" "}
            $753.95{" "}
          </Text>
        </Flex>
        <Flex justify={"space-between"} align={"center"}>
          <Text c={"#707B81"} fz={16} fw={500} style={{ flexBasis: "250px" }}>
            {" "}
            Delivery{" "}
          </Text>
          <Text
            c={"#1A2530"}
            fz={16}
            fw={500}
            ta={"left"}
            style={{ textAlign: "left", flexBasis: "58px" }}
          >
            {" "}
            $60.20{" "}
          </Text>
        </Flex>

        <Box my={15} style={{ border: "1px dashed #707B81" }}></Box>

        <Flex justify={"space-between"} align={"center"} mb={30}>
          <Text c={"#2B2B2B"} fz={16} fw={500} style={{ flexBasis: "250px" }}>
            {" "}
            Total Cost{" "}
          </Text>
          <Text c={"#0D6EFD"} fz={16} fw={600} ta={"left"}>
            {" "}
            $814.15{" "}
          </Text>
        </Flex>

        <Button
          variant="filled"
          fz={14}
          h={50}
          w={"100%"}
          fw={500}
          style={{
            borderRadius: "15px",
            background: "linear-gradient(180deg, #428EFF 0%, #0463EF 100%)",
            borderWidth: "0px",
          }}
        >
          Checkout
        </Button>
      </Box>
      {/* Checkout Table End */}
    </Box>
  );
};

export default Checkout;
