import {
  Box,
  Flex,
  Image,
  Rating,
  Tabs,
  Text,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";

import {
  IconChevronLeft,
  IconHeart,
  IconArrowsMaximize,
  IconPlus,
} from "@tabler/icons-react";
import React from "react";

const ProductDetails = () => {

  return (
    <Box>
      {/* Product Thumbnail Carousel Start */}
      <Box h={420} style={{ width: "100%" }} pos="relative">
        <Carousel
          height={420}
          slideSize="100%"
          slideGap={16}
          align="center"
          withControls={false}
        >
          <Carousel.Slide pos="relative">
            <Image
              style={{ width: "100%", height: "100%" }}
              radius="30px"
              src="https://s3-alpha-sig.figma.com/img/d6c0/8a5d/65a26a156f0b866c993caf81637a26fc?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h~~nRAP7ZLRvmqHfXY54iPY-w7hWJdQJ4wk6s7zRgzbhg9l0Y3WH2h4yV5rMfpZ3Sg8qpKvFdqeRBjPU1mCaXJpopU0VLcHxL97t74UYt5h99Lb0viDFJSYQN5SPG~RtyJ60MntlVNyBZYkW9coeGR7AIn-9zlY2NFnvaTo0MUkSTxhe4OFIDVnXcuk6DACmoMFHCQMPGGpk8ySyQxwdGq6vf5xOQgjNpVwc-otzBtMQ4tzZctgZHsPNqDawQGASTZ2NfVOkAiyncKUKNktMy4sWiQJqDEY2UQeWtWKBFZtnzbkRZ3gSBjH6FB53FNYu4V6bpzEV-1lvcFUjTUzYSw__"
              alt="T-shirt"
            />
            <Box pos="absolute" bottom={30} left={20}>
              <Text fz={15} c="#d1d1d1">
                T-shirt
              </Text>
              <Text fw={600} mt={10} size={17} lh={1} c="#ffffff">
                Outcast Orange
              </Text>
              <Text fw={700} mt={10} size={18} lh={1} c="#ffffff">
                $ 24.5
              </Text>
              <Flex mt={25}>
                <Box w={35} h={5} bg={'#ffffff'} style={{ borderRadius: "30px" }}></Box>
                <Box w={20} h={5} ml={8} bg={'#7f8c8d'} style={{ borderRadius: "30px" }}></Box>
                <Box w={20} h={5} ml={8} bg={'#7f8c8d'} style={{ borderRadius: "30px" }}></Box>
              </Flex>
            </Box>
          </Carousel.Slide>
          <Carousel.Slide pos="relative">
            <Image
              style={{ width: "100%", height: "100%" }}
              radius="30px"
              src="https://s3-alpha-sig.figma.com/img/d6c0/8a5d/65a26a156f0b866c993caf81637a26fc?Expires=1708300800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h~~nRAP7ZLRvmqHfXY54iPY-w7hWJdQJ4wk6s7zRgzbhg9l0Y3WH2h4yV5rMfpZ3Sg8qpKvFdqeRBjPU1mCaXJpopU0VLcHxL97t74UYt5h99Lb0viDFJSYQN5SPG~RtyJ60MntlVNyBZYkW9coeGR7AIn-9zlY2NFnvaTo0MUkSTxhe4OFIDVnXcuk6DACmoMFHCQMPGGpk8ySyQxwdGq6vf5xOQgjNpVwc-otzBtMQ4tzZctgZHsPNqDawQGASTZ2NfVOkAiyncKUKNktMy4sWiQJqDEY2UQeWtWKBFZtnzbkRZ3gSBjH6FB53FNYu4V6bpzEV-1lvcFUjTUzYSw__"
              alt="T-shirt"
            />
            <Box pos="absolute" bottom={30} left={20}>
              <Text fz={15} c="#F8F9FA">
                T-shirt
              </Text>
              <Text fw={600} c="#ffffff">
                Forest Orange
              </Text>
              <Text fw={600} c="#ffffff">
                $ 24.5
              </Text>
              <Flex mt={25}>
                <Box w={35} h={5} bg={'#ffffff'} style={{ borderRadius: "30px" }}></Box>
                <Box w={20} h={5} ml={8} bg={'#7f8c8d'} style={{ borderRadius: "30px" }}></Box>
                <Box w={20} h={5} ml={8} bg={'#7f8c8d'} style={{ borderRadius: "30px" }}></Box>
              </Flex>
            </Box>
          </Carousel.Slide>
        </Carousel>

        <Flex
          align="center"
          justify="space-between"
          p={15}
          pos="absolute"
          top={20}
          style={{ width: "100%" }}
        >
          <IconChevronLeft color="#ffffff" />
          <Flex align="center" gap={14}>
            <IconArrowsMaximize color="#ffffff" />
            <IconHeart color="#E03131" />
          </Flex>
        </Flex>
        <Flex
          align="center"
          justify="center"
          w={50}
          h={50}
          bg="#E03131"
          style={{ borderRadius: "10px" }}
          pos="absolute"
          bottom={-25}
          right={30}
        >
          <IconPlus color="#ffffff" />
        </Flex>
      </Box>
      {/* Product Thumbnail Carousel End */}

      {/* Product Content Tab Start */}
      <Box pt={30} px={10}>
        <Tabs defaultValue="description">
          <Tabs.List fw={600}>
            <Tabs.Tab style={{ borderColor: "#000" }} value="description" fz={16}>Description</Tabs.Tab>
            <Tabs.Tab value="brand" style={{ opacity: "0.2" }}>About Brand</Tabs.Tab>
            <Tabs.Tab value="delivery" style={{ opacity: "0.2" }}>Delivery Info</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="description" pt={5}>
            <Text fz={15} py={10} c="#6e6e6e">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore.
            </Text>
          </Tabs.Panel>
        </Tabs>
        <Flex align="flex-end" justify="space-between" p={10}>
          <Box>
            <Text fw={600}>Review</Text>
            <Flex align={'center'} mt={10}>
              <Text c={'#000000'} fz={20} fw={600} mr={15}>4.0</Text>
              <Rating value={4} readOnly />
            </Flex>
          </Box>
          <Text fw={600} fz={14} c="#4C6C93">
            View more
          </Text>
        </Flex>
        <Box h={5} w={130} bg={'#D9D9D9'} mx={'auto'}></Box>
      </Box>
      {/* Product Content Tab Start */}
    </Box>
  );
};

export default ProductDetails;
