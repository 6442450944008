import { Box, Button, Flex, Image, Text } from "@mantine/core";
import {
  IconChevronLeft,
  IconMinus,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import React from "react";

const Cart = () => {
  return (
    <Box p={8} pos={"relative"}>
      {/* Template Title Start */}
      <Text
        c={"#ffffff"}
        pos={"absolute"}
        top={-65}
        fw={600}
        fz={20}
        left={"50%"}
        w={"100%"}
        ta={"center"}
        style={{ transform: "translateX(-50%)" }}
      >
        Cart Template
      </Text>
      {/* Template Title End */}

      {/* Top Navigation Start */}
      <Flex justify={"flex-start"} align={"center"} mb={20}>
        <Flex
          justify={"center"}
          align={"center"}
          w={45}
          h={45}
          bg={"#ffffff"}
          style={{ borderRadius: "50%" }}
        >
          <IconChevronLeft color="#1b77fe" size={22} />
        </Flex>
        <Text c={"#2B2B2B"} fz={16} fw={600} ml={95}>
          {" "}
          Cart{" "}
        </Text>
      </Flex>
      {/* Top Navigation End */}

      {/* Product Items Start */}
      <Box mb={250}>
        <Text c={"#1A2530"} fz={16} fw={600} mb={10}>
          {" "}
          3 Item{" "}
        </Text>

        <Flex justify={"space-between"} align={"center"} mb={15}>
          {/* blue box */}
          <Flex
            h={105}
            w={60}
            px={15}
            py={10}
            mr={10}
            justify={"space-between"}
            align={"center"}
            style={{
              background: "linear-gradient(180deg, #2D82FF 0%, #0566F5 100%)",
              borderRadius: "10px",
              flexDirection: "column",
            }}
          >
            <IconPlus color="#ffffff" size={22} style={{ cursor: "pointer" }} />
            <Text my={15} c={"#ffffff"} fz={14} fw={400}>
              {" "}
              1{" "}
            </Text>
            <IconMinus
              color="#ffffff"
              size={22}
              style={{ cursor: "pointer" }}
            />
          </Flex>
          {/* white box */}
          <Flex
            h={105}
            w={265}
            justify={"flex-start"}
            align={"center"}
            bg={"#ffffff"}
            p={10}
            style={{ borderRadius: "25px" }}
          >
            <Box
              bg={"#F7F7F9"}
              w={85}
              h={85}
              mr={20}
              style={{ borderRadius: "20px" }}
            >
              <Image src={"shoe2.png"} />
            </Box>
            <Box>
              <Text c={"#1A2530"} fz={15} fw={500}>
                {" "}
                Nike Club Max{" "}
              </Text>
              <Text c={"#0E6EFD"} fz={14} fw={600}>
                {" "}
                $584.95{" "}
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex justify={"space-between"} align={"center"} mb={15}>
          {/* white box */}
          <Flex
            h={105}
            w={265}
            justify={"flex-start"}
            align={"center"}
            bg={"#ffffff"}
            p={10}
            style={{ borderRadius: "25px" }}
          >
            <Box
              bg={"#F7F7F9"}
              w={85}
              h={85}
              mr={20}
              style={{ borderRadius: "20px" }}
            >
              <Image src={"shoe2.png"} />
            </Box>
            <Box>
              <Text c={"#1A2530"} fz={15} fw={500}>
                {" "}
                Nike Air Max 200{" "}
              </Text>
              <Text c={"#0E6EFD"} fz={14} fw={600}>
                {" "}
                $584.95{" "}
              </Text>
            </Box>
          </Flex>
          {/* red box */}
          <Flex
            h={105}
            w={60}
            px={15}
            py={10}
            ml={10}
            justify={"center"}
            align={"center"}
            style={{
              background: "linear-gradient(180deg, #FF3822 0%, #EA1B04 100%)",
              borderRadius: "10px",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <IconTrash color="#ffffff" size={22} />
          </Flex>
        </Flex>

        <Flex justify={"space-between"} align={"center"} mb={15}>
          {/* white box */}
          <Flex
            h={105}
            w={"100%"}
            justify={"flex-start"}
            align={"center"}
            bg={"#ffffff"}
            p={10}
            style={{ borderRadius: "25px" }}
          >
            <Box
              bg={"#F7F7F9"}
              w={85}
              h={85}
              mr={20}
              style={{ borderRadius: "20px" }}
            >
              <Image src={"shoe2.png"} />
            </Box>
            <Box>
              <Text c={"#1A2530"} fz={15} fw={500}>
                {" "}
                Nike Air Max 270 Essential{" "}
              </Text>
              <Text c={"#0E6EFD"} fz={14} fw={600}>
                {" "}
                $74.95{" "}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Box>
      {/* Product Items End */}

      {/* Checkout Table Start */}
      <Box
        bg={"#ffffff"}
        pos={"absolute"}
        w={"375px"}
        left={-20}
        bottom={-25}
        p={25}
        style={{
          borderBottomLeftRadius: "30px",
          borderBottomRightRadius: "30px",
        }}
      >
        <Flex justify={"space-between"} align={"center"} mb={10}>
          <Text c={"#707B81"} fz={16} fw={500} style={{ flexBasis: "250px" }}>
            {" "}
            Subtotal{" "}
          </Text>
          <Text c={"#1A2530"} fz={16} fw={500} ta={"left"}>
            {" "}
            $753.95{" "}
          </Text>
        </Flex>
        <Flex justify={"space-between"} align={"center"}>
          <Text c={"#707B81"} fz={16} fw={500} style={{ flexBasis: "250px" }}>
            {" "}
            Delivery{" "}
          </Text>
          <Text
            c={"#1A2530"}
            fz={16}
            fw={500}
            ta={"left"}
            style={{ textAlign: "left", flexBasis: "58px" }}
          >
            {" "}
            $60.20{" "}
          </Text>
        </Flex>

        <Box my={15} style={{ border: "1px dashed #707B81" }}></Box>

        <Flex justify={"space-between"} align={"center"} mb={30}>
          <Text c={"#2B2B2B"} fz={16} fw={500} style={{ flexBasis: "250px" }}>
            {" "}
            Total Cost{" "}
          </Text>
          <Text c={"#0D6EFD"} fz={16} fw={600} ta={"left"}>
            {" "}
            $814.15{" "}
          </Text>
        </Flex>

        <Button
          variant="filled"
          fz={14}
          h={50}
          w={"100%"}
          fw={500}
          style={{
            borderRadius: "15px",
            background: "linear-gradient(180deg, #428EFF 0%, #0463EF 100%)",
            borderWidth: "0px",
          }}
        >
          Checkout
        </Button>
      </Box>
      {/* Checkout Table End */}
    </Box>
  );
};

export default Cart;
