import {
    Box,
    Flex,
    Image,
    Text,
    ActionIcon,
    Button
} from "@mantine/core";
import {
    IconStarFilled,
    IconChevronLeft,
    IconMinus,
    IconPlus,
    IconTrash,
} from "@tabler/icons-react";
import React from "react";

const Home = () => {
    return (
        <Box h='100%' pos='relative'>
            {/* header start */}
            <Box pos='relative' pt={20} px={20}>
                <ActionIcon pos="absolute" left={20} bg="#ffffff" variant="filled" radius="50px" aria-label="Settings">
                    <IconChevronLeft style={{ width: '70%', height: '70%', color: '#cfcfcf' }} stroke={1.5} />
                </ActionIcon>

                <Text ta="center" size={18} lh={1} fw={700}>Cart</Text>
            </Box>
            {/* header end */}

            {/* main section start */}
            <Box px={20}>
                <Box mt={32} bg="#f7f6f4" p={20} style={{ border: "3px solid #fff", borderRadius: "15px", boxShadow: "#11111a1a 0px 4px 16px, #11111a0d 0px 8px 32px" }}>
                    <Flex align="center" gap={20}>
                        <Flex align="center" justify="center" p={10} bg="#ececea" w={150} h={150} style={{ borderRadius: "15px" }}>
                            <Image
                                maw={90}
                                w="100%"
                                h="auto"
                                src="https://i.postimg.cc/fWvhLRw7/Png-Item-891873.png"
                            />
                        </Flex>

                        <Box>
                            <Text size={18} lh={1} mb={6} fw={500} c="#343a40">Sony WH-1000XM4</Text>
                            <Text size={20} mb={5} lh={1} c="#4a86f4" fw={700}>$ 4.999</Text>
                            <Flex align="center" gap={5}>
                                <Text c="#e6bb66">
                                    <IconStarFilled size={17}></IconStarFilled>
                                </Text>
                                <Text c="#asdfa" size={16} lh={1} m={0} fw={700}>4.9</Text>
                            </Flex>
                        </Box>
                    </Flex>

                    <Flex mt={32} justify="space-between" align="center">
                        <Flex gap={10} justify="center" align="center">
                            <Flex justify="center" align="center" bg="#000" c="#fff" p={2} style={{ borderRadius: "5px", boxShadow: "4px 4px 6px 3px #cdcdcd" }}><IconMinus size={22}></IconMinus></Flex>
                            <Text>1</Text>
                            <Flex justify="center" align="center" bg="#000" c="#fff" p={2} style={{ borderRadius: "5px", boxShadow: "4px 4px 6px 3px #cdcdcd" }}><IconPlus size={22}></IconPlus></Flex>
                        </Flex>

                        <Flex align="center">
                            <Text c="#838383" mt={5}>
                                <IconTrash></IconTrash>
                            </Text>
                            <Button bg="transparent" fw={500} pl={0} ml={5} pr={0} c="#838383">Remove</Button>
                        </Flex>
                    </Flex>
                </Box>
            </Box>
            {/* main section end */}

            {/* footer section start */}
            <Box p={23} mt={32} bg="#fff" style={{ borderTopLeftRadius: "30px", borderTopRightRadius: "30px", boxShadow: '#64646f29 0px -2px 29px 0px' }} pos="absolute" w="100%" bottom={0}>
                <Flex align="center" pb={10} justify="space-between" style={{ borderBottom: "1px dotted #cbcaca" }}>
                    <Text size={14} lh={1}>Subtotal (2 items)</Text>
                    <Text size={14} lh={1} style={{ flexBasis: "17%" }}>$ 4.999</Text>
                </Flex>

                <Flex align="center" pt={10} justify="space-between">
                    <Text>Total</Text>
                    <Text style={{ flexBasis: "17%" }}>$ 4.999</Text>
                </Flex>

                <Button radius="50px" mt={12} w="100%" bg="#4285f4" c="#ffffff">Buy Now</Button>
            </Box>
            {/* footer section end */}
        </Box>
    );
};

export default Home;
