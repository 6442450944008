import { Carousel } from "@mantine/carousel";
import {
    Box,
    Flex,
    Image,
    Text,
    Input,
    ActionIcon,
    Grid
} from "@mantine/core";
import {
    IconMenu2,
    IconSearch,
    IconBellRinging,
    IconHome,
    IconMessage,
    IconShoppingCart,
    IconUserCircle,
} from "@tabler/icons-react";
import React from "react";

const Home2 = () => {
    return (
        <Box h='100%' p={20} pos='relative'>
            {/* header start */}
            <Flex align="center" justify="space-between">
                <IconMenu2></IconMenu2>
                <Flex style={{ borderRadius: '18px' }} align="center" justify="space-between" bg="#ffffff">
                    <Box pr='5' pl='10' pos="relative" top={3}>
                        <IconSearch style={{ color: '#cfcfcf', width: '18px' }}></IconSearch>
                    </Box>
                    <Input variant="unstyled" c="#cfcfcf" style={{ width: '230px' }} h="100%" size={16} lh={1} placeholder="Search Products" />
                </Flex>
                <ActionIcon bg="#ffffff" variant="filled" radius="50%" aria-label="Settings">
                    <IconBellRinging style={{ width: '70%', height: '70%', color: '#cfcfcf' }} stroke={1.5} />
                </ActionIcon>
            </Flex>
            {/* header end */}

            {/* category section start */}
            <Box mt={32}>
                <Carousel
                    // slideSize={80}
                    // height={250}
                    style={{ display: 'flex' }}
                    dragFree
                    align="start"
                    withControls={false}
                >
                    <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
                        <Box>
                            <Text style={{ border: '1px solid #d6e6ff', borderRadius: '100vmax' }} bg="#eaf2ff" c='#4285f4' size={14} lh={1} ta="center" py={5} px={10} tt="capitalize" w="fit-content">Technology</Text>
                        </Box>
                    </Carousel.Slide>
                    <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
                        <Box>
                            <Text style={{ border: '1px solid #e9e9e9', borderRadius: '100vmax' }} bg="#fff" size={14} lh={1} ta="center" py={5} px={10} tt="capitalize" w="fit-content">Fashion</Text>
                        </Box>
                    </Carousel.Slide>
                    <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
                        <Box>
                            <Text style={{ border: '1px solid #e9e9e9', borderRadius: '100vmax' }} bg="#fff" size={14} lh={1} ta="center" py={5} px={10} tt="capitalize" w="fit-content">Sports</Text>
                        </Box>
                    </Carousel.Slide>
                    <Carousel.Slide mr={15} style={{ maxWidth: 'fit-content' }}>
                        <Box>
                            <Text style={{ border: '1px solid #e9e9e9', borderRadius: '100vmax' }} bg="#fff" size={14} lh={1} ta="center" py={5} px={10} tt="capitalize" w="fit-content">Home</Text>
                        </Box>
                    </Carousel.Slide>
                </Carousel>
            </Box>
            {/* category section end */}

            {/* Product Carousel start */}
            <Box mt={32}>
                <Text mb={10} fz="18px" c='#353b41' lh='1' fw={700}>Hot Sales</Text>
                <Grid>
                    <Grid.Col span={6}>
                        <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={10} bg='#eef1f5'>
                            <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text>
                            <Image
                                h='auto'
                                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
                            />
                            <Text c='#353b41' mt={12} tt="capitalize" size={12} lh="18px">macbook air m1</Text>
                            <Text c='#353b41' fw={700} size={14} lh={1}>$ 29,999</Text>
                        </Box>
                    </Grid.Col>

                    <Grid.Col span={6}>
                        <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={10} bg='#eef1f5'>
                            <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text>
                            <Image
                                h='auto'
                                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
                            />
                            <Text c='#353b41' mt={12} tt="capitalize" size={12} lh="18px">macbook air m1</Text>
                            <Text c='#353b41' fw={700} size={14} lh={1}>$ 29,999</Text>
                        </Box>
                    </Grid.Col>

                    <Grid.Col span={6}>
                        <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={10} bg='#eef1f5'>
                            <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text>
                            <Image
                                h='auto'
                                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
                            />
                            <Text c='#353b41' mt={12} tt="capitalize" size={12} lh="18px">macbook air m1</Text>
                            <Text c='#353b41' fw={700} size={14} lh={1}>$ 29,999</Text>
                        </Box>
                    </Grid.Col>

                    <Grid.Col span={6}>
                        <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={10} bg='#eef1f5'>
                            <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text>
                            <Image
                                h='auto'
                                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
                            />
                            <Text c='#353b41' mt={12} tt="capitalize" size={12} lh="18px">macbook air m1</Text>
                            <Text c='#353b41' fw={700} size={14} lh={1}>$ 29,999</Text>
                        </Box>
                    </Grid.Col>

                    <Grid.Col span={6}>
                        <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={10} bg='#eef1f5'>
                            <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text>
                            <Image
                                h='auto'
                                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
                            />
                            <Text c='#353b41' mt={12} tt="capitalize" size={12} lh="18px">macbook air m1</Text>
                            <Text c='#353b41' fw={700} size={14} lh={1}>$ 29,999</Text>
                        </Box>
                    </Grid.Col>

                    <Grid.Col span={6}>
                        <Box style={{ border: '1px solid #e5e5e5', borderRadius: '10px', position: 'relative' }} p={10} bg='#eef1f5'>
                            <Text style={{ borderRadius: '100vmax' }} py={2} px={10} ta="center" bg='#fff' fz="10px" c='#6ab9ff' tt="capitalize" mx={0} mb={10} mt={0} w='fit-content'>free shipping</Text>
                            <Image
                                h='auto'
                                src="https://i.postimg.cc/tR8RSvsM/png-transparent-macbook-pro-13-inch-macbook-air-laptop-macbook-gadget-electronics-laptop-1.png"
                            />
                            <Text c='#353b41' mt={12} tt="capitalize" size={12} lh="18px">macbook air m1</Text>
                            <Text c='#353b41' fw={700} size={14} lh={1}>$ 29,999</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Box>
            {/* Product Carousel end */}
            {/* bottom nav start */}
            <Flex align="center" w='335px' ml="auto" mr="auto" bg='#fff' py={7} px={7} justify="space-between" pos="absolute" bottom={25} left={0} right={0} style={{ borderRadius: '100vmax', boxShadow: '#64646f29 0px -2px 29px 0px' }}>
                <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '100vmax' }} bg='#eaf2ff' c='#4385f4'>
                    <IconHome style={{ maxWidth: "20px" }}></IconHome>
                </Flex>
                <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '50%' }} c='#cacaca'>
                    <IconMessage style={{ maxWidth: "20px" }}></IconMessage>
                </Flex>
                <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '50%' }} c='#cacaca'>
                    <IconSearch style={{ maxWidth: "20px" }}></IconSearch>
                </Flex>
                <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '50%' }} c='#cacaca'>
                    <IconShoppingCart style={{ maxWidth: "20px" }}></IconShoppingCart>
                </Flex>
                <Flex align="center" justify="center" w={40} h={40} style={{ cursor: 'pointer', borderRadius: '50%' }} c='#cacaca'>
                    <IconUserCircle style={{ maxWidth: "20px" }}></IconUserCircle>
                </Flex>
            </Flex>
            {/* bottom nav end */}
        </Box>
    );
};

export default Home2;
