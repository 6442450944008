import { Box, Button, Flex, Image, Text } from "@mantine/core";
import { IconStarFilled, IconChevronLeft, IconHome, IconCompass, IconShoppingCart, IconUserCircle, IconCameraFilled, IconArrowZigZag, IconTags } from "@tabler/icons-react";
import React from "react";

const Profile = () => {
  return (
    <Box py={8} pos={'relative'}>

      {/* Template Title Start */}
      <Text c={"#ffffff"} pos={'absolute'} top={-65} fw={600} fz={20} left={'50%'} w={"100%"} ta={'center'} style={{ transform: "translateX(-50%)" }}>Profile Template</Text>
        {/* Template Title End */}

        {/* Page Title Start */}
        <Flex c={'#ffffff'} mb={25} px={25}>
            <IconChevronLeft />
            <Text fz={24} fw={500} lh={1} ml={107}> Profile </Text>
        </Flex>
        {/* Page Title End */}

        {/* Profile Image Start */}
        <Box mt={100} pos={'relative'} bg={'#181818'} px={40} style={{ border: "2px solid #2F3134", boxShadow: "4px 4px 12px 0px #0000004d", borderRadius: "30px" }}>

          {/* Image */}
          <Box pos={'absolute'} left={'50%'} top={'-48px'} style={{ transform: "translateX(-50%)" }}>
            <Box pos={'relative'}>
              <Image w={95} h={95} src="https://images.pexels.com/photos/3778680/pexels-photo-3778680.jpeg" style={{ borderRadius: "50%" }} />
              <Flex pos={'absolute'} left={'68%'} bottom={0} justify={'center'} align={'center'} h={22} w={22} c={"#fff"} bg={'#006FDC'} style={{ borderRadius: "50%" }} >
                <IconCameraFilled size={13} />
              </Flex>
            </Box>
          </Box>

          {/* Name */}
          <Box ta={'center'} mt={65}>
            <Text c={'#ffffff'} fz={22} fw={600} mb={5}>ALISA</Text>
            <Text c={'#ffffff99'} fz={12} fw={500}>alisa@gmail.com</Text>
          </Box>

          {/* Icon Box Section */}
          <Flex justify={'space-between'} align={'center'} my={27}>

            {/* single icon box */}
            <Flex justify={'center'} align={'center'} w={85} h={85} bg={'#181818'} style={{ border: "2px solid #2F3134", borderRadius: "10px", flexDirection: "column" }}>
              <Flex mt={2} justify={'center'} style={{ transform: "rotate(45deg)" }}>
                <IconArrowZigZag color="#25b6c9" size={22} />
              </Flex>
              <Text fz={11} fw={500} c={'#ffffff99'} my={5}>Progress Order</Text>
              <Text fz={12} fw={500} c={'#ffffff99'} mb={2}>10+</Text>
            </Flex>
            {/* single icon box */}
            <Flex w={85} justify={'center'} align={'center'} h={85} bg={'#181818'} style={{ border: "2px solid #2F3134", borderRadius: "10px", flexDirection: "column" }}>
              <Flex justify={'center'}>
                <IconTags color="#006fdc" size={22} />
              </Flex>
              <Text fz={12} fw={500} c={'#ffffff99'} my={5}>Promo codes</Text>
              <Text fz={12} fw={500} c={'#ffffff99'}>5</Text>
            </Flex>
            {/* single icon box */}
            <Flex w={85} h={85} justify={'center'} align={'center'} bg={'#181818'} style={{ border: "2px solid #2F3134", borderRadius: "10px", flexDirection: "column" }}>
              <Flex justify={'center'} c={'#dfac16'}>
                <IconStarFilled size={22} />
              </Flex>
              <Text fz={12} fw={500} c={'#ffffff99'} my={5}>Reviews</Text>
              <Text fz={12} fw={500} c={'#ffffff99'}>5</Text>
            </Flex>

          </Flex>

          {/* Info Section */}
          <Box mb={50}>
            <Flex mb={15} justify={'space-between'} align={'center'}>
              <Text c={'#ffffff'} fz={14} fw={400} style={{ flexBasis: "60%" }}>Name:</Text>
              <Text c={'#ffffff'} fz={14} fw={500} ta={'left'} style={{ flexBasis: "40%" }}>Alisa</Text>
            </Flex>
            <Flex mb={15} justify={'space-between'} align={'center'}>
              <Text c={'#ffffff'} fz={14} fw={400} style={{ flexBasis: "60%" }}>Email:</Text>
              <Text c={'#ffffff'} fz={14} fw={500} style={{ flexBasis: "40%" }}>alisa@gmail.com</Text>
            </Flex>
            <Flex mb={15} justify={'space-between'} align={'center'}>
              <Text c={'#ffffff'} fz={14} fw={400} style={{ flexBasis: "60%" }}>Location:</Text>
              <Text c={'#ffffff'} fz={14} fw={500} style={{ flexBasis: "40%" }}>XXXXXXXXX</Text>
            </Flex>
            <Flex mb={15} justify={'space-between'} align={'center'}>
              <Text c={'#ffffff'} fz={14} fw={400} style={{ flexBasis: "60%" }}>Zip Code:</Text>
              <Text c={'#ffffff'} fz={14} fw={500} style={{ flexBasis: "40%" }}>1200</Text>
            </Flex>
            <Flex mb={15} justify={'space-between'} align={'center'}>
              <Text c={'#ffffff'} fz={14} fw={400} style={{ flexBasis: "60%" }}>Phone Number:</Text>
              <Text c={'#ffffff'} fz={14} fw={500} style={{ flexBasis: "40%" }}>(+1) 5484 4757 84</Text>
            </Flex>
          </Box>

          {/* Buttons */}
          <Box mt={-25}>
            <Button variant="filled" fullWidth c={'#ffffff'} fz={16} fw={500} h={50} bg={'#00B9D3'} mb={60} style={{ cursor: 'pointer', borderRadius: "30px" }}>Buy Now</Button>
          </Box>

        </Box>
        {/* Profile Image End */}

        {/* Bottom Nav Start */}
        <Flex justify={'space-between'} align={'center'} pos={'absolute'} bg={'#1A1A1A'} w={'375px'} left={0} bottom={-25} h={65} py={25} px={30} style={{ borderBottomLeftRadius: '30px', borderBottomRightRadius: '30px', }}>
          <IconHome color="#A5A5A5"/>
          <IconCompass color="#A5A5A5"/>
          <IconShoppingCart color="#A5A5A5" />
          <IconUserCircle color="#00B9D3" />
        </Flex>
        {/* Bottom Nav End */}
      
    </Box>
  );
};

export default Profile;
